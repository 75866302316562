export function normalizeString(rawText) {
  return rawText.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``)
}

function normalizeUppercase(text) {
  return normalizeString(`${text}`.toUpperCase())
}

export const filterTable = ({ data, filterText, filters }) => {
  return data.filter(row => {
    return filters.find(filter => {
      const nesting = filter.split(`.`)
      let auxRow = row
      nesting.forEach(nest => {
        if (auxRow[nest]) {
          auxRow = auxRow[nest]
        }
      })

      return normalizeUppercase(auxRow).includes(normalizeUppercase(filterText))
    })
  })
}

export const thousandsFormat = (number, prependSign = false) => {
  const numb = parseInt(number, 10) || 0
  const array = Array.from(numb.toString()).reverse()
  let numberOut = ``
  array.forEach((num, i) => {
    if ((i + 1) % 3 === 0 && i + 1 !== array.length) {
      if (i + 1 === array.length - 1 && array[array.length - 1] === `-`) {
        numberOut = `${num}${numberOut}`
      } else {
        numberOut = `.${num}${numberOut}`
      }
    } else {
      numberOut = `${num}${numberOut}`
    }
  })
  return `${prependSign ? `$ ` : ``}${numberOut}`
}
