import API from '../../config/API'
import {
  USERS_LOADING,
  GET_USERS,
  GET_USERS_ERROR,
  ADD_USER,
  ADD_USER_ERROR,
  EDIT_USER,
  EDIT_USER_ERROR,
  REMOVE_USER,
  REMOVE_USER_ERROR,
  ERROR_MESSAGE,
} from '../actions/types'
import { notification } from '../../utils'

export const getUsers = (unitId, roleId) => async dispatch => {
  dispatch({ type: USERS_LOADING })
  try {
    const users = await API.getUsers(unitId, roleId)
    dispatch({ type: GET_USERS, users })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_USERS_ERROR, error })
  }
}

export const addUser = user => async dispatch => {
  dispatch({ type: USERS_LOADING })
  try {
    const id = await API.addUser(user)
    notification({
      type: `success`,
      message: `Se ha agregado el usuario correctamente`,
    })
    dispatch({ type: ADD_USER, id, user })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ADD_USER_ERROR, error })
  }
}

export const editUser = user => async dispatch => {
  dispatch({ type: USERS_LOADING })
  try {
    await API.editUser(user)
    notification({
      type: `success`,
      message: `Se ha editado el usuario correctamente`,
    })
    dispatch({ type: EDIT_USER, user })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: EDIT_USER_ERROR, error })
  }
}

export const removeUser = userId => async dispatch => {
  dispatch({ type: USERS_LOADING })
  try {
    await API.removeUser(userId)
    notification({
      type: `success`,
      message: `Se ha eliminado el usuario correctamente`,
    })
    dispatch({ type: REMOVE_USER, userId })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: REMOVE_USER_ERROR, error })
  }
}
