import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Spinner } from '@blueprintjs/core'
import moment from 'moment'
import { store, persistor } from './redux/store'
import 'moment/locale/es'
import MainApp from './MainApp'
import { ThemeProvider } from './context/ThemeContent'

moment.locale(`es`)

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <PersistGate loading={() => <Spinner />} persistor={persistor}>
          <MainApp />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  )
}

export default App
