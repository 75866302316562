import React from 'react'
import {
  faExclamationCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { notification as antdNotification } from 'antd'
import Icon from '../components/Icon'
import theme from '../config/theme'

export const notification = ({ type, message }) => {
  const setTitle = (type) => {
    if (type === `error`) {
      return `Error`
    } else if (type === `info`) {
      return `Información`
    } else {
      return `Operación realizada con éxito`
    }
  }

  const setColor = () => {
    if (type === `error`) {
      return theme.colors.red[0]
    } else if (type === `info`) {
      return theme.colors.yellow[0]
    } else {
      return theme.colors.primary[4]
    }
  }

  antdNotification[type]({
    message: `${setTitle(type)}`,
    icon: (
      <Icon
        icon={type === `error` ? faExclamationCircle : faCheckCircle}
        position="absolute"
        color={setColor(type)}
      />
    ),
    style: {
      border: `solid 1px ${
        setColor(type)
      }`,
    },
    description: message,
    placement: `topRight`,
    duration: 1.5,
  })
}
