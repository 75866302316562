import React, { useState } from 'react'
import { Card, Typography, Table, Container, Button } from '../../components'

function GenericManager({
  currentStep,
  setCurrentStep,
  title,
  data,
  setCurrentUnit,
}) {
  const [selectedUnit, setSelectedUnit] = useState([])

  const columns = [
    { title: `Id`, dataIndex: `id`, sorter: (a, b) => a.id - b.id },
    {
      title: `Campo`,
      dataIndex: `name`,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ]

  const rowSelection = {
    type: `radio`,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUnit(selectedRows.map(row => row.id))
    },
  }

  const setStep = step => {
    setCurrentUnit(selectedUnit[0])
    setCurrentStep(step)
  }

  return (
    <Card width="100%" borderColor="primary.3">
      <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
        {title}
      </Typography>
      <Table
        marginTop="16px"
        columns={columns}
        dataSource={data}
        rowSelection={rowSelection}
        pagination={false}
        scroll={{ y: 540 }}
        locale={{
          emptyText: `No existen campos`,
        }}
      />
      <Container marginTop="20px" justifyContent="flex-end">
        <Button
          onClick={() => setStep(currentStep + 1)}
          text="Siguiente"
          boxShadow={true}
          backgroundColor="gray.2"
        />
      </Container>
    </Card>
  )
}

export default GenericManager
