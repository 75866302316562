import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Modal, Container, Typography, Input, Button } from '../../components'
import { reviewTask, closeTask, deleteTask } from '../../redux/actions'
import API from '../../config/API'
import { notification } from '../../utils'

function FieldsWrapper({ title, content }) {
  return (
    <Container flexDirection="column" width="48%" marginBottom="16px">
      <Typography fontWeight="bold">{title}</Typography>
      <Typography backgroundColor="gray.1" width="100%" padding="4px 8px">
        {content}
      </Typography>
    </Container>
  )
}

// eslint-disable-next-line complexity
function ViewTask({
  data,
  onCancel,
  workers,
  assignTask,
  userRole,
  userId,
  showDeleteTask,
  reviewTask,
  taskTypes,
  closeTask,
  deleteTask,
}) {
  const [selectedWorkerId, setWorker] = useState(
    data.assignedTo ? data.assignedTo.id : ``,
  )
  const [taskTypeId, setTaskTypeId] = useState(
    data.taskType ? data.taskType.id : ``,
  )
  const closeTaskIds = [14, 15, 16, 17, 18, 19, 24, 25]
  const taskStatus = [4, 5, 6]
  const remainingDays = taskStatus.includes(data.taskStatusId)
    ? `-`
    : data.remainingDays

  const handleInput = (field, value) => {
    setWorker(value)
  }

  const editTaskType = async () => {
    try {
      await API.editTask(data.id, { taskTypeId })
      notification({ type: `success`, message: `Tarea editada con éxito` })
      onCancel()
    } catch (error) {
      notification({ type: `error`, message: `Hubo un error, reintente.` })
    }
  }

  const manageTask = () => {
    assignTask(data.id, userId, selectedWorkerId, data.treeId)
    onCancel()
  }

  const confirmReviewTask = () => {
    reviewTask(data.id, { isReviewed: true, taskStatusId: 6 })
    onCancel()
  }

  //le di el terminado a quien se le asigno la tarea
  const confirmCloseTask = () => {
    closeTask(data.id, {
      finishedAt: moment().format(`YYYY-MM-DD HH:mm`),
      finishedById: data.assignedTo.id,
      isReviewed: true,
      taskStatusId: 6,
      taskTypeId: data.taskType.id,
    })
    onCancel()
  }

  const finishTask = () => {
    closeTask(data.id, {
      finishedAt: moment().format(`YYYY-MM-DD HH:mm`),
      acceptedAt: moment().format(`YYYY-MM-DD HH:mm`),
      acceptedById: userId,
      finishedById: userId,
      taskStatusId: 4,
      taskTypeId: data.taskType.id,
    })
    onCancel()
  }

  const destroyTask = () => {
    deleteTask(data.id, {
      deletedAt: moment().format(`YYYY-MM-DD HH:mm`),
      deletedById: userId,
      taskStatusId: 5,
    })
    onCancel()
  }

  return (
    <Modal
      onAccept={onCancel}
      onCancel={onCancel}
      onDelete={showDeleteTask ? destroyTask : () => null}
      visible={true}
      title="Ver tarea"
      showCancel={false}
      showDelete={showDeleteTask && userRole === 1 && data.taskStatusId !== 5}
    >
      <Container width="100%" justifyContent="space-between">
        <FieldsWrapper title="Id Árbol:" content={data.treeId} />
        <FieldsWrapper
          title="Especie:"
          content={
            data.tree.specie ? data.tree.specie.description : `Árbol eliminado`
          }
        />
        {data.assignedTo && (
          <>
            {[1, 3].includes(userRole) &&
            !data.deletedAt &&
            !data.finishedBy ? (
              <Container width="48%" marginBottom="16px" alignItems="center">
                <FieldsWrapper
                  title="Tipo de Tarea:"
                  content={
                    <Input
                      width="100%"
                      showSearch
                      type="select"
                      optionFilterProp="children"
                      placeholder="Elija una opción"
                      onChange={(_, value) => setTaskTypeId(value)}
                      name="taskTypeId"
                      keyValue="description"
                      options={taskTypes}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={taskTypeId === `` ? undefined : taskTypeId}
                    />
                  }
                />
                <Button
                  marginLeft="2"
                  onClick={editTaskType}
                  text="Cambiar tipo"
                  boxShadow={true}
                  backgroundColor="gray.2"
                  disabled={taskTypeId === ``}
                />
              </Container>
            ) : (
              <FieldsWrapper
                title="Tipo de Tarea:"
                content={data.taskType.description}
              />
            )}
          </>
        )}
        <FieldsWrapper title="Creada el:" content={data.createdAt} />
        <FieldsWrapper
          title="Prioridad:"
          content={data.taskPriority ? data.taskPriority.description : `-`}
        />
        <FieldsWrapper title="Estado:" content={data.taskStatus.description} />
        <FieldsWrapper title="Días Restantes:" content={remainingDays} />
        <FieldsWrapper
          title="Comentario:"
          content={data.observation ? data.observation : `-`}
        />
        <FieldsWrapper
          title="Creada por:"
          content={`${data.createdBy.firstName} ${data.createdBy.lastName}`}
        />
        {data.acceptedBy && (
          <>
            <FieldsWrapper
              title="Aceptada el:"
              content={moment(data.acceptedAt).format(`DD/MM/YYYY`)}
            />
            <FieldsWrapper
              title="Aceptada por:"
              content={`${data.acceptedBy.firstName} ${data.acceptedBy.lastName}`}
            />
          </>
        )}
        {data.justificatedBy && (
          <>
            <FieldsWrapper
              title="Justificada el:"
              content={moment(data.justificatedAt).format(`DD/MM/YYYY`)}
            />
            <FieldsWrapper
              title="Justificada por:"
              content={`${data.justificatedBy.firstName} ${data.justificatedBy.lastName}`}
            />
            <FieldsWrapper
              title="Tipo justificación:"
              content={data.taskJustification.description}
            />
            {data.justification && (
              <FieldsWrapper
                title="Descripción justificación:"
                content={`${data.justification}`}
              />
            )}
          </>
        )}
        {/**TODO: BUGS-24 **/}
        {userRole === 3 || userRole === 5
          ? null
          : !data.assignedTo &&
            !data.deletedBy && (
              <Container flexDirection="column" width="48%" marginBottom="16px">
                <Input
                  width="100%"
                  showSearch
                  type="select"
                  optionFilterProp="children"
                  label="Seleccione Jefe de Cuadrilla a asignar:"
                  placeholder="Elija una opción"
                  onChange={handleInput}
                  name="taskTypeId"
                  keyValue="description"
                  options={workers}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedWorkerId === `` ? undefined : selectedWorkerId}
                />
                <Button
                  onClick={manageTask}
                  text="Asignar tarea"
                  boxShadow={true}
                  backgroundColor="gray.2"
                  marginTop="8px"
                  marginLeft="5px"
                  disabled={selectedWorkerId === ``}
                />
              </Container>
            )}
        {data.assignedBy && (
          <FieldsWrapper
            title="Asignada por:"
            content={`${data.assignedBy.firstName} ${data.assignedBy.lastName}`}
          />
        )}
        {data.assignedTo && (
          <>
            <FieldsWrapper
              title="Asignada el:"
              content={moment(data.assignedAt).format(`DD/MM/YYYY`)}
            />
            {userRole <= 2 && !data.deletedAt && !data.finishedBy ? (
              <Container width="48%" marginBottom="16px" alignItems="center">
                <FieldsWrapper
                  title="Asignada a:"
                  content={
                    <Input
                      width="100%"
                      showSearch
                      type="select"
                      optionFilterProp="children"
                      placeholder="Elija una opción"
                      onChange={handleInput}
                      name="selectedWorkerId"
                      keyValue="description"
                      options={workers}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={
                        selectedWorkerId === `` ? undefined : selectedWorkerId
                      }
                    />
                  }
                />
                <Button
                  onClick={manageTask}
                  marginLeft="2"
                  text="Asignar tarea"
                  boxShadow={true}
                  backgroundColor="gray.2"
                  disabled={selectedWorkerId === ``}
                />
              </Container>
            ) : (
              <FieldsWrapper
                title="Asignada a:"
                content={`${data.assignedTo.firstName} ${data.assignedTo.lastName}`}
              />
            )}
          </>
        )}
        {[2, 4].includes(userRole) || data.isReviewed
          ? null
          : data.assignedTo &&
            closeTaskIds.includes(data.taskType.id) && (
              <Container flexDirection="column" width="48%" marginBottom="16px">
                <Button
                  onClick={confirmCloseTask}
                  text="Cerrar tarea"
                  boxShadow={true}
                  backgroundColor="gray.2"
                  marginTop="8px"
                  marginLeft="5px"
                />
              </Container>
            )}
        {data.deletedBy && (
          <>
            <FieldsWrapper
              title="Eliminada el:"
              content={moment(data.deletedAt).format(`DD/MM/YYYY`)}
            />
            <FieldsWrapper
              title="Eliminada por:"
              content={`${data.deletedBy.firstName} ${data.deletedBy.lastName}`}
            />
          </>
        )}
        {data.finishedBy && (
          <>
            <FieldsWrapper
              title="Ejecutada el:"
              content={moment(data.finishedAt).format(`DD/MM/YYYY`)}
            />
            <FieldsWrapper
              title="Ejecutada por:"
              content={`${data.finishedBy.firstName} ${data.finishedBy.lastName}`}
            />
          </>
        )}
        {userRole === 2 || userRole === 4
          ? null
          : data.finishedBy &&
            !data.isReviewed && (
              <Container flexDirection="column" width="48%" marginBottom="16px">
                <Button
                  onClick={confirmReviewTask}
                  text="Finalizar tarea"
                  boxShadow={true}
                  backgroundColor="gray.2"
                  marginTop="8px"
                  marginLeft="5px"
                />
              </Container>
            )}
        {data.isReviewed && (
          <>
            <FieldsWrapper
              title="Tarea revisada el:"
              content={moment(data.updatedAt).format(`DD/MM/YYYY`)}
            />
            {data.acceptedBy ? (
              <FieldsWrapper
                title="Revisada por:"
                content={`${data.acceptedBy.firstName} ${data.acceptedBy.lastName}`}
              />
            ) : (
              <FieldsWrapper
                title="Revisada por:"
                content={`${data.createdBy.firstName} ${data.createdBy.lastName}`}
              />
            )}
          </>
        )}
        {data.taskStatusId < 4 && userRole === 1 ? (
          <Button
            onClick={finishTask}
            text="Terminar tarea"
            boxShadow={true}
            backgroundColor="gray.2"
            marginTop="8px"
            marginLeft="5px"
          />
        ) : null}
      </Container>
    </Modal>
  )
}

const mapStateToProps = () => {}

const mapDispatchToProps = {
  reviewTask,
  closeTask,
  deleteTask,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewTask)
