import moment from 'moment'
import axios from 'axios'
import API from '../../config/API'
import {
  ERROR_MESSAGE,
  GET_OT_ALL,
  OT_LOADING,
  GET_OT_ERROR,
  SUCCESS_EDIT_PAY_OT,
  GET_OT_REPORT_FILTERS,
  GET_OT_WITH_FILTER,
  GET_COUNT_OT,
  GET_OT_BILLING,
  OT_IS_LOADING,
  GET_OT_WITH_FINES,
  GET_VALUE_UTM,
} from '../actions/types'
import { notification } from '../../utils'

export const getAllOrderTasks = () => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const { rows, count } = await API.getAllTaskPaginate({
      offset: 0,
      limit: 10,
      order: [[`createdAt`, `ASC`]],
    })
    dispatch({ type: GET_OT_ALL, orderTasks: rows, count })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR, error })
  }
}

export const getAllOrderTasksPaginate = payload => async dispatch => {
  dispatch({ type: OT_IS_LOADING })
  try {
    const { rows, count } = await API.getAllTaskPaginate(payload)
    dispatch({ type: GET_OT_ALL, orderTasks: rows, count })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR, error })
  }
}

export const modifyOrderTask = taskRequest => async dispatch => {
  try {
    await API.editPayTask(taskRequest)
    notification({
      type: `success`,
      message: `Se ha editado el pago de la OT correctamente`,
    })
    const orderTasks = await API.getAllTask()
    dispatch({ type: SUCCESS_EDIT_PAY_OT, orderTasks })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const getOrderTaskFilters = unitId => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const { subUnits } = await API.getTaskReportFilters(unitId)
    const statuses = await API.getStatusesTaskRequest()
    const taskTypes = await API.getOrderTaskType()
    const species = await API.getTree()
    const zones = await API.getZone()
    const users = await API.getUsersBoss()
    const phytosanitaryStates = await API.getPhytosanitaryStatesByUnitId({
      unitId,
    })
    const locations = await API.getLocationsByUnitId({ unitId })
    const newUsers = users.map(user => ({
      ...user,
      description: `${user.firstName} ${user.lastName}`,
    }))
    const newSpecies = species.map(tree => ({
      ...tree,
      description: `${tree.description} (${tree.specie})`,
    }))
    const orderTaskFilters = {
      taskTypes,
      species: newSpecies,
      subUnits,
      statuses,
      users: newUsers,
      phytosanitaryStates,
      locations,
      zones,
    }
    dispatch({
      type: GET_OT_REPORT_FILTERS,
      orderTaskFilters,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
  }
}

export const getOrderTaskApplyfilters = filters => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const orderTasks = await API.getAllTaskWithFilters(filters)
    dispatch({ type: GET_OT_WITH_FILTER, orderTasks, filters })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR })
  }
}

export const getOrderTaskCount = () => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const {
      tasksResponseByTaskType,
      tasksResponseByUser,
    } = await API.getCountTaskRequest()
    dispatch({
      type: GET_COUNT_OT,
      tasksCountByType: tasksResponseByTaskType,
      tasksCountByUser: tasksResponseByUser,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR })
  }
}

export const getOrderTaskCountFilters = filters => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const {
      tasksResponseByTaskType,
      tasksResponseByUser,
    } = await API.getCountTaskRequestFilters(filters)
    dispatch({
      type: GET_COUNT_OT,
      tasksCountByType: tasksResponseByTaskType,
      tasksCountByUser: tasksResponseByUser,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR })
  }
}

export const getBilligTaskRequestFilters = filters => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const orderTaskBilling = await API.getBilligTaskRequest(filters)
    dispatch({
      type: GET_OT_BILLING,
      orderTaskBilling,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR })
  }
}

export const resetBilligTaskRequestFilters = () => dispatch => {
  dispatch({
    type: GET_OT_BILLING,
    orderTaskBilling: [],
  })
}

export const getTaskRequestWithFines = () => async dispatch => {
  dispatch({ type: OT_LOADING })
  try {
    const orderTaskWithFines = await API.getTaskRequestWithFines()
    const response = await axios.get(
      `https://mindicador.cl/api/utm/${moment().format(`DD-MM-YYYY`)}`,
    )
    if (response.status === 200) {
      const valueUTM = response.data.serie[0].valor
      dispatch({ type: GET_VALUE_UTM, valueUTM })
    }
    dispatch({
      type: GET_OT_WITH_FINES,
      orderTaskWithFines,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_OT_ERROR })
  }
}
