import API from '../../config/API'
import { notification } from '../../utils'
import { GET_SUB_UNITS, ADD_SUB_UNITS, EDIT_SUB_UNITS } from './types'

export const createSubUnit = subunit => async dispatch => {
  try {
    const id = await API.createSubUnit(subunit)
    notification({
      type: `success`,
      message: `Se ha agregado la sub unidad correctamente`,
    })
    dispatch({ type: ADD_SUB_UNITS, subunit: { id, ...subunit } })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const editSubUnit = (subUnitId, subunit) => async dispatch => {
  try {
    const result = await API.editSubUnit(subUnitId, subunit)
    notification({
      type: `success`,
      message: result,
    })
    dispatch({ type: EDIT_SUB_UNITS, subUnitId, editedSubunit: subunit })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const getSubUnits = (unitId) => async dispatch => {
  try {
    const result = await API.getSubUnits(unitId)
    dispatch({
      type: GET_SUB_UNITS,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}
