import API from '../../config/API'
import { notification } from '../../utils'
import {
  LOADING_REQUESTS,
  ERROR_REQUESTS,
  GET_REQUESTS,
  GET_REQUEST_FILTERS,
  SUCCESS_ASSIGN_REQUEST,
  SUCCESS_REJECT_REQUEST,
  SUCCESS_APPROVE_REQUEST,
  SUCCESS_DELETE_REQUEST,
} from './types'

export const getRequestFilters = unitId => async dispatch => {
  try {
    const { amounts, ...requestFilters } = await API.getRequestFilters(unitId)
    dispatch({
      type: GET_REQUEST_FILTERS,
      requestFilters,
      amounts,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const getRequests = filters => async dispatch => {
  dispatch({ type: LOADING_REQUESTS })
  try {
    const { fullRequests, count } = await API.getFilteredRequests(filters)
    const requestsById = {}
    const requests = fullRequests.map(request => {
      requestsById[request.id] = request
      return request.id
    })
    if (requests.length > 0) {
      dispatch({
        type: GET_REQUESTS,
        requests,
        requestsById,
        count,
      })
    } else {
      notification({
        type: `error`,
        message: `No se han encontrado solicitudes`,
      })
      dispatch({ type: ERROR_REQUESTS })
    }
  } catch (error) {
    notification({ type: `error`, message: error })
    dispatch({ type: ERROR_REQUESTS })
  }
}

export const assignRequest = (
  requestId,
  assignedInspectorId,
  treeId,
  citizenTokens,
  citizenMessage,
) => async dispatch => {
  try {
    const result = await API.assignRequest(
      requestId,
      assignedInspectorId,
      treeId,
      citizenTokens,
      citizenMessage,
    )
    notification({ type: `success`, message: result })
    dispatch({
      type: SUCCESS_ASSIGN_REQUEST,
      requestId,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const rejectRequest = (
  requestId,
  rejectedById,
  citizenTokens,
) => async dispatch => {
  try {
    const result = await API.rejectRequest(
      requestId,
      rejectedById,
      citizenTokens,
    )
    notification({ type: `success`, message: result })
    dispatch({
      type: SUCCESS_REJECT_REQUEST,
      requestId,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const deleteRequest = requestId => async dispatch => {
  try {
    const result = await API.deleteRequest(requestId)
    notification({ type: `success`, message: result })
    dispatch({
      type: SUCCESS_DELETE_REQUEST,
      requestId,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const approveRequest = (
  requestId,
  treeId,
  createdById,
  taskTypeId,
  taskPriorityId,
  citizenTokens,
  observation,
) => async dispatch => {
  try {
    const result = await API.approveRequest(
      requestId,
      treeId,
      createdById,
      taskTypeId,
      taskPriorityId,
      citizenTokens,
      observation,
    )
    notification({ type: `success`, message: result })
    dispatch({
      type: SUCCESS_APPROVE_REQUEST,
      requestId,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}
