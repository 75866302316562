import API from '../../config/API'
import { notification } from '../../utils'
import {
  SPECIES_LOADING,
  SPECIES_ERROR,
  //ADD_CLIENT,
  EDIT_SPECIES,
  GET_SPECIES,
  GET_SPECIE_BY_ID,
  ERROR_MESSAGE,
  GET_SPECIE_FIELDS,
  SETUP_SPECIE_LOADING,
  SETUP_SPECIE_SUCCESS,
  SETUP_SPECIE_ERROR,
  SUCCESS_EDIT,
} from './types'


export const getAllSpecies = () => async dispatch => {
  dispatch({ type: SPECIES_LOADING })
  try {
    const result = await API.getAllSpecies()
    dispatch({
      type: GET_SPECIES,
      result,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
    dispatch({ type: SPECIES_ERROR })
  }
}

export const getSpeciesFields = () => async dispatch => {
  try {
    const result = await API.getSpecieFields()
    dispatch({
      type: GET_SPECIE_FIELDS,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}


export const getSpecieById = specieId => async dispatch => {
  try {
    const result = await API.getSpecieById(specieId)
    dispatch({
      type: GET_SPECIE_BY_ID,
      result,
    })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const modifySpecie = (specieId, specie) => async dispatch => {
  try {
    await API.editSpecie(specieId, specie)
    notification({
      type: `success`,
      message: `Se ha editado la especie correctamente`,
    })
    dispatch({ type: SUCCESS_EDIT, specie, specieId })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}


export const setValues = (type, selectedRowKeys, selectedIds) => dispatch => {
  dispatch({
    type,
    selectedRowKeys,
    selectedIds,
  })
}


