import firebase from 'firebase'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: `AIzaSyDTveYGct8Kmle1JLGtmvMcxPwU4FeeevE`,
  authDomain: `arbotag.firebaseapp.com`,
  databaseURL: `https://arbotag.firebaseio.com`,
  projectId: `arbotag`,
  storageBucket: `arbotag.appspot.com`,
  messagingSenderId: `745849969524`,
})

const database = firebase.firestore()
const auth = firebase.auth()

const treeRef = database.collection(`trees`)
const municipalityRef = database.collection(`municipality`)
const solicitudesRef = database.collection(`solicitudes`)
const usersRef = database.collection(`users`)
const citizensRef = database.collection(`citizen`)

export {
  treeRef,
  solicitudesRef,
  usersRef,
  citizensRef,
  municipalityRef,
  auth,
  database as default,
}
