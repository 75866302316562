import _ from 'lodash'
import { GET_SUB_UNITS, ADD_SUB_UNITS, EDIT_SUB_UNITS } from '../actions/types'

const initialState = {
  subunits: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_UNITS:
      return {
        ...state,
        subunits: action.result,
      }
    case ADD_SUB_UNITS: {
      const { subunit } = action
      return {
        ...state,
        subunits: [...state.subunits, subunit],
      }
    }
    case EDIT_SUB_UNITS: {
      const { subUnitId, editedSubunit } = action
      const cloneSubUnit = _.cloneDeep(state.subunits)
      const subunits = cloneSubUnit.map(subunit =>
        subunit.id === subUnitId ? editedSubunit : subunit,
      )
      return {
        ...state,
        subunits,
      }
    }
    default:
      return state
  }
}
