import React, {useState} from 'react';
import API from '../config/API';
import { notification } from '../utils/notification'

const UploadImgClient = ({client}) => {
    const GLOBAL_VAR = { failed: 0 };
    const [file1, setFile1] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const clientId = client.clientId;
    let file2 = null;

    const handleFileInput = (e) => {
        try {
            setFile1(null);
            setFile1(e.target.files[0]);
            file2 = e.target.files[0];
            console.log(e.target.files[0]);
            console.log(file2);

        } catch (err) {
            console.log(err);
            notification({
                type: `error`,
                message: "Ocurrió un problema al cargar la imagen",
            })
        }
    }

    const changeHandler = (e) => {
        const el = e.target
        this.setState({
            file1: el.type === "file" ? el.files[0] : el.value
        })
    }


    const handleSubmit = (e) => {
        try {
            GLOBAL_VAR.failed = 0;
            e.preventDefault();
            
            if (file1) {

            console.log(file1);
                let success = callApi(file1, clientId);
         
            } else {
                notification({
                    type: `error`,
                    message: "No se ha cargado una imagen",
                })
            }

        } catch (err) {
            console.log(err);
            notification({
                type: `error`,
                message: "Ocurrió un problema al enviar la imagen",
            })
        }
    }



    const callApi = async (img, clientId) => {
        try{
            
            const formData = new FormData();
            formData.append("file", img);
            formData.append("clientId", clientId);
            for (var key of formData.entries()) {
                console.log(key[0] + ', ' + key[1]);
            }
            const resp = await API.uploadImgClient(formData);
            GLOBAL_VAR.failed += resp.failed;
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    const showNotification = (success, rowsFailed) => {
        if (success) {
                notification({
                    type: `success`,
                    message: "Imagen subida correctamente",
                })
            
        } else {
            notification({
                type: `error`,
                message: "Error al enviar la imagen",
            })
        }
    }

    return (
        <div className="file-uploader" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, width: `100%`, flexDirection: `column`}}>
            <div style={{width: `100%`, display: `flex`, flex: 1, alignItems: `center`, flexDirection: `column`}}>
                <label style={{flex: 1, alignItems: `center`}}>
                    Subir logo de cliente
                </label>
                <input type={"file"} name="file"  onChange={handleFileInput} multiple style={{flex: 1}}/>
            </div>

            <div style={{flex: 1}}>
            <button onClick={(e) => {handleSubmit(e)}}>
                Subir Imagen
            </button>
            </div>
        </div>
    )
}
export default UploadImgClient
