function normalizeString(rawText) {
  return rawText.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``)
}

function normalizeUppercase(text) {
  return normalizeString(`${text}`.toUpperCase())
}

export function filterData({ data, filterText, filters }) {
  return data.filter(row => {
    return filters.find(filter => {
      const nesting = filter.split(`.`)
      let auxRow = row
      nesting.forEach(nest => {
        if (auxRow[nest]) {
          auxRow = auxRow[nest]
        }
      })
      if (typeof auxRow === `object` && auxRow.length > 0) {
        return auxRow.find(row => row.startsWith(filterText))
      }
      return normalizeUppercase(auxRow).includes(normalizeUppercase(filterText))
    })
  })
}
