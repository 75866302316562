import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { format, clean } from 'rut.js'
import { Input, Modal } from '../../components'
import { addUser, editUser } from '../../redux/actions'

const separateRut = rut => {
  return [rut.slice(0, -1), rut.slice(-1)]
}

const getInitialState = {
  rut: ``,
  firstName: ``,
  lastName: ``,
  email: ``,
  password: ``,
  unitId: ``,
  roleId: ``,
  isActive: true,
}

function Form({ addUser, editUser, onCancel, entity, units, roles }) {
  const [fields, setFields] = useState(getInitialState)

  useEffect(() => {
    if (entity) {
      setFields({
        ...fields,
        ...entity,
      })
    }
  }, [])

  useEffect(() => {
    setFields({
      ...fields,
      ...entity,
    })
  }, [entity])

  const onInputChange = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }

  const onAccept = () => {
    if (entity) {
      const { id } = entity
      const [rut, dv] = separateRut(clean(fields.rut))
      editUser({
        user: {
          ...fields,
          rut,
          dv,
        },
        userId: id,
      })
    } else {
      addUser({
        ...fields,
        rut: clean(fields.rut),
      })
    }
    onCancel()
  }

  return (
    <Modal
      onAccept={onAccept}
      onCancel={onCancel}
      visible={true}
      title="Creación o edición de usuario"
    >
      <Input
        width="48%"
        label="Ingrese nombre:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.firstName}
        name="firstName"
      />
      <Input
        width="48%"
        label="Ingrese apellido:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.lastName}
        name="lastName"
      />
      <Input
        width="48%"
        label="Ingrese email:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.email}
        name="email"
      />
      <Input
        width="48%"
        label="Ingrese Rut:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={format(fields.rut)}
        name="rut"
      />
      <Input
        width="48%"
        label="Contraseña:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.password}
        name="password"
      />
      <Input
        width="48%"
        showSearch
        type="select"
        optionFilterProp="children"
        label="Selecciona Unidad:"
        placeholder="Elija una opción"
        onChange={onInputChange}
        name="unitId"
        keyValue="name"
        options={units}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={fields.unitId === `` ? undefined : fields.unitId}
      />
      <Input
        width="48%"
        showSearch
        type="select"
        optionFilterProp="children"
        label="Selecciona Rol:"
        placeholder="Elija una opción"
        onChange={onInputChange}
        name="roleId"
        keyValue="description"
        options={roles}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={fields.roleId === `` ? undefined : fields.roleId}
      />
      <Input
        width="48%"
        type="boolean"
        label="Usuario activo:"
        placeholder="Elija una opción"
        onChange={onInputChange}
        name="isActive"
        value={fields.isActive === `` ? undefined : fields.isActive}
      />
    </Modal>
  )
}

const mapStateToProps = ({ general: { units, roles } }) => ({
  units,
  roles,
})

const mapDispatchToProps = {
  addUser,
  editUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
