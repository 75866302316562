import styled from 'styled-components'
import { Button } from 'antd'
import { CSVLink } from 'react-csv'
import {
  space,
  typography,
  border,
  color,
  shadow,
  layout,
  compose,
  position,
  flexbox,
} from 'styled-system'

export const ButtonStyled = styled(Button)`
  &.ant-btn {
    ${compose(
      space,
      typography,
      border,
      color,
      shadow,
      layout,
      position,
      flexbox,
    )}
    :hover {
      ${compose(
        space,
        typography,
        border,
        color,
        shadow,
        layout,
        position,
      )}
    }
    :focus {
      ${compose(
        space,
        typography,
        border,
        color,
        shadow,
        layout,
        position,
      )}
    }
    span {
      font-size: 14px;
      text-decoration: underline;
    }
    ${({ boxShadow }) =>
      boxShadow
        ? `box-shadow: 3px 1px 9px rgba(0, 0, 0, 0.307638) !important;`
        : `box-shadow: none !important;`}
  }
`
ButtonStyled.defaultProps = {
  backgroundColor: `primary.3`,
  height: `46px`,
  minWidth: `160px`,
  width: `auto`,
  color: `gray.0`,
  fontWeight: 500,
  boxShadow: false,
  borderRadius: `4px`,
  borderColor: `transparent`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
}

export const DownloadStyle = styled(CSVLink)`
  ${compose(
    space,
    typography,
    border,
    color,
    shadow,
  )}
`

DownloadStyle.defaultProps = {
  padding: `14px`,
  color: `primary.3`,
  fontWeight: 500,
}
