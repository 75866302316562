import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setUnitId, logOut } from '../../redux/actions'
import { Container, Timer } from '../../components'
import { TextProfile, LogOut, SelectStyle } from './styles'

const Header = ({ user, roles, units, setUnitId, logOut }) => {
  const [selectedUnitId, setSelectedUnitId] = useState(null)
  useEffect(() => {
    if (
      units &&
      units.length > 0 &&
      selectedUnitId === null &&
      user.roleId === 1
    ) {
      units.splice(0,0,{id: -1, name: "Todas"})
      setSelectedUnitId(units[0].id)
      setUnitId(units[0].id)
    } else if (
      user.roleId === 1 &&
      units
    ) {
      units.splice(0,0,{id: -1, name: "Todas"})
    }
  }, [units])
  if (!roles) {
    return null
  }

  const handleSelect = value => {
    setSelectedUnitId(value)
    setUnitId(value)
  }

  return (
    <Container
      position="sticky"
      top={0}
      zIndex="800"
      alignItems="center"
      width="100%"
      padding="0 1em"
      height="4em"
      backgroundColor="primary.1"
      justifyContent="space-between"
    >
      <Timer />
      <Container justifyContent="flex-end" width="50%">
        {user.roleId === 1 && (
          <SelectStyle
            width="40%"
            onChange={value => handleSelect(value)}
            placeholder="Seleccione unidad a gestionar"
            value={selectedUnitId}
          >
            {units.map(unit => {
              return (
                <SelectStyle.Option key={unit.id} value={unit.id}>
                  {unit.name}
                </SelectStyle.Option>
              )
            })}
          </SelectStyle>
        )}
        <TextProfile>{`${user.firstName} ${user.lastName} | ${
          roles.filter(x => x.id === user.roleId)[0].description
        }`}</TextProfile>
        <TextProfile>|</TextProfile>
        <LogOut onClick={() => logOut()}>Cerrar Sesión</LogOut>
      </Container>
    </Container>
  )
}

const mapStateToProps = ({ auth: { user }, general: { roles, units } }) => ({
  user,
  roles,
  units,
})

const mapDispatchToProps = {
  setUnitId,
  logOut,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
)
