import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { getAllSpecies } from '../../redux/actions'
import { useListenerEnter } from '../../hooks'
import { filterTable } from '../../utils'
import {
  MainContainer,
  Container,
  Card,
  Table,
  Popover,
  Button,
  Typography,
  Modal,
  Loader,
} from '../../components'
import Form from './Form'

function Managment({ getAllSpecies, species, loading }) {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)
  const [search, setSearch] = useState(``)
  const [data, setData] = useState([])
  const [dataVisible, setDataVisible] = useState([])

const handleSearch = () => {
    const nextVisibleData = filterTable({
      data,
      filterText: search,
      filters: [`description`,],
    })
    setDataVisible(nextVisibleData)
  }
  useListenerEnter({ enterFn: handleSearch, dependencies: [search] })

  useEffect(() => {
    getAllSpecies()
  }, [])

useEffect(() => {
    setData(species)
    setDataVisible(species)
  }, [species])

  const columns = [
    {
      title: `Nombre`,
      dataIndex: `description`,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },

    {
      title: `Acción`,
      key: `action`,
      dataIndex: `action`,
      render: (text, record) => (
        <Popover
          title="Acción"
          icon={faEllipsisV}
          trigger="click"
          color="gray.2"
          content={
            <Container flexDirection="column">
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Editar"
                icon={faEdit}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleModal(true)
                }}
              />
              {/* <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Eliminar"
                icon={faTrashAlt}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleDeleteModal(true)
                }}
              /> */}
            </Container>
          }
        />
      ),
    },
  ]

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {visibleModal && (
        <Form
          data={entityWork}
          onCancel={() => {
            setEntityWork(null)
            setVisibleModal(false)
          }}
          onAccept={() => {
            setVisibleModal(false)
          }}
        />
      )}
      {species.length > 0 && (
        <MainContainer
          title="Especies"
          justifyContent="space-around"
          hasSearch={true}
          handleSearch={handleSearch}
          handleSearchInput={(name, value) => {
            setSearch(value)
          }}
          search={search}
          defaultData={() => {
            setDataVisible(data)
          }}
          handleRefresh={() => {
        getAllSpecies()
      }}
      searchText="Buscar por nombre"
          handleCreate={() => {
            setVisibleModal(true)
          }}
        >
          <Table
            marginTop="16px"
            columns={columns}
            dataSource={dataVisible}
            locale={{
              emptyText: `No existen especies`,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: [`10`, `20`, `30`, `40`],
            }}
          />
        </MainContainer>
      )}
    </>
  )
}



const mapStateToProps = ({ species: { species, loading } }) => ({
  species,
  loading,
})

const mapDispatchToProps = { getAllSpecies }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Managment)
