import React, {useState} from 'react';
import API from '../../config/API';
import { notification } from '../../utils';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { MainContainer, Input, Button, Typography } from '../../components'

function QRGenerator({user}) {
    const MAX_NUM_CODES = 195
    const [fromId, setFromID] = useState()
    const [toId, setToId] = useState()

    const validateId = (id) => {
        let idInt = parseInt(id)
        if (idInt) {
            if (idInt >= 0) {
                return true
            }
        }
        return false
    }

    const generate = () => {
        let fromIdInt = parseInt(fromId)
        let toIdInt = parseInt(toId)
        if (validateId(fromIdInt) && validateId(toIdInt)) {
            if (fromIdInt > toIdInt) {
                prepareBatchesToSend(toIdInt, fromIdInt)
            } else {
                prepareBatchesToSend(fromIdInt, toIdInt)
            }
        } else if (validateId(fromIdInt)) {
            prepareBatchesToSend(fromIdInt, fromIdInt)
        } else if (validateId(toIdInt)) {
            prepareBatchesToSend(toIdInt, toIdInt)
        } else {
            notification({
                type: `error`,
                message: "No se han ingresado IDs válidos",
            })
        }
    }

    const prepareBatchesToSend = async (fromIntId, toIntId) => {
        let numOfCodes = 1 + toIntId - fromIntId
        let numOfBatches = Math.ceil(numOfCodes/MAX_NUM_CODES)
        for (let i=0; i < numOfBatches; i++) {
            let startingVal = fromIntId + i * MAX_NUM_CODES
            let endingVal = Math.min(toIntId, startingVal + MAX_NUM_CODES - 1)
            await sendToApi(startingVal, endingVal, i)
        }
    }

    const sendToApi = (fromIdInt, toIdInt, i) => {
        let timeOut = 1
        if (i) {
            timeOut = 5000
        }
        return new Promise(async (resolve, reject) => {
            // El timeout asegura la carga del archivo antes de sobreescribir
            setTimeout(
                () => {
                    console.log("About to generate in range", fromIdInt, toIdInt)
                    API.generateQR(fromIdInt, toIdInt, user.id).then((res) => {
                        if (res.data.success) {
                            notification({
                                type: `success`,
                                message: "Códigos generados",
                            })
                            // El timeout asegura la carga del archivo antes de descargar
                            setTimeout(
                                () => {
                                    try {
                                        window.open(`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/${user.id}_qrcodes.svg`)
                                    } catch(e) {
                                        console.log(e)
                                    }
                                    resolve()
                                },
                                1000
                            )
                            
                        } else {
                            notification({
                                type: `error`,
                                message: "Error al generar QR",
                            })
                            reject()
                        }
                    })
                },
                timeOut
            )
        })
    }

    const handleChangeFrom = (field, value) => {
        setFromID(value)
    }

    const handleChangeTo = (field, value) => {
        setToId(value)
    }

    return (
        <>
        <MainContainer title="Generar QR" justifyContent="space-around">
        <div className="file-uploader" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, width: `100%`, flexDirection: `column`}}>
            <div style={{width: `100%`, display: `flex`, flex: 1, alignItems: `center`, flexDirection: `column`}}>
                <Typography type="label" fontWeight="bold" fontStyle="italic">
                    Generar códigos QR en el intervalo de ID
                </Typography>
            </div>

            <span style={{display:`flex`, width: `100%`, alignItems: `center`, justifyContent: `center`}}>
            <Input
                width="25%"
                label="Desde:"
                placeholder="ID árbol"
                onChange={handleChangeFrom}
                value={fromId}
                name="fromId"
            />

            <Input
                width="25%"
                label="Hasta:"
                placeholder="ID árbol"
                onChange={handleChangeTo}
                value={toId}
                name="toId"
            />
            </span>

            <div style={{flex: 1}}>
            <Button
              onClick={generate}
              text={"Generar"}
              backgroundColor="primary.3"
            />
            </div>
        </div>
        </MainContainer>
        </>
    )
}

const mapStateToProps = ({
  auth: { user },
}) => ({
  user,
})

export default withRouter(
  connect(
    mapStateToProps,
  )(QRGenerator),
)
