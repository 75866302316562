import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { getTrees, getTreeFilters, deleteTree } from '../../redux/actions'
import { isEmptyObj, thousandsFormat } from '../../utils'
import {
  MainContainer,
  Container,
  Card,
  Table,
  Popover,
  Button,
  Link,
  Typography,
  Modal,
  HeaderInfo,
} from '../../components'
import TreeFilter from '../../components/TreeFilter'
import Form from './Form'
import ViewTree from './ViewTree'
import CSVLoader from '../../components/CSVLoader'

function TreesTable({
  match,
  trees,
  loading,
  treeFilters,
  count,
  countArboles,
  countPalmeras,
  countTocones,
  countPV,
  countEspecies,
  countVisits,
  getTreeFilters,
  getTrees,
  user,
  deleteTree,
  adminUnitId,
  history,
}) {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleViewModal, setVisibleViewModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)
  const [filters, setFilters] = useState({})
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [columns, setColumns] = useState([])
  const [useParams, setUseParams] = useState(true)
  const [orders, setOrders] = useState({})

  useEffect(() => {
    const { roleId } = user
    if (roleId === 8 || roleId === 9) {
      history.push(`/ot-barnechea`)
    }
  }, [user])

  useEffect(() => {
    const { roleId } = user
    const treeColumns = [
      { title: `ID`, dataIndex: `id`, sorter: true },
      { title: `Especie`, dataIndex: `specie.description`, sorter: true },
      {
        title: `Dirección`,
        dataIndex: `address`,
        sorter: true,
        sorterPath: `streetName`,
      },
      {
        title: `Unidad vecinal`,
        dataIndex: `subUnit.description`,
        sorterPath: `subUnit.description`,
        sorter: true,
      },
      {
        title: `Última actualización`,
        dataIndex: `updatedAt`,
        sorter: true,
        render: value => moment(value).format(`LLL`),
      },
      {
        title: `Detalle`,
        width: 100,
        key: `u`,
        render: (text, record) => {
          return (
            <Link
              onClick={() => {
                setEntityWork(record)
                setVisibleViewModal(true)
              }}
            >
              Ver
            </Link>
          )
        },
      },
    ]
    if (roleId === 1 || roleId === 3 || roleId === 5) {
      treeColumns.push({
        title: `Acción`,
        key: `action`,
        dataIndex: `action`,
        render: (text, record) => (
          <Popover
            title="Acción"
            icon={faEllipsisV}
            trigger="click"
            color="gray.2"
            content={
              <Container flexDirection="column">
                <Button
                  backgroundColor="transparent"
                  color="primary.3"
                  text="Editar"
                  icon={faEdit}
                  onClick={() => {
                    setEntityWork(record)
                    setVisibleModal(true)
                  }}
                />
                {roleId === 1 && (
                  <Button
                    backgroundColor="transparent"
                    color="primary.3"
                    text="Eliminar"
                    icon={faTrashAlt}
                    onClick={() => {
                      setEntityWork(record)
                      setVisibleDeleteModal(true)
                    }}
                  />
                )}
              </Container>
            }
          />
        ),
      })
    }
    setColumns(treeColumns)
  }, [])

  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1 && roleId !== 8 && roleId !== 9) {
      getTreeFilters(unitId)
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getTreeFilters(adminUnitId)
      getTrees({
        ...filters,
        pagination: { page: 1, pageSize: pagination.pageSize },
        unitId: adminUnitId
      })
    }
    else if (adminUnitId === null) {
      const { unitId, roleId } = user
      getTreeFilters(unitId)
      getTrees({
        ...filters,
        pagination: { page: 1, pageSize: pagination.pageSize },
        unitId: unitId
      })
      
    }
  }, [adminUnitId])

  const removeTree = () => {
    deleteTree(entityWork.id)
    setVisibleDeleteModal(false)
  }

  const onTableChange = (pagination, filtersTable, sorter) => {
    const directions = {
      ascend: `ASC`,
      descend: `DESC`,
    }
    const sorters = { ...orders }
    if (!isEmptyObj(sorter)) {
      const field = sorter.column.sorterPath || sorter.field
      sorters[field] = [...field.split(`.`), directions[sorter.order]]
      setOrders({ ...sorters })
    }
    if (!isEmptyObj(pagination)) {
      setPagination({ page: pagination.current, pageSize: pagination.pageSize })
    }
    getTrees({
      ...filters,
      pagination: { page: pagination.current, pageSize: pagination.pageSize },
      order: Object.values(sorters),
    })
  }

  return (
    <>
      {visibleModal && (
        <Form
          adminUnitId={adminUnitId}
          data={entityWork}
          onCancel={() => {
            setVisibleModal(false)
          }}
          getTrees={() => getTrees({ ...filters, pagination })}
        />
      )}
      {visibleDeleteModal && (
        <Modal
          onCancel={() => {
            setVisibleDeleteModal(false)
          }}
          title="Eliminar árbol"
          onAccept={removeTree}
          visible={true}
        >
          <Typography>
            {`¿Está seguro que desea eliminar el ${entityWork.specie.description} con
            ID ${entityWork.id}?`}
          </Typography>
        </Modal>
      )}
      {visibleViewModal && (
        <ViewTree
          unitId={user.roleId === 1 ? adminUnitId : user.unitId}
          onCancel={() => {
            setVisibleViewModal(false)
          }}
          data={entityWork}
          countVisits={countVisits}
        />
      )}
      {!treeFilters && user.roleId === 1 ? (
        <Container marginBottom="3">
          <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
            Seleccione Unidad
          </Typography>
        </Container>
      ) : null}
      {treeFilters && (
        <MainContainer title="Árboles" justifyContent="space-around">
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(treeFilters.totalTrees),
                subtitle: `TOTAL`,
              },
              {
                title: thousandsFormat(countPV),
                subtitle: `PLANTERAS VACÍAS`
              },
            ]}
          />
          </div>
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(countArboles),
                subtitle: `ARBOLES`
              },
              { 
                title: thousandsFormat(countEspecies), 
                subtitle: `ESPECIES` 
              },
            ]}
          />
          </div>
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(countPalmeras),
                subtitle: `PALMERAS`
              },
              { 
                title: thousandsFormat(count), 
                subtitle: `TOTAL BÚSQUEDA` 
              },
            ]}
          />
          </div>
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(countTocones),
                subtitle: `TOCONES`
              },
            ]}
          />
          </div>
        </MainContainer>
      )}

      
      { // Render carga por csv solo si usuario es Superadmin
        (user.roleId === 1) && <CSVLoader/>
      }

      <TreeFilter
        getTrees={getTrees}
        setUseParams={setUseParams}
        treeFilters={treeFilters}
        paramsTreeId={useParams ? match.params.treeId : null}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
      />
      
      <Card width="100%" borderColor="primary.3">
        <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
          Resultados búsqueda:
        </Typography>
        <Table
          marginTop="16px"
          columns={columns}
          dataSource={trees.map(tree => {
            const address = `${tree.streetName} ${tree.streetNumber}`
            return {
              ...tree,
              address,
            }
          })}
          loading={loading}
          locale={{
            emptyText: `No existen árboles con los parametros ingresados`,
          }}
          onChange={onTableChange}
          pagination={{
            showSizeChanger: true,
            pageSize: pagination.pageSize,
            current: pagination.page,
            total: count,
            pageSizeOptions: [`10`, `20`, `30`],
          }}
        />
      </Card>
    </>
  )
}

const mapStateToProps = ({
  trees: { trees, loading, treeFilters, count, countArboles, countPalmeras, countTocones, countPV, countEspecies, countVisits },
  auth: { user },
  general: { adminUnitId },
}) => ({
  loading,
  trees,
  treeFilters,
  count,
  countArboles,
  countPalmeras,
  countTocones,
  countPV,
  countEspecies,
  countVisits,
  user,
  adminUnitId,
})

const mapDispatchToProps = {
  getTrees,
  getTreeFilters,
  deleteTree,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TreesTable),
)
