import React, { useEffect, useState } from 'react'
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts'

import { Card, Typography, Table, Container, Loader } from '../../components'

const columns = [
  { title: `Estado`, dataIndex: `state` },
  { title: `Cantidad`, dataIndex: `qty`, align: `center` },
  { title: `%`, dataIndex: `percentage`, align: `center` },
]

const COLORS = [
  `#00C49F`,
  `#FFBB28`,
  `#a8323e`,
  `#000000`,
  `#FF8042`,
  `#82ca9d`,
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? `start` : `end`}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  )
}

function TreesByUnit({ treesByUnitId, loading }) {
  const [state, setState] = useState({
    dataGrid: [],
    dataTable: [],
  })

  const { dataGrid, dataTable } = state

  useEffect(() => {
    let total = 0
    const data = Object.keys(treesByUnitId).reduce((acc, item) => {
      total += treesByUnitId[item].length
      acc.push({ name: item, value: treesByUnitId[item].length })
      return acc
    }, [])

    const table = data.map(({ name, value }) => ({
      state: name,
      qty: parseFloat(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`),
      percentage: ((value / total) * 100).toFixed(1),
    }))

    setState(prevState => ({ ...prevState, dataGrid: data, dataTable: table }))
  }, [treesByUnitId])

  return (
    <Card width="100%" borderColor="primary.3">
      {loading ? (
        <Loader />
      ) : (
        <Container display="flex" justifyContent="center">
          <Container width="100%" mb={2}>
            <Typography fontSize={3} fontWeight={3}>
              Estados generales de árboles
            </Typography>
          </Container>
          <Container width="40%" height="280px">
            <ResponsiveContainer>
              <PieChart width={400} height={400}>
                <Pie
                  data={dataGrid}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {dataGrid.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  formatter={value =>
                    parseFloat(value)
                      .toFixed(0)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          </Container>
          <Container width="40%">
            <Table
              marginTop="16px"
              columns={columns}
              size="small"
              dataSource={dataTable}
              bordered={true}
              pagination={false}
            />
          </Container>
        </Container>
      )}
    </Card>
  )
}

export default TreesByUnit
