import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Modal, Container, Typography, Input } from '../../components'
import { modifyOrderTask } from '../../redux/actions'
import { notification } from '../../utils'

function FieldsWrapper({ title = ``, content }) {
  return (
    <Container flexDirection="column" width="48%" marginBottom="16px">
      <Typography fontWeight="bold">{title}</Typography>
      <Typography backgroundColor="gray.1" width="100%" padding="4px 8px">
        {content}
      </Typography>
    </Container>
  )
}

const initialState = {
  price: ``,
  status: `PENDING`,
  paymentDateCollect: moment(),
  paymentDateReceive: moment(),
  loading: false,
}

function EditOrderTask({
  handleCloseEditOrderTask,
  task,
  modifyOrderTask,
  user,
}) {
  const [state, setState] = useState(initialState)
  function onChange(name, value) {
    setState(prevState => ({ ...prevState, [name]: value }))
  }
  const {
    id,
    code,
    orderTask: { description: taskTypeName = `` },
    timeLimit,
    changeStatuses,
    dateCreated,
    createdAt,
    price: priceInitial,
    paymentDateCollect: paymentDateCollectInitial,
    paymentDateReceive: paymentDateReceiveInitial,
    paymentStatus,
  } = task
  const { price, status, paymentDateCollect, paymentDateReceive } = state

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      price: priceInitial,
      status: paymentStatus ? paymentStatus : `PENDING`,
      paymentDateCollect: paymentDateCollectInitial,
      paymentDateReceive: paymentDateReceiveInitial,
    }))
  }, [])

  const handleEditOrderTask = async () => {
    const { roleId } = user
    if (roleId !== 8) {
      notification({
        type: `error`,
        message: `No tiene permisos para ejecutar esta acción`,
      })
      return false
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    const payload = {
      taskId: id,
      price,
      status,
      paymentDateCollect,
      paymentDateReceive,
    }
    await modifyOrderTask(payload)
    setState(prevState => ({
      ...prevState,
      loading: false,
    }))
  }

  const handleDaysAllow = () => {
    const statusFinish = changeStatuses.find(el => el.statusId === 5)
    if (statusFinish) return false

    const remainingDays = timeLimit + moment(dateCreated).diff(moment(), `days`)
    if (remainingDays >= 4 && remainingDays <= 6) {
      return { days: remainingDays, background: `#EFFE00`, color: `#000000` }
    }
    if (remainingDays >= 7) {
      return { days: remainingDays, background: `#4DFF00`, color: `#000000` }
    }

    return { days: remainingDays, background: `#FF0000`, color: `#FFFFFF` }
  }

  return (
    <Modal
      onAccept={handleEditOrderTask}
      onCancel={handleCloseEditOrderTask}
      visible={true}
      title="Editar pago"
      showCancel={true}
      acceptText="Editar pago"
    >
      <Container width="100%" justifyContent="space-between">
        <FieldsWrapper title="Código" content={`#${code}`} />
        <FieldsWrapper title="Tipo de tarea" content={taskTypeName} />
        <FieldsWrapper title="Fecha Creación" content={createdAt} />
        <FieldsWrapper
          title="Plazo"
          content={
            handleDaysAllow() ? (
              <Container
                style={{
                  backgroundColor: handleDaysAllow().background,
                  color: handleDaysAllow().color,
                  padding: `0px 8px`,
                  borderRadius: 50,
                  width: 100,
                  justifyContent: `center`,
                }}
              >{`${handleDaysAllow().days} Día(s)`}</Container>
            ) : (
              `-`
            )
          }
        />
        <FieldsWrapper
          content={
            <Input
              label="Precio:"
              number={true}
              name="price"
              value={price}
              onChange={onChange}
            />
          }
        />
        <FieldsWrapper
          content={
            <Input
              label="Estado de pago:"
              type="select"
              name="status"
              key="value"
              value={status}
              onChange={onChange}
              keyValue="description"
              options={[
                { description: `Pendiente`, id: `PENDING` },
                { description: `Pagado`, id: `PAID` },
              ]}
            />
          }
        />
        <FieldsWrapper
          content={
            <Input
              label="Fecha de cobro:"
              type="date"
              name="paymentDateCollect"
              value={paymentDateCollect}
              onChange={onChange}
            />
          }
        />
        <FieldsWrapper
          content={
            <Input
              label="Fecha de pago recibido:"
              type="date"
              name="paymentDateReceive"
              value={paymentDateReceive}
              onChange={onChange}
              allowEmpty={true}
            />
          }
        />
      </Container>
    </Modal>
  )
}

const mapStateToProps = ({ auth: { user } }) => {
  return {
    user,
  }
}

const mapDispatchToProps = {
  modifyOrderTask,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditOrderTask)
