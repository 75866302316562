import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  getRequestFilters,
  assignRequest,
  rejectRequest,
  approveRequest,
  getRequests,
  deleteRequest,
} from '../../redux/actions'
import {
  MainContainer,
  HeaderInfo,
  Container,
  Card,
  Table,
  Typography,
  Link,
  Button,
} from '../../components'
import RequestFilter from '../../components/RequestFilter'
import { isEmptyObj } from '../../utils'
import ViewRequest from './ViewRequest'
import NotificationModal from './NotificationModal'

function RequestsTable({
  requests,
  loading,
  getRequestFilters,
  user,
  requestFilters,
  count,
  getRequests,
  assignRequest,
  rejectRequest,
  approveRequest,
  amounts,
  adminUnitId,
  deleteRequest,
}) {
  const [visibleViewModal, setVisibleViewModal] = useState(false)
  const [notificationModal, setNotificationModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)
  const [filters, setFilters] = useState({})
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [orders, setOrders] = useState({})
  const columns = [
    { title: `Id solicitud`, dataIndex: `id`, sorter: true },
    { title: `Id Árbol`, dataIndex: `treeId`, sorter: true },
    { title: `Especie`, dataIndex: `tree.specie.description`, sorter: true },
    { title: `Fecha Creación`, dataIndex: `createdAt`, sorter: true },
    { title: `Días desde creación`, align: `center`, dataIndex: `createdDiff` },
    {
      title: `Tipo de Solicitud`,
      dataIndex: `requestType.description`,
      sorter: true,
    },
    {
      title: `Dirección`,
      dataIndex: `tree.address`,
      sorter: true,
      sorterPath: `tree.streetName`,
    },
    {
      title: `Unidad vecinal`,
      align: `center`,
      dataIndex: `tree.subUnit.description`,
      sorter: true,
    },
    { title: `Estado`, dataIndex: `requestStatus.description`, sorter: true },
    { title: `Descripción`, dataIndex: `description`, sorter: true },
    {
      title: `Detalle`,
      width: 100,
      align: `center`,
      key: `u`,
      render: (text, record) => {
        return (
          <Link
            onClick={() => {
              setEntityWork(record)
              setVisibleViewModal(true)
            }}
          >
            Ver
          </Link>
        )
      },
    },
  ]

  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getRequestFilters(unitId)
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getRequestFilters(adminUnitId)
    }
  }, [adminUnitId])

  const onTableChange = (pagination, filtersTable, sorter) => {
    const directions = {
      ascend: `ASC`,
      descend: `DESC`,
    }
    const sorters = { ...orders }
    if (!isEmptyObj(sorter)) {
      const field = sorter.column.sorterPath || sorter.field
      sorters[field] = [...field.split(`.`), directions[sorter.order]]
      setOrders({ ...sorters })
    }
    if (!isEmptyObj(pagination)) {
      setPagination({ page: pagination.current, pageSize: pagination.pageSize })
    }
    getRequests({
      ...filters,
      pagination: { page: pagination.current, pageSize: pagination.pageSize },
      order: Object.values(sorters),
    })
  }

  return (
    <>
      {notificationModal && (
        <NotificationModal onCancel={() => setNotificationModal(false)} />
      )}
      {visibleViewModal && (
        <ViewRequest
          onCancel={() => {
            setVisibleViewModal(false)
          }}
          data={entityWork}
          workers={requestFilters.workers}
          taskTypes={requestFilters.taskTypes}
          taskPriorities={requestFilters.priorities}
          userId={user.id}
          roleId={user.roleId}
          assignRequest={assignRequest}
          rejectRequest={rejectRequest}
          approveRequest={approveRequest}
          deleteRequest={deleteRequest}
        />
      )}
      {!requestFilters && user.roleId === 1 ? (
        <Container marginBottom="3">
          <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
            Seleccione Unidad
          </Typography>
        </Container>
      ) : null}
      {amounts && (
        <MainContainer
          title="Solicitudes"
          justifyContent="space-around"
          action={
            [1, 3].includes(user.roleId) && (
              <Button
                onClick={() => setNotificationModal(true)}
                text="Mensaje vecinos"
              />
            )
          }
        >
          <HeaderInfo
            list={[
              { title: amounts.pending, subtitle: `PENDIENTES` },
              { title: amounts.assigned, subtitle: `ASIGNADAS` },
              {
                title: amounts.finished,
                subtitle: `TERMINADAS/REVISADAS`,
              },
              { title: amounts.deleted, subtitle: `RECHAZADAS` },
              { title: amounts.totalRequest, subtitle: `TOTAL SOLICITUDES` },
            ]}
          />
        </MainContainer>
      )}
      <RequestFilter
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
      />
      <Card width="100%" borderColor="primary.3">
        <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
          Resultados búsqueda:
        </Typography>
        <Table
          marginTop="16px"
          columns={columns}
          loading={loading}
          dataSource={requests.map(request => ({
            ...request,
            createdAt: moment(request.createdAt).format(`DD/MM/YYYY`),
          }))}
          locale={{
            emptyText: `No existen tareas con los parametros ingresados`,
          }}
          onChange={onTableChange}
          pagination={{
            showSizeChanger: true,
            pageSize: pagination.pageSize,
            current: pagination.page,
            total: count,
            pageSizeOptions: [`10`, `20`, `30`],
          }}
        />
      </Card>
    </>
  )
}

const mapStateToProps = ({
  requests: {
    requests: requestIds,
    requestsById,
    loading,
    requestFilters,
    count,
    amounts,
  },
  auth: { user },
  general: { adminUnitId },
}) => {
  const requests = requestIds.map(requestId => requestsById[requestId])
  return {
    loading,
    requests,
    count,
    requestFilters,
    user,
    adminUnitId,
    amounts,
  }
}

const mapDispatchToProps = {
  getRequestFilters,
  assignRequest,
  rejectRequest,
  approveRequest,
  getRequests,
  deleteRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestsTable)
