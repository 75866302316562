import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Input, Loader, Button } from '../../components'
import { getSpeciesFields, getSpecieById, modifySpecie } from '../../redux/actions'
import ImageUploaderSpecie from '../../components/ImageUploaderSpecie'
const getInitialState = {
  description: ``,
  scientificName: ``,
  geographyOrigin: ``,
  typeOrigin: ``,
  clasification:  ``,
  code: ``,
  sunRequirement: ``,
  waterRequirement:  ``,
  growthSpeed:  ``,
  topShape : ``,
  longDescription:  ``,
  url :  ``,
  file :  ``,
}

const Form = ({
  data,
  userId,
  roleId,
  modifySpecie,
  getSpecieFields,
  getSpecieById,
  specieFields,
  onCancel,
  loading,
}) => {
  const [fields, setFields] = useState(getInitialState)
  const [values, setValues] = useState({})


  useEffect(() => {
    if (data) {
      setFields({
        ...fields,
        ...data,
      })
    }
  }, [])

  useEffect(() => {
    setFields({
      ...fields,
      ...data,
    })
  }, [data])

  useEffect(() => {
    const { id } = data
    getSpecieById(id)
  }, [])


  const onInputChange = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }


  const onAccept = () => {
    let specie = { ...fields }
    modifySpecie(data.id, specie)
    onCancel()
  }

  return (
    <Modal
      onAccept={onAccept}
      onCancel={onCancel}
      visible={true}
      title="Edición de especie"
    >
      <Input
        width="48%"
        label="Ingrese nombre:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.description}
        name="description"
      />
      <Input
        width="48%"
        label="Ingrese nombre cientifico:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.scientificName}
        name="scientificName"
      />
      <Input
        width="48%"
        label="Ingrese origen:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.geographyOrigin}
        name="geographyOrigin"
      />
      <Input
        width="48%"
        label="Ingrese tipo de origen:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        type="select"
        value={fields.typeOrigin}
        keyValue="description"
        options={[
          { id: "Exótico", description: `Exótico` },
          { id: "Nativo", description: `Nativo` },
          { id: "Introducido", description: `Introducido` },
        ]}
        name="typeOrigin"
      />
      <Input
        width="48%"
        label="Ingrese clasificación por Tipo de Hoja:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        type="select"
        keyValue="description"
        value={fields.clasification}
        options={[
          { id: "Caduco", description: `Caduco` },
          { id: "Perenne", description: `Perenne` },
          { id: "Semicaduco", description: `Semicaduco` },
          { id: "Persistente", description: `Persistente` },
        ]}
        name="clasification"
      />
      <Input
        width="48%"
        label="Ingrese familia:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.family}
        name="family"
      />
      <Input
        width="48%"
        label="Ingrese codigo:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.code}
        name="code"
      />
      <Input
        width="48%"
        label="Ingrese requerimiento de Sol:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        type="select"
        keyValue="description"
        value={fields.sunRequirement}
        options={[
          { id: "Semi sombra", description: `Semi sombra` },
          { id: "Sol", description: `Sol` },
        ]}
        name="sunRequirement"
      />
      <Input
        width="48%"
        label="Ingrese requeriemiento de agua:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        type="select"
        keyValue="description"
        value={fields.waterRequirement}
        options={[
          { id: "Alto", description: `Alto` },
          { id: "Medio", description: `Medio` },
          { id: "Bajo", description: `Bajo` },
        ]}
        name="waterRequirement"
      />
      <Input
        width="48%"
        label="Ingrese velocidad de crecimiento:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        type="select"
        keyValue="description"
        value={fields.growthSpeed}
        options={[
          { id: "Rápido", description: `Rápido` },
          { id: "Medio", description: `Medio` },
          { id: "Lento", description: `Lento` },
        ]}
        name="growthSpeed"
      />
      <Input
        width="48%"
        label="Ingrese forma:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        type="select"
        keyValue="description"
        value={fields.topShape}
        options={[
          { id: "Esférica", description: `Esférica` },
          { id: "Cónica", description: `Cónica` },
          { id: "Irregular", description: `Irregular` },
          { id: "Irregular", description: `Irregular` },
          { id: "Ovoidal", description: `Ovoidal` },
          { id: "Columnar", description: `Columnar` },
          { id: "Extendida", description: `Extendida` },
          { id: "Abanico", description: `Abanico` },
          { id: "Pendular", description: `Pendular` },
          { id: "Palmiforme", description: `Palmiforme` },
        ]}
        name="topShape"
      />
      
      <Input
        width="96%"
        label="Ingrese descripcion:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.longDescription}
        name="longDescription"
      />
      
      <h3>Imagen de Especie</h3>
      <hr/>
      <ImageUploaderSpecie
      specie={{ specieId: data.id }}/>
      <img
        label="Imagen Actual"
        width="96%"
        src={fields.url}
        name="url"
      />
      {loading ? (
        <Loader />
      ) : (
        <>
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = ({
  auth: {
    user: { id: userId, roleId },
  },
  species: { specieFields },
}) => ({
  userId,
  roleId,
  specieFields,
})

const mapDispatchToProps = { getSpeciesFields, getSpecieById, modifySpecie }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
