import React from 'react'
import Container from './Container'
import Typography from './Typography'

function Header({ list }) {
  return list.map(x => (
    <Container flexDirection="column" alignItems="center">
      <Typography fontSize="32px" fontWeight="bold">
        {x.title}
      </Typography>
      <Typography>{x.subtitle}</Typography>
    </Container>
  ))
}

export default Header
