import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { faEllipsisV, faEdit } from '@fortawesome/free-solid-svg-icons'
import { getSubUnits } from '../../redux/actions'
import {
  MainContainer,
  Container,
  Table,
  Popover,
  Button,
  Typography,
  Modal,
} from '../../components'
import Form from './Form'

function SubUnits({ getSubUnits, subunits, adminUnitId }) {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)

  useEffect(() => {
    getSubUnits(adminUnitId)
  }, [adminUnitId])

  const columns = [
    { title: `Id`, dataIndex: `id`, sorter: (a, b) => a.id - b.id },
    {
      title: `Descripción`,
      dataIndex: `description`,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: `Unidad`,
      dataIndex: `Unit.name`,
      sorter: (a, b) => a.Unit.name.localeCompare(b.Unit.name),
    },
    {
      title: `Acción`,
      key: `action`,
      dataIndex: `action`,
      render: (text, record) => (
        <Popover
          title="Acción"
          icon={faEllipsisV}
          trigger="click"
          color="gray.2"
          content={
            <Container flexDirection="column">
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Editar"
                icon={faEdit}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleModal(true)
                }}
              />
              {/* <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Eliminar"
                icon={faTrashAlt}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleDeleteModal(true)
                }}
              /> */}
            </Container>
          }
        />
      ),
    },
  ]
  return (
    <>
      {visibleModal && (
        <Form
          data={entityWork}
          onCancel={() => {
            setEntityWork(null)
            setVisibleModal(false)
          }}
          onAccept={() => {
            setVisibleModal(false)
          }}
        />
      )}
      {visibleDeleteModal && (
        <Modal
          onCancel={() => {
            setVisibleDeleteModal(false)
          }}
          title="Eliminar cliente"
          onAccept={() => console.log(`eliminar sub unidad`)}
          visible={true}
        >
          <Typography>
            {`¿Está seguro que desea eliminar la sub unidad ${entityWork.description}?`}
          </Typography>
        </Modal>
      )}
      {subunits.length > 0 && (
        <MainContainer
          title="Sub Unidades"
          justifyContent="space-around"
          hasCreate={true}
          handleCreate={() => {
            setVisibleModal(true)
          }}
        >
          <Table
            marginTop="16px"
            columns={columns}
            dataSource={subunits}
            locale={{
              emptyText: `No existen sub unidades`,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: [`10`, `20`, `30`, `40`],
            }}
          />
        </MainContainer>
      )}
    </>
  )
}

const mapStateToProps = ({
  subunit: { subunits },
  general: { adminUnitId }
}) => ({
    subunits,
    adminUnitId
  })

const mapDispatchToProps = { getSubUnits }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubUnits)
