import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Container, Typography } from '../../components'
import API from '../../config/API'

function FieldsWrapper({ title, content }) {
  return (
    <Container flexDirection="column" width="47%" marginBottom="16px">
      <Typography fontWeight="bold">{title}</Typography>
      <Typography backgroundColor="gray.1" width="100%" padding="4px 8px">
        {content}
      </Typography>
    </Container>
  )
}

// eslint-disable-next-line complexity
function ViewTree({ data, onCancel, unitId, countVisits }) {
  const [fields, setfields] = useState([])
  async function getFields() {
    const fields = await API.getViewOrder(unitId)
    setfields(fields)
  }
  useEffect(() => {
    getFields()
  }, [])
  return (
    <Modal
      onAccept={onCancel}
      onCancel={onCancel}
      visible={true}
      title="Ver árbol"
      showCancel={false}
    >
      <Container justifyContent="space-between">
        <FieldsWrapper title="ID:" content={data.id} />
        <FieldsWrapper title="Visitas Registradas:" content={countVisits && countVisits.find(el => el.id == data.id) ? countVisits.find(el => el.id == data.id).count : 0} />
        {fields.map(field => {
          if (field.optionSource) {
            if (
              [
                `Arquitectura`,
                `Daño en copa`,
                `Daño en base del tronco`,
                `Daño en tronco`,
                `Daño en ramas principales superiores a 10cm`,
                `Daño en inserción de ramas`,
                `Estado Estructural de copas`,
                `Orientación inclinación`,
                `Árbol más próximo`,
                `Ancho de veredón`,
                `Arquitectura pobre`,
                `Copa Muerta`,
                `Forma`,
                `Inclinación`,
                `Orientación en Parque`,
                `Presencia de`,
                `Ubicación referencial`
              ].includes(field.fieldName)
            ) {
              return (
                <FieldsWrapper
                  title={field.fieldName}
                  content={data[field.optionSource] ? data[field.optionSource].description: `No Aplica`}
                />
              )
            }

            if (
              [
                `Agente patógeno 1`,
                `Agente patógeno 2`
              ].includes(field.fieldName)
            ) {
              return (
                <FieldsWrapper
                  title={field.fieldName}
                  content={data[field.optionSource] ? data[field.optionSource].description: `Sin`}
                />
              )
            }

            if (
              [
                `Recomendación`,
                `Recomendaciones 2`,
                `Recomendaciones 3`
              ].includes(field.fieldName)
            ) {
              return (
                <FieldsWrapper
                  title={field.fieldName}
                  content={data[field.optionSource] ? data[field.optionSource].description: `Sin Recomendación`}
                />
              )
            }

            if (field.fieldName === 'Interferencia') {
              return (
                <FieldsWrapper
                  title={field.fieldName}
                  content={data[field.optionSource] ? data[field.optionSource].description: `Sin Interferencia`}
                />
              )
            }

            if (field.fieldName === 'Tipo de Urbanización') {
              return (
                <FieldsWrapper
                  title={field.fieldName}
                  content={data[field.optionSource] ? data[field.optionSource].description: `Sin Urbanización Subterránea`}
                />
              )
            }

            if (field.optionSource === `specie`) {
              return (
                <>
                  <FieldsWrapper
                    title={field.fieldName || `Especie`}
                    content={
                      data[field.optionSource]
                        ? data[field.optionSource].description
                        : `N/A`
                    }
                  />
                  <FieldsWrapper
                    title="Nombre Cientifico:"
                    content={data.specie ? data.specie.scientificName : `-`}
                  />
                </>
              )
            }
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={
                  data[field.optionSource]
                    ? data[field.optionSource].description
                    : `N/A`
                }
              />
            )
          }
          if (field.fieldType === `boolean`) {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={data[field.treeField] ? `Si` : `No`}
              />
            )
          }
          if (field.fieldType === `date`) {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={
                  data[field.treeField]
                    ? moment(data[field.treeField]).format(`DD-MM-YYYY`)
                    : `N/D`
                }
              />
            )
          }
          if (field.fieldType === `address`) {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={`${data.streetName} ${data.streetNumber}`}
              />
            )
          }

          if (
            [
              `Arquitectura`,
              `Daño en copa`,
              `Daño en base del tronco`,
              `Daño en tronco`,
              `Daño en ramas principales superiores a 10cm`,
              `Daño en inserción de ramas`,
              `Estado Estructural de copas`,
              `Orientación inclinación`,
              `Árbol más próximo`,
              `Ancho de veredón`,
              `Arquitectura pobre`,
              `Copa Muerta`,
              `Forma`,
              `Inclinación`,
              `Orientación en Parque`,
              `Presencia de`,
              `Ubicación referencial`
            ].includes(field.fieldName)
          ) {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={data[field.treeField] ? data[field.treeField] : `No Aplica`}
              />
            )
          }

          if (
            [
              `Agente patógeno 1`,
              `Agente patógeno 2`
            ].includes(field.fieldName)
          ) {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={data[field.treeField] ? data[field.treeField] : `Sin`}
              />
            )
          }

          if (
            [
              `Recomendación`,
              `Recomendaciones 2`,
              `Recomendaciones 3`
            ].includes(field.fieldName)
          ) {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={data[field.treeField] ? data[field.treeField] : `Sin Recomendación`}
              />
            )
          }

          if (field.fieldName === 'Interferencia') {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={data[field.treeField] ? data[field.treeField] : `Sin Interferencia`}
              />
            )
          }

          if (field.fieldName === 'Tipo de Urbanización') {
            return (
              <FieldsWrapper
                title={field.fieldName}
                content={data[field.treeField] ? data[field.treeField] : `Sin Urbanización Subterránea`}
              />
            )
          }

          return (
            <FieldsWrapper
              title={field.fieldName}
              content={data[field.treeField] ? data[field.treeField] : `N/D`}
            />
          )
        })}
      </Container>
    </Modal>
  )
}

export default ViewTree
