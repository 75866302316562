import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  faEllipsisV,
  faEdit,
  faEye,
  faDollarSign,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {
  getAllOrderTasks,
  getOrderTaskFilters,
  getAllOrderTasksPaginate,
} from '../../redux/actions'
import {
  Typography,
  Loader,
  Container,
  Card,
  Table,
  Popover,
  Button,
  Modal,
  MainContainer,
} from '../../components'
import { isEmptyObj, statusPaidOTEnum, notification } from '../../utils'
import API from '../../config/API'
import OrderTaskFilter from './filtersOrderTask'
import EditOrderTaskPay from './editOrderTask'
import ShowOrderTask from './showOrderTask'
import CreateOrderTask from './createOrderTask'
import EditOrderTask from './editTask'

function OrdenTask({
  getAllOrderTasks,
  getOrderTaskFilters,
  getAllOrderTasksPaginate,
  orderTasks,
  count,
  loading,
  orderTaskFilters,
  user,
  adminUnitId,
  isLoading,
}) {
  const [state, setState] = useState({
    visibleEditOrderPayTaskModal: false,
    visibleEditOrderTaskModal: false,
    visibleShowOrderTaskModal: false,
    task: null,
    img: null,
    showImg: false,
    createTask: false,
    isUpdated: false,
    orders: [],
    page: 1,
    pageSize: 10,
    offset: 0,
    filters: {},
    showDelete: false,
    taskId: 0,
  })

  const handleEditOrderTaskPay = taskId => {
    const task = orderTasks.filter(({ id }) => id === taskId)[0]
    setState(prevState => ({
      ...prevState,
      visibleEditOrderPayTaskModal: true,
      visibleShowOrderTaskModal: false,
      visibleEditOrderTaskModal: false,
      task,
    }))
  }

  const handleEditOrderTask = taskId => {
    const task = orderTasks.filter(({ id }) => id === taskId)[0]
    setState(prevState => ({
      ...prevState,
      visibleEditOrderTaskModal: true,
      visibleShowOrderTaskModal: false,
      visibleEditOrderPayTaskModal: false,
      task,
    }))
  }

  const handleShowOrderTask = taskId => {
    const task = orderTasks.filter(({ id }) => id === taskId)[0]
    setState(prevState => ({
      ...prevState,
      visibleEditOrderPayTaskModal: false,
      visibleEditOrderTaskModal: false,
      visibleShowOrderTaskModal: true,
      task,
      isUpdated: false,
    }))
  }

  const handleCloseShowImg = () => {
    setState(prevState => ({
      ...prevState,
      visibleShowOrderTaskModal: true,
      img: null,
      showImg: false,
    }))
  }

  const handleShowDeleteOT = id => {
    setState(prevState => ({ ...prevState, showDelete: true, taskId: id }))
  }
  const handleDeleteCancelOT = () => {
    setState(prevState => ({ ...prevState, showDelete: false }))
  }

  const columns = [
    { title: `ID`, dataIndex: `id`, sorter: true },
    { title: `Codigo OT`, dataIndex: `code`, sorter: true },
    {
      title: `Tipo de Tarea`,
      dataIndex: `orderTask.description`,
      sorter: true,
      sorterPath: `orderTaskId`,
    },
    {
      title: `Plazo`,
      dataIndex: `daysAllow`,
      render: daysAllow => (
        <>
          {daysAllow ? (
            <Container
              style={{
                backgroundColor: daysAllow.background,
                color: daysAllow.color,
                padding: `4px 8px`,
                borderRadius: 50,
                width: 100,
                justifyContent: `center`,
              }}
            >{`${daysAllow.days} Día(s)`}</Container>
          ) : (
            `-`
          )}
        </>
      ),
    },
    {
      title: `Especie`,
      dataIndex: `tree.description`,
      sorter: true,
      sorterPath: `treeId`,
    },
    { title: `Dirección`, dataIndex: `addressMin` },
    {
      title: `Estado de OT`,
      dataIndex: `statusLast`,
    },
    {
      title: `Estado de pago`,
      dataIndex: `statusPaid`,
      sorter: true,
      sorterPath: `paymentStatus`,
    },
    {
      title: `Fecha Creación`,
      dataIndex: `createdAt`,
      sorter: true,
      sorterPath: `id`,
    },
    { title: `Última actualización`, dataIndex: `updatedAt` },
    {
      title: `Acción`,
      key: `action`,
      dataIndex: `id`,
      render: id => (
        <Popover
          title="Acción"
          icon={faEllipsisV}
          trigger="click"
          color="gray.2"
          content={
            <Container flexDirection="column">
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Ver detalle"
                icon={faEye}
                onClick={() => {
                  handleShowOrderTask(id)
                }}
              />
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Editar OT"
                icon={faEdit}
                onClick={() => {
                  handleEditOrderTask(id)
                }}
              />
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Editar pago"
                icon={faDollarSign}
                onClick={() => {
                  handleEditOrderTaskPay(id)
                }}
              />
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Eliminar OT"
                icon={faTrash}
                onClick={() => {
                  handleShowDeleteOT(id)
                }}
              />
            </Container>
          }
        />
      ),
    },
  ]

  const {
    visibleEditOrderPayTaskModal,
    visibleEditOrderTaskModal,
    visibleShowOrderTaskModal,
    task,
    img,
    showImg,
    createTask,
    isUpdated,
    orders,
    page,
    pageSize,
    offset,
    filters,
    showDelete,
    taskId,
  } = state

  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getOrderTaskFilters(unitId)
    }

    getAllOrderTasks()
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getOrderTaskFilters(adminUnitId)
    }
  }, [adminUnitId])

  const handleCloseEditOrderTask = () => {
    setState(prevState => ({
      ...prevState,
      visibleEditOrderPayTaskModal: false,
      task: null,
    }))
  }

  const handleIsUpdated = () => {
    const payload = {
      filters,
      offset,
      limit: pageSize,
      order: orders.length ? [orders] : orders,
    }

    getAllOrderTasksPaginate(payload)
  }

  const handleGetTaskWithFilters = filters => {
    const payload = {
      filters,
      offset,
      limit: pageSize,
      order: orders.length ? [orders] : orders,
    }
    setState(prevState => ({ ...prevState, filters }))
    getAllOrderTasksPaginate(payload)
  }

  const handleCloseShowOrderTask = () => {
    setState(prevState => ({
      ...prevState,
      visibleShowOrderTaskModal: false,
      task: null,
    }))
    if (isUpdated) handleIsUpdated()
  }

  const handleCloseCreateTask = () => {
    setState(prevState => ({
      ...prevState,
      createTask: false,
    }))
  }
  const handleCloseEditTask = () => {
    setState(prevState => ({
      ...prevState,
      visibleEditOrderTaskModal: false,
    }))
  }

  const onTableChange = (pagination, filtersTable, sorter) => {
    const directions = {
      ascend: `ASC`,
      descend: `DESC`,
    }
    const order = []
    if (!isEmptyObj(sorter)) {
      const field = sorter.column.sorterPath || sorter.field
      order.push(...field.split(`.`), directions[sorter.order])

      setState(prevState => ({ ...prevState, orders: order }))
    }
    if (!isEmptyObj(pagination)) {
      setState(prevState => ({
        ...prevState,
        page: pagination.current,
        pageSize: pagination.pageSize,
      }))
    }

    let offset = 0

    if (pagination.current === 1) {
      offset = 0
    } else {
      offset =
        pagination.current > page
          ? page * pagination.pageSize
          : pagination.current * pagination.pageSize
    }

    const payload = {
      offset,
      filters,
      limit: pagination.pageSize,
      order: order.length ? [order] : order,
    }

    getAllOrderTasksPaginate(payload)
    setState(prevState => ({
      ...prevState,
      offset,
    }))
  }

  const handleDeleteOT = async () => {
    try {
      await API.deleteTaskRequest(taskId)
      notification({
        type: `success`,
        message: `Orden de trabajo eliminada correctamente`,
      })
      handleDeleteCancelOT()
      handleIsUpdated()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {orderTaskFilters && (
        <CreateOrderTask
          open={createTask}
          onCancel={handleCloseCreateTask}
          orderTaskFilters={orderTaskFilters}
          handleIsUpdated={handleIsUpdated}
        />
      )}
      {orderTaskFilters && task && (
        <EditOrderTask
          open={visibleEditOrderTaskModal}
          onCancel={handleCloseEditTask}
          orderTaskFilters={orderTaskFilters}
          task={task}
          handleIsUpdated={handleIsUpdated}
        />
      )}
      {visibleEditOrderPayTaskModal && (
        <EditOrderTaskPay
          handleCloseEditOrderTask={handleCloseEditOrderTask}
          task={task}
        />
      )}
      {visibleShowOrderTaskModal && (
        <ShowOrderTask
          handleCloseShowOrderTask={handleCloseShowOrderTask}
          task={task}
          setState={setState}
          users={orderTaskFilters.users}
        />
      )}
      <Container width="100%">
        <MainContainer
          action={
            <Button
              text="Agregar OT"
              onClick={() =>
                setState(prevState => ({
                  ...prevState,
                  createTask: true,
                }))
              }
            />
          }
          title="Ordenes de trabajo"
          justifyContent="space-around"
        >
          {orderTaskFilters && (
            <OrderTaskFilter getTaskRequest={handleGetTaskWithFilters} />
          )}
        </MainContainer>
        <Card width="100%" borderColor="primary.3" marginTop="16px">
          <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
            Resultados búsqueda:
          </Typography>
          <Table
            marginTop="16px"
            columns={columns}
            dataSource={orderTasks}
            loading={isLoading}
            onChange={onTableChange}
            locale={{
              emptyText: `No existen tareas con los parametros ingresados`,
            }}
            pagination={{
              showSizeChanger: true,
              pageSize,
              current: page,
              total: orderTasks.length > 0 ? count : 0,
              pageSizeOptions: [`10`, `20`, `30`],
            }}
          />
        </Card>
      </Container>
      {showImg && (
        <Modal
          onAccept={handleCloseShowImg}
          onCancel={handleCloseShowImg}
          showCancel={false}
          visible={true}
        >
          <Container width="100%" justifyContent="center">
            <img src={img} alt="img-change-status-ot" width="50%" />
          </Container>
        </Modal>
      )}
      <Modal
        width="40%"
        onDelete={handleDeleteOT}
        onCancel={handleDeleteCancelOT}
        showCancel={true}
        showDelete={true}
        visible={showDelete}
      >
        <Container width="100%" justifyContent="center">
          <Typography fontWeight="bold" fontStyle="italic">
            ¿Seguro que desea eliminar la Orden de Trabajo?
          </Typography>
        </Container>
      </Modal>
    </>
  )
}

const mapStateToProps = ({
  auth: { user },
  orderTask: { orderTasks, loading, orderTaskFilters, count, isLoading },
  general: { adminUnitId },
}) => {
  const handleDaysAllow = ({ timeLimit, statuses, createdAt }) => {
    const findStatusDone = statuses.find(status => status.statusId === 2)
    if (findStatusDone) return false

    const remainingDays = timeLimit + moment(createdAt).diff(moment(), `days`)
    if (remainingDays >= 4 && remainingDays <= 6) {
      return { days: remainingDays, background: `#EFFE00`, color: `#000000` }
    }
    if (remainingDays >= 7) {
      return { days: remainingDays, background: `#4DFF00`, color: `#000000` }
    }

    return { days: remainingDays, background: `#FF0000`, color: `#FFFFFF` }
  }

  const mapOrderTaskWithStatusLast = orderTasks.map(task => {
    const {
      changeStatuses,
      paymentStatus,
      createdAt,
      updatedAt,
      streetName,
      streetNumber,
      timeLimit,
    } = task
    const statuses = changeStatuses.sort((a, b) => {
      return a.status.order - b.status.order
    })
    const last = statuses[statuses.length - 1]
    const {
      status: { description: statusLast },
    } = last
    const dateCreated = createdAt
    return {
      ...task,
      statusPaid: paymentStatus ? statusPaidOTEnum[paymentStatus] : `--`,
      statusLast,
      daysAllow: handleDaysAllow({ timeLimit, statuses, createdAt }),
      createdAt: moment(createdAt).format(`DD/MM/YYYY`),
      updatedAt: moment(updatedAt).format(`DD/MM/YYYY`),
      addressMin: `${streetName || ``}${
        Number(streetNumber) ? `, ${streetNumber}` : ``
      }`,
      dateCreated,
    }
  })

  return {
    user,
    orderTasks: mapOrderTaskWithStatusLast,
    count,
    loading,
    orderTaskFilters,
    adminUnitId,
    isLoading,
  }
}

const mapDispatchToProps = {
  getAllOrderTasks,
  getOrderTaskFilters,
  getAllOrderTasksPaginate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdenTask)
