export default {
  colors: {
    primary: [
      `#F1F7E7`, //0
      `#A7CF69`, //1
      `#62A004`, //2
      `#81BB2A`, //3
      `#87BF34`, //4
      `RGBA(167, 207, 105, 0.15)`, //5
      `rgba(144, 194, 63, 0.1)`, //6
    ],
    secondary: [`#0098D6`, `#00597D`, `#042E48`, `#0a132d`],
    gray: [`#fff`, `#F4F3F2`, `#4A4A4A`],
    red: [`#D0021B`],
    yellow: [`#F9D908`],
  },
  //          0   1   2   3   4   5   6   7   8    9
  fontSizes: [12, 14, 15, 16, 18, 20, 22, 24, 40, 32],
}
