import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import API from '../../config/API'
import { notification } from '../../utils'
import { MainContainer, Table, Typography, Loader } from '../../components'
import { InputTableStyle } from '../../components/styles'

function OrderFields({ loading, adminUnitId }) {
  const [fields, setFields] = useState([])

  const setOrderValue = (value, fieldId) => {
    const newFields = fields.map(field => {
      if (field.fieldId === fieldId) {
        return { ...field, order: value }
      }
      return field
    })
    setFields(newFields)
  }

  const columns = [
    {
      title: `Id`,
      dataIndex: `fieldId`,
      sorter: (a, b) => a.fieldId - b.fieldId,
    },
    {
      title: `Campo`,
      dataIndex: `Field.description`,
      sorter: (a, b) => a.Field.description.localeCompare(b.Field.description),
    },
    {
      title: `Orden`,
      dataIndex: `order`,
      render: (text, record) => {
        return (
          <InputTableStyle
            value={record.order}
            onChange={value => setOrderValue(value, record.fieldId)}
          />
        )
      },
    },
  ]

  async function getFields(unitId) {
    const unitFields = await API.getOrderedFields(unitId)
    setFields(unitFields)
  }

  useEffect(() => {
    if (adminUnitId !== null) {
      getFields(adminUnitId)
    }
  }, [adminUnitId])

  const setOrder = async () => {
    try {
      const result = await API.setOrderFields(
        fields.map(({ Field, ...rest }) => ({ ...rest })),
      )
      getFields(adminUnitId)
      notification({ type: `success`, message: result })
    } catch (error) {
      notification({
        type: `error`,
        message: `Error al guardar, intente nuevamente`,
      })
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {adminUnitId ? null : (
        <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
          Seleccione Unidad
        </Typography>
      )}
      {fields.length > 0 && (
        <MainContainer
          title="Orden Formulario"
          justifyContent="space-around"
          hasCreate={false}
          handleCreate={null}
          hasSave={true}
          handleSave={setOrder}
        >
          <Table
            marginTop="16px"
            columns={columns}
            dataSource={fields}
            locale={{
              emptyText: `Esta unidad aun no ha sido gestionada`,
            }}
            pagination={false}
          />
        </MainContainer>
      )}
    </>
  )
}

const mapStateToProps = ({ general: { adminUnitId } }) => ({ adminUnitId })

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderFields)
