import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { Modal, Container, Input, Typography } from '../../components'
import API from '../../config/API'
import {
  notification,
  treeAgesEnum,
  generalStateEnum,
  orderTaskPlantationEnum,
} from '../../utils'

function CreateOrderTask({
  open,
  onCancel,
  orderTaskFilters,
  task,
  handleIsUpdated,
}) {
  const [state, setState] = useState({
    code: ``,
    address: ``,
    treeDap: ``,
    treeId: ``,
    phytosanitaryStateId: ``,
    orderTaskId: ``,
    subUnitId: ``,
    generalState: ``,
    qtyTree: 1,
    locationId: ``,
    timeLimit: 0,
    treeAge: ``,
    treeHeight: ``,
    observation: ``,
    latitude: 0,
    longitude: 0,
    streetName: ``,
    streetNumber: `0`,
    streetData: [],
    trees: [],
    loadingCreation: false,
    taskId: 0,
    handleCheckInput: false,
  })
  const {
    code,
    address,
    treeDap,
    treeId,
    phytosanitaryStateId,
    orderTaskId,
    subUnitId,
    generalState,
    qtyTree,
    locationId,
    timeLimit,
    treeAge,
    treeHeight,
    observation,
    latitude,
    longitude,
    streetName,
    streetNumber,
    streetData,
    trees,
    loadingCreation,
    taskId,
    handleCheckInput,
  } = state

  const {
    subUnits = [],
    taskTypes = [],
    locations = [],
    phytosanitaryStates = [],
    species = [],
  } = orderTaskFilters

  useEffect(() => {
    const {
      code,
      address,
      timeLimit,
      subUnit: { id: subUnitId },
      orderTask: { id: orderTaskId },
      tree: { id: treeId },
      treeAge,
      treeDap,
      treeHeight,
      generalState,
      phytosanitaryState: { id: phytosanitaryStateId },
      location: { id: locationId },
      changeStatuses,
      latitude,
      longitude,
      streetName,
      streetNumber,
      id: taskId,
      qtyTree,
    } = task

    const { observation } = changeStatuses[0]

    setState(prevState => ({
      ...prevState,
      code,
      address,
      subUnitId,
      orderTaskId,
      timeLimit,
      treeId,
      trees: species,
      treeAge,
      treeDap,
      treeHeight,
      generalState,
      phytosanitaryStateId,
      locationId,
      observation,
      latitude,
      longitude,
      qtyTree,
      streetName,
      streetNumber,
      taskId,
    }))

    setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        treeId,
      }))
    }, 500)
  }, [task])

  useEffect(() => {
    if (orderTaskId && orderTaskId !== `` && handleCheckInput) {
      if (orderTaskId === orderTaskPlantationEnum) {
        const filterSpecies = species.filter(
          ({ unitPrice }) => parseFloat(unitPrice) > 0,
        )
        setState(prevState => ({
          ...prevState,
          trees: filterSpecies,
          treeId: ``,
        }))
      } else {
        setState(prevState => ({ ...prevState, trees: species, treeId: `` }))
      }
    }
  }, [orderTaskId])

  useEffect(() => {
    if (taskId) {
      setState(prevState => ({ ...prevState, handleCheckInput: true }))
    }
  }, [taskId])

  function onChange(name, value) {
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  async function onAccept() {
    setState(prevState => ({ ...prevState, loadingCreation: true }))
    try {
      const task = {
        code,
        address,
        treeDap,
        treeId,
        phytosanitaryStateId,
        orderTaskId,
        subUnitId,
        generalState,
        qtyTree,
        locationId,
        timeLimit,
        treeAge,
        treeHeight,
        observation,
        latitude,
        longitude,
        streetName,
        streetNumber,
        taskId,
      }
      await API.editTaskRequest(task)
      notification({
        type: `success`,
        message: `Orden de trabajo editada correctamente`,
      })
      handleIsUpdated()
      return onCancel()
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un error, reinteinte.`
      return notification({
        type: `error`,
        message: error,
      })
    }
  }

  const handleValidateField = () => {
    if (
      code.trim() === `` ||
      address.trim() === `` ||
      treeDap.trim() === `` ||
      treeId === `` ||
      phytosanitaryStateId === `` ||
      orderTaskId === `` ||
      subUnitId === `` ||
      locationId === `` ||
      generalState === ``
    ) {
      return notification({
        type: `error`,
        message: `No puede editar la OT con campos faltantes`,
      })
    }
    return onAccept()
  }

  const onSearchAddressChange = value => {
    if (value.length < 2) {
      return setState(prevState => ({ ...prevState, streetData: [] }))
    }
    if (value.length > 5) {
      const autocomplete = new window.google.maps.places.AutocompleteService()
      autocomplete.getPlacePredictions(
        { input: value, componentRestrictions: { country: `cl` } },
        predictions => {
          return setState(prevState => ({
            ...prevState,
            streetData: predictions,
          }))
        },
      )
    }
    return setState(prevState => ({ ...prevState, address: value }))
  }

  const [debounceSearch] = useDebouncedCallback(
    value => onSearchAddressChange(value),
    3000,
  )

  const onSelect = async data => {
    const street = JSON.parse(data)
    const streetNumber = street.structured_formatting.main_text.match(/\d+/g)
    setState(prevState => ({
      ...prevState,
      address: street.description,
    }))
    if (streetNumber) {
      setState(prevState => ({
        ...prevState,
        streetName: street.structured_formatting.main_text.replace(
          ` ${streetNumber[0]}`,
          ``,
        ),
        streetNumber: streetNumber[0].toString(),
      }))
      setState(prevState => ({ ...prevState, streetData: [] }))
    }
    const geoCode = await getGeocode({ address: street.description })
    const { lat, lng } = await getLatLng(geoCode[0])

    return setState(prevState => ({
      ...prevState,
      streetName: street.structured_formatting.main_text,
      latitude: lat,
      longitude: lng,
    }))
  }

  return (
    <Modal
      onAccept={handleValidateField}
      onCancel={onCancel}
      visible={open}
      title="Editar orden de trabajo"
      showCancel={true}
      confirmLoading={loadingCreation}
    >
      <Typography fontSize="6" type="subtitle">
        Editar los datos de la OT
      </Typography>
      <Container width="100%" justifyContent="space-between">
        <Input
          label="Código OT:"
          width="32%"
          onChange={onChange}
          name="code"
          value={code === `` ? undefined : code}
        />
        <Input
          label="Plazo: (Días)"
          width="32%"
          number
          onChange={onChange}
          name="timeLimit"
          value={timeLimit === `` ? undefined : timeLimit}
          min={0}
        />
        <Input
          label="Subunidad:"
          width="32%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="subUnitId"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={subUnitId === `` ? undefined : subUnitId}
          options={subUnits}
          keyValue="description"
        />
        <Input
          width="100%"
          type="address"
          label="Dirección:"
          value={address === `` ? undefined : address}
          onSearch={debounceSearch}
          options={streetData}
          name="address"
          onSelect={onSelect}
        />
        <Input
          label="Tipo:"
          width="49%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="orderTaskId"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={orderTaskId === `` ? undefined : orderTaskId}
          options={taskTypes}
          keyValue="description"
        />
        <Input
          label="Especie:"
          width="49%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="treeId"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={treeId === `` ? undefined : treeId}
          options={trees}
          keyValue="description"
          disabled={!trees.length}
        />
        <Input
          label="Edad:"
          width="32%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="treeAge"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={treeAge === `` ? undefined : treeAge}
          options={treeAgesEnum}
          keyValue="description"
        />
        <Input
          label="D.A.P:"
          width="32%"
          onChange={onChange}
          name="treeDap"
          value={treeDap === `` ? undefined : treeDap}
        />
        <Input
          label="Altura:"
          width="32%"
          onChange={onChange}
          name="treeHeight"
          value={treeHeight === `` ? undefined : treeHeight}
        />
        <Input
          number
          label="Número de árboles:"
          width="49%"
          onChange={onChange}
          name="qtyTree"
          value={qtyTree}
          min={1}
        />
        <Input
          label="Estado general:"
          width="49%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="generalState"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={generalState === `` ? undefined : generalState}
          options={generalStateEnum}
          keyValue="description"
        />
        <Input
          label="Estado fitosanitario:"
          width="49%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="phytosanitaryStateId"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={phytosanitaryStateId === `` ? undefined : phytosanitaryStateId}
          options={phytosanitaryStates}
          keyValue="description"
        />
        <Input
          label="Ubicación:"
          width="49%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="locationId"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={locationId === `` ? undefined : locationId}
          options={locations}
          keyValue="description"
        />
        <Input
          label="Observación:"
          width="100%"
          onChange={onChange}
          name="observation"
          value={observation === `` ? undefined : observation}
        />
      </Container>
    </Modal>
  )
}

export default CreateOrderTask
