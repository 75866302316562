import {
  TREES_LOADING,
  ERROR_TREES,
  GET_TREES,
  GET_TREE_FILTERS,
  GET_TREE_FIELDS,
  GET_TREE_BY_ID,
  SUCCESS_EDIT,
  SUCCESS_DELETE,
  GET_TREES_BY_UNIT,
  LOAD_TREES_BY_UNIT,
} from '../actions/types'

const initialState = {
  loading: false,
  trees: [],
  count: 0,
  treeFilters: null,
  treeFields: null,
  editableTree: null,
  treesByUnit: [],
  treesByUnitLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    //TREES ACTIONS
    case GET_TREE_FILTERS: {
      return {
        ...state,
        treeFilters: action.treeFilters,
      }
    }
    case GET_TREE_FIELDS: {
      return {
        ...state,
        treeFields: action.result,
      }
    }
    case GET_TREE_BY_ID:
      return {
        ...state,
        editableTree: action.result,
      }
    case SUCCESS_EDIT: {
      const treesCopy = [...state.trees]
      const index = treesCopy.findIndex(tree => tree.id === action.treeId)
      if (index !== undefined) {
        treesCopy[index] = { ...treesCopy[index], ...action.tree }
      }
      return {
        ...state,
        trees: treesCopy,
        treeFields: null,
        editableTree: null,
      }
    }
    case SUCCESS_DELETE: {
      return {
        ...state,
        trees: [],
      }
    }
    case TREES_LOADING: {
      return {
        ...state,
        trees: [],
        loading: true,
      }
    }
    case ERROR_TREES: {
      return {
        ...state,
        trees: [],
        loading: false,
      }
    }
    case GET_TREES: {
      return {
        ...state,
        trees: action.trees,
        count: action.count,
        countArboles: action.countArboles,
        countPalmeras: action.countPalmeras,
        countTocones: action.countTocones,
        countPV: action.countPV,
        countEspecies: action.countEspecies,
        countVisits: action.countVisits,
        loading: false,
      }
    }
    case LOAD_TREES_BY_UNIT: {
      return {
        ...state,
        treesByUnit: [],
        treesByUnitLoading: true,
      }
    }
    case GET_TREES_BY_UNIT: {
      return {
        ...state,
        treesByUnit: action.payload,
        treesByUnitLoading: false,
      }
    }
    default:
      return state
  }
}
