import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { compose, typography, color, layout, space } from 'styled-system'

const IconStyled = styled(FontAwesomeIcon)`
  ${compose(
    typography,
    color,
    layout,
    space,
  )}
  &:hover {
    ${color}
  }
  ${spin =>
    spin
      ? `
    @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation-delay: 1.45s;
  `
      : ``}
`
IconStyled.defaultProps = {
  fontSize: `5`,
  color: `gray.0`,
}

const Icon = ({ icon, ...props }) => <IconStyled icon={icon} {...props} />

export default Icon
