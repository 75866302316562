import { combineReducers } from 'redux'
import auth from './auth'
import assignTasks from './assignTasks'
import tasks from './tasks'
import requests from './requests'
import workers from './workers'
import trees from './trees'
import map from './map'
import users from './users'
import general from './general'
import genericFields from './genericFields'
import clients from './clients'
import subunit from './subunit'
import orderTask from './orderTask'
import species from './species'

export default combineReducers({
  auth,
  assignTasks,
  tasks,
  requests,
  workers,
  trees,
  map,
  users,
  general,
  genericFields,
  clients,
  subunit,
  orderTask,
  species,
})
