import React from 'react'
import { TitleStyle, SubtitleStyle, LabelStyle } from './styles'

const typographyType = {
  title: ({ children, ...props }) => {
    return <TitleStyle {...props}>{children}</TitleStyle>
  },
  subtitle: ({ children, ...props }) => (
    <SubtitleStyle {...props}>{children}</SubtitleStyle>
  ),
  label: ({ children, ...props }) => (
    <LabelStyle {...props}>{children}</LabelStyle>
  ),
}

const Typo = ({ children, type = `label`, ...props }) => {
  const Component = typographyType[type]
  return <Component {...props}>{children}</Component>
}

export default Typo
