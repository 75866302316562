import styled from 'styled-components'
import { compose, space, layout, position } from 'styled-system'

const Img = styled.img`
  ${compose(
    space,
    layout,
    position,
  )}
`
export default Img
