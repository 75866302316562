import styled from 'styled-components'
import {
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  grid,
  typography,
  compose,
  system,
} from 'styled-system'

const Container = styled.div`
  ${compose(
    space,
    color,
    layout,
    flexbox,
    border,
    position,
    grid,
    typography,
  )}
  ${({
    withShadow = false,
    overflowY = null,
    onClick = null,
    withTransition = false,
  }) => `
  ${overflowY ? `overflow-y: ${overflowY}` : ``}
  ${
    withShadow
      ? `-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3) !important;
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3) !important;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3) !important;
      `
      : ``
  }
  ${withTransition ? `transition: all ease 0.3s` : ``}
  ${onClick == null ? `` : `  cursor: pointer`};
  `}
  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        space,
        color,
        layout,
        flexbox,
        border,
        position,
        grid,
        typography,
      )({ ...props, ...hoverProps })}
    & > * {
      ${({ hoverProps, ...props }) =>
        compose(
          typography,
          system({ textDecoration: true }),
        )({ ...props, ...hoverProps })}
    }
  }
`
Container.defaultProps = {
  display: `flex`,
  flexDirection: `row`,
  flexWrap: `wrap`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
}

export default Container
