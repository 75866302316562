import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getTaskRequestWithFines } from '../../../redux/actions'
import { Typography, Loader, Container, Card, Table } from '../../../components'

import { currencyFormat } from '../../../utils'

const columns = [
  { title: `ID`, dataIndex: `id` },
  {
    title: `Código OT`,
    dataIndex: `code`,
  },
  {
    title: `Tipo de tarea`,
    dataIndex: `orderTask.description`,
  },
  {
    title: `Plazo`,
    dataIndex: `allowDays`,
    render: allowDays => (
      <>
        {allowDays ? (
          <Container
            style={{
              backgroundColor: allowDays.background,
              color: allowDays.color,
              padding: `4px 4px`,
              borderRadius: 50,
              width: 100,
              justifyContent: `center`,
            }}
          >{`${allowDays.days} Día(s)`}</Container>
        ) : (
          `-`
        )}
      </>
    ),
  },
  {
    title: `Especie`,
    dataIndex: `tree.description`,
  },
  {
    title: `Dirección`,
    dataIndex: `address`,
  },
  {
    title: `Estado de OT`,
    dataIndex: `statusLast`,
  },
  {
    title: `Motivo de multa`,
    dataIndex: `fines.description`,
  },
  {
    title: `Valor multa UTM`,
    dataIndex: `valueUT`,
  },
  {
    title: `Valor multa CLP`,
    dataIndex: `valueFine`,
  },
]

function Fines({
  getTaskRequestWithFines,
  loading,
  orderTaskWithFines,
  valueUTM,
}) {
  useEffect(() => {
    getTaskRequestWithFines()
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Container width="100%">
        <Container width="100%" display="flex" alignItems="center">
          <Container width="20%">
            <Typography type="title">Multas</Typography>
          </Container>
        </Container>
        <Card
          width="100%"
          borderColor="primary.3"
          display="flex"
          marginTop="16px"
          paddingTop="0px"
          style={{ flexWrap: `wrap` }}
        >
          <Container display="flex" width="100%" justifyContent="flex-end">
            <Typography
              type="label"
              color="primary.3"
            >{`Valor UTM hoy: ${currencyFormat(valueUTM)}`}</Typography>
          </Container>
          <Container width="100%" marginTop="8px">
            <Table
              marginTop="16px"
              columns={columns}
              dataSource={orderTaskWithFines}
              loading={loading}
              locale={{
                emptyText: `No existen orden de trabajo para el rango seleccionado`,
              }}
            />
          </Container>
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = ({
  orderTask: { orderTaskWithFines, loading, valueUTM },
  auth: { user },
}) => {
  const handleDaysAllow = ({ timeLimit, statuses, createdAt }) => {
    const findStatusDone = statuses.find(status => status.statusId === 2)
    if (findStatusDone) return false

    const remainingDays = timeLimit + moment(createdAt).diff(moment(), `days`)
    if (remainingDays >= 4 && remainingDays <= 6) {
      return { days: remainingDays, background: `#EFFE00`, color: `#000000` }
    }
    if (remainingDays >= 7) {
      return { days: remainingDays, background: `#4DFF00`, color: `#000000` }
    }

    return { days: remainingDays, background: `#FF0000`, color: `#FFFFFF` }
  }

  const mapOrderTaskWithFines = orderTaskWithFines
    .map(task => {
      const { timeLimit, changeStatuses, price, createdAt } = task
      const last = changeStatuses[changeStatuses.length - 1]
      const {
        status: { description: statusLast },
      } = last
      const fines = { description: `Plazo máximo de ejecución excedido` }
      const allowDays = handleDaysAllow({
        timeLimit,
        statuses: changeStatuses,
        createdAt,
      })
      if (allowDays && allowDays.days < 0) {
        const fine = Math.abs(allowDays.days) * 3 * valueUTM

        return {
          ...task,
          createdAt: moment.utc(task.createdAt).format(`DD/MM/YYYY`),
          statusLast,
          fines,
          timeLimit: `${timeLimit} Día(s)`,
          price: currencyFormat(price),
          valueUT: Math.abs(allowDays.days) * 3,
          allowDays,
          valueFine: currencyFormat(fine),
        }
      }
      return false
    })
    .filter(task => task)

  return {
    user,
    orderTaskWithFines: mapOrderTaskWithFines,
    loading,
    valueUTM,
  }
}

const mapDispatchToProps = {
  getTaskRequestWithFines,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fines)
