import styled from 'styled-components'

import { compose, border, space, shadow, layout, flexbox } from 'styled-system'

const Card = styled.div`
  ${compose(
    border,
    space,
    shadow,
    layout,
    flexbox,
  )}
`
Card.defaultProps = {
  padding: `20px`,
  marginBottom: `20px`,
  boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.260954)`,
}

export default Card
