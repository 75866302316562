import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  getOrderTaskApplyfilters,
  getAllOrderTasks,
} from '../../../redux/actions'
import {
  Card,
  Container,
  Input,
  Typography,
  Button,
  Loader,
} from '../../../components'

const initialFilters = {
  taskType: ``,
  specie: ``,
  subUnit: ``,
  startDate: ``,
  endDate: ``,
  statusPay: ``,
  createdAt: ``,
  code: ``,
  statusId: ``,
}

function OrderTaskFilterToMap({
  getAllOrderTasks,
  orderTaskFilters,
  getOrderTaskApplyfilters,
  loading,
  filtersSelected,
}) {
  const [filters, setFilters] = useState(
    filtersSelected ? filtersSelected : initialFilters,
  )
  const { taskTypes, species, subUnits, statuses, zones } = orderTaskFilters
  const handleInput = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const getTasks = () => {
    getOrderTaskApplyfilters(filters)
  }

  const handleResetDataGetTasksAll = () => {
    setFilters(initialFilters)
    getAllOrderTasks()
  }

  if (loading) {
    return <Loader />
  }

  if (!orderTaskFilters) {
    return null
  }
  return (
    <Card
      width="100%"
      borderColor="gray"
      marginTop={3}
      borderTop="4px solid"
      borderRadius={4}
    >
      <Container
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Typography type="label" fontWeight="bold" fontStyle="italic">
          Nueva búsqueda:
        </Typography>
        <Container>
          <Button
            onClick={getTasks}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
          />
          <Button
            onClick={handleResetDataGetTasksAll}
            text="Limpiar Búsqueda"
            color="primary.3"
            backgroundColor="transparent"
          />
        </Container>
      </Container>
      <Container justifyContent="space-between" marginTop="32px">
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo de tarea:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="taskTypeId"
          keyValue="description"
          options={taskTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.taskTypeId === `` ? undefined : filters.taskTypeId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Especie:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="specieId"
          keyValue="description"
          options={species}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.specieId === `` ? undefined : filters.specieId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Sub Unidad:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="subUnitId"
          keyValue="description"
          options={subUnits}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.subUnitId === `` ? undefined : filters.subUnitId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado de pago"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="statusPay"
          keyValue="description"
          options={[
            { description: `Pendiente`, id: `PENDING` },
            { description: `Pagado`, id: `PAID` },
          ]}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.statusPay === `` ? undefined : filters.statusPay}
        />
        <Input
          width="32%"
          type="date"
          optionFilterProp="children"
          label="Fecha de creación"
          onChange={handleInput}
          name="createdAt"
          value={filters.createdAt === `` ? undefined : filters.createdAt}
        />
        <Input
          width="32%"
          label="Código"
          onChange={handleInput}
          name="code"
          value={filters.code === `` ? undefined : filters.code}
        />
        <Input
          width="32%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado de OT:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="statusId"
          keyValue="description"
          options={statuses}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.statusId === `` ? undefined : filters.statusId}
        />
      </Container>
    </Card>
  )
}

const mapStateToProps = ({
  orderTask: { orderTaskFilters, loading, filtersSelected },
  auth: { user },
}) => ({
  orderTaskFilters,
  user,
  loading,
  filtersSelected,
})

const mapDispatchToProps = {
  getOrderTaskApplyfilters,
  getAllOrderTasks,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderTaskFilterToMap)
