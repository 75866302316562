import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Tag, Spin } from 'antd'
import {
  Input,
  MainContainer,
  Table,
  Popover,
  Container,
  Button,
  Modal,
  Typography,
  Loader,
} from '../../components'
import { getGenericFields, removeGenericField } from '../../redux/actions'
import Form from './Form'
import { filterData } from './filterData'

import supportedModels from './supportedModels'

const GenericFields = ({
  getGenericFields,
  removeGenericField,
  loading,
  genericFields,
  unitsById,
  units,
}) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)
  const [data, setData] = useState([])
  const [dataVisible, setDataVisible] = useState([])
  const [columns, setColumns] = useState([])
  const [currentModel, setCurrentModel] = useState(supportedModels[0])
  const [models, setModels] = useState(supportedModels)
  const [filteredSpecies, setFilteredSpecies] = useState([])
  const [filterValue, setFilterValue] = useState(``)
  const [createField, setCreateField] = useState(false)

  const filterSpecies = value => {
    setFilterValue(value)
    const filtered = filterData({
      data: genericFields, //aki data
      filterText: value,
      filters: [`description`, `scientificName`],
    })
    setFilteredSpecies(filtered)
  }

  const onModelChange = (label, newModelId) => {
    const newModel = supportedModels.find(sm => sm.id === newModelId)
    setCurrentModel(newModel)
    getGenericFields(newModel.routeModel)
  }

  const extraColumns = currentModel => {
    const { additionalFields } = currentModel
    if (additionalFields && additionalFields.length > 0) {
      return additionalFields.map(af => ({
        title: af.label,
        key: af.name,
        dataIndex: af.name,
        sorter: (a, b) => {
          if (a[af.name] && b[af.name]) {
            return a[af.name].localeCompare(b[af.name])
          }
          return -1
        },
      }))
    } else {
      return []
    }
  }

  const tableColumns = [
    { title: `ID`, key: `id`, dataIndex: `id`, sorter: (a, b) => a.id - b.id },
    {
      title: `Descripción`,
      key: `description`,
      dataIndex: `description`,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    ...extraColumns(currentModel),
    {
      title: `Unidades visibles`,
      key: `unitConfig`,
      dataIndex: `unitConfig`,
      render: config => {
        if (!config || config.belongsTo.length === 0) {
          return <Typography>Sin configuración</Typography>
        }
        if (config.belongsTo.length === units.length) {
          return <Tag color="green">Todos</Tag>
        }
        return config.belongsTo.map(unitId => (
          <Tag color="green">
            {unitsById[unitId] ? unitsById[unitId].name : <Spin />}
          </Tag>
        ))
      },
    },
    {
      title: `Acción`,
      key: `action`,
      dataIndex: `action`,
      render: (text, record) => (
        <Popover
          title="Acción"
          icon={faEllipsisV}
          trigger="click"
          color="gray.2"
          content={
            <Container flexDirection="column">
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Editar"
                icon={faEdit}
                onClick={() => {
                  const { unitConfig, ...rest } = record
                  const entity = {
                    ...rest,
                    unitsToBelong: unitConfig ? unitConfig.belongsTo : [],
                  }
                  setEntityWork(entity)
                  setVisibleModal(true)
                }}
              />
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Eliminar"
                icon={faTrashAlt}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleDeleteModal(true)
                }}
              />
            </Container>
          }
        />
      ),
    },
  ]

  useEffect(() => {
    getGenericFields(currentModel.routeModel)
    setModels(supportedModels.sort((a, b) => a.label.localeCompare(b.label)))
  }, [])

  useEffect(() => {
    setData(genericFields)
    setDataVisible(genericFields)
    setColumns(tableColumns)
    if (currentModel.routeModel === `Specie`) {
      setFilterValue(``)
      setFilteredSpecies(genericFields)
    }
  }, [genericFields])

  const clearModalData = () => {
    setEntityWork(null)
    setVisibleModal(false)
    setCreateField(false)
  }

  if (loading) {
    return <Loader />
  }
  return (
    <MainContainer
      title="Campos Selección Múltiple"
      hasSearch={false}
      hasCreate={true}
      handleCreate={() => {
        setVisibleModal(true)
        setCreateField(true)
      }}
      defaultData={() => {
        setDataVisible(data)
      }}
      handleRefresh={() => {
        getGenericFields()
      }}
      personalizedHeader={
        <Input
          type="select"
          value={currentModel.label}
          label="Selecciona el campo:"
          name="label"
          keyValue="label"
          onChange={onModelChange}
          options={models}
          width="200px"
          margin="0"
          padding="0"
        />
      }
    >
      {visibleModal && (
        <Form
          clearModalData={clearModalData}
          onCancel={() => {
            setEntityWork(null)
            setVisibleModal(false)
            setCreateField(false)
          }}
          createField={createField}
          entity={entityWork}
          currentModel={currentModel}
        />
      )}
      {visibleDeleteModal && (
        <Modal
          onAccept={() => {
            removeGenericField({
              modelName: currentModel.routeModel,
              id: entityWork.id,
            })
            setVisibleDeleteModal(false)
          }}
          onCancel={() => {
            setVisibleDeleteModal(false)
          }}
          visible={true}
          title="Eliminar usuario"
        >
          <Typography>¿Seguro que deseas eliminar el valor?</Typography>
        </Modal>
      )}
      {currentModel.routeModel === `Specie` && (
        <Input
          value={filterValue}
          label="Buscar:"
          name="searchText"
          keyValue="label"
          onChange={(name, value) => filterSpecies(value)}
          width="100%"
          margin="0"
          padding="10px"
        />
      )}
      {
        <Table
          columns={columns}
          dataSource={
            currentModel.routeModel === `Specie` ? filteredSpecies : dataVisible
          }
          locale={{ emptyText: `Colección vacía` }}
        />
      }
    </MainContainer>
  )
}

const mapStateToProps = ({
  genericFields: {
    loading,
    error,
    genericFields: genericFieldIds,
    genericFieldsById,
  },
  general: { units: rawUnits = [] },
}) => {
  const genericFields = genericFieldIds.map(id => genericFieldsById[id])
  const unitsById = {}
  const units = rawUnits
    ? rawUnits.map(unit => {
        unitsById[unit.id] = unit
        return unit.id
      })
    : []
  return {
    loading,
    error,
    genericFields,
    unitsById,
    units,
  }
}

const mapDispatchToProps = {
  getGenericFields,
  removeGenericField,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericFields)
