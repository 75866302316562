import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { Divider } from 'antd'
import { logOut } from '../../redux/actions'
import routes from '../../config/routes'
import logo from '../../assets/logo_blanco.svg'
import metroprovLogo from '../../assets/metroprov.png'
import { Icon, Container } from '../../components'
import {
  // SideBarContainer as Container,
  LogoContainer,
  Logo,
  LinkStyled,
  LinkChildStyled,
} from './styles'

function SideBar({ roleId, unitId, location: { pathname } }) {
  const [isCollapsed, setCollapsed] = useState(false)
  return (
    <Container
      width={isCollapsed ? `4%` : `15%`}
      height="100vh"
      overflowY="auto"
      paddingY="1em"
      alignContent="flex-start"
      backgroundColor="primary.3"
      justifyContent="center"
      position="relative"
      withTransition
    >
      <Container
        hoverProps={{
          border: `0.1em solid`,
          borderColor: `primary.1`,
        }}
        position="absolute"
        right={0}
        top={56}
        zIndex="900"
        borderRadius="200px 10px 10px 200px"
        padding="2"
        width="2em"
        height="2em"
        justifyContent="center"
        alignContent="center"
        backgroundColor="white"
        onClick={() => setCollapsed(!isCollapsed)}
      >
        <Icon
          icon={isCollapsed ? faChevronRight : faChevronLeft}
          fontSize="3"
          color="primary.2"
        />
      </Container>
      <LogoContainer>
        
        { // Cambiando logo para ML7-Providencia (id 34)
        unitId === 34 ? (
          <Logo src={metroprovLogo} alt="logo" isCollapsed={isCollapsed} />
        ) : (
          <Logo src={logo} alt="logo" isCollapsed={isCollapsed} />
        )}
      </LogoContainer>

      <Container marginTop="3em" width="100%" paddingLeft="1">
        {routes.map(
          ({
            key,
            icon,
            title,
            path,
            childrens,
            rolesIncluded,
            lb,
            showDivider,
          }) => {
            let isActive = path === pathname
            let childRoutes = null
            if (childrens) {
              childRoutes = childrens.map(({ key, title, path }) => {
                const isActiveChild = path === pathname
                if (isActiveChild) {
                  isActive = true
                }
                return (
                  <LinkChildStyled key={key} active={isActiveChild} to={path}>
                    <span>{title}</span>
                  </LinkChildStyled>
                )
              })
            }
            if (rolesIncluded && !rolesIncluded.includes(roleId)) {
              return null
            }
            if (key === `5-1` && unitId !== 1) {
              return null
            }
            return (
              <>
                {roleId === 1 && showDivider && (
                  <Divider style={{ margin: `4px 0px` }} />
                )}
                <LinkStyled
                  isCollapsed={isCollapsed}
                  key={key}
                  active={isActive}
                  to={path}
                >
                  <Icon icon={icon} />
                  {!isCollapsed && (
                    <span>
                      {title}
                      {roleId === 1 && lb ? ` LB` : ``}
                    </span>
                  )}
                </LinkStyled>
                {childRoutes}
              </>
            )
          },
        )}
      </Container>
    </Container>
  )
}

const mapStateToProps = ({
  auth: {
    user: { roleId, unitId },
  },
}) => ({ roleId, unitId })

const mapDispatchToProps = {
  logOut,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SideBar),
)
