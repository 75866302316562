import API from '../../config/API'
import {
  GENERAL_LOADING,
  GET_GENERAL_DATA,
  GET_GENERAL_DATA_ERROR,
  SET_UNIT_ID,
} from './types'

export const getGeneralData = () => async dispatch => {
  dispatch({ type: GENERAL_LOADING })
  try {
    const roles = await API.getRoles()
    const units = await API.getUnits()
    dispatch({ type: GET_GENERAL_DATA, roles, units })
  } catch (error) {
    dispatch({ type: GET_GENERAL_DATA_ERROR, error })
  }
}

export const setUnitId = unitId => dispatch =>
  dispatch({ type: SET_UNIT_ID, unitId })
