import axios from 'axios'

const API = {}
const env = process.env.REACT_APP_API_ENV || `local`
const urls = {
  prod: `https://arbotag.apisaltala.com`,
  dev: `http://arbotagqa.apisaltala.com`,
  local: `http://arbotag.jorgecourbis.io`,
}

const BASE_URL = urls[env]

const axiosInstance = axios.create({
  baseURL: BASE_URL,
})
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(`arbotag-token`)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error),
)

function validateRequest(request) {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        data: { result, error, success },
      } = await request()
      if (success) {
        resolve(result)
      } else {
        reject(error)
      }
    } catch (e) {
      reject(e)
    }
  })
}

//AUTH
API.loginUser = ({ email, password }) =>
  validateRequest(() => axiosInstance.post(`/auth`, { email, password }))

//TASKS
API.getAssignFilteredTasks = () =>
  validateRequest(() => axiosInstance.get(`/task/getFilteredTasks`))

API.getReviewFilteredTasks = () =>
  validateRequest(() => axiosInstance.get(`/task/getReviewTasks`))

API.assignReview = ({ documentId, treeId, body }) =>
  validateRequest(() =>
    axiosInstance.put(`/task/update/${documentId}`, {
      updateType: 6,
      treeId,
      body,
    }),
  )

API.getFilteredTasks = filters =>
  validateRequest(() =>
    axiosInstance.post(`/task/getFilteredTasks`, { filters }),
  )

API.downloadTaskCsv = filters =>
  validateRequest(() => axiosInstance.post(`/task/downloadCsv`, { filters }))

API.getTaskFilters = unitId =>
  validateRequest(() => axiosInstance.post(`/task/getTaskFilters`, { unitId }))

API.editTask = (taskId, task) =>
  validateRequest(() => axiosInstance.put(`/task`, { taskId, task }))

API.deleteTask = (taskId, task) =>
  validateRequest(() =>
    axiosInstance.post(`/task/deleteTask`, { taskId, task }),
  )

//Task Reports
API.getTaskReportFilters = unitId =>
  validateRequest(() =>
    axiosInstance.get(`/taskReport/getTaskReportFilters`, {
      params: { unitId },
    }),
  )

API.getTasksBySpecies = filters =>
  validateRequest(() =>
    axiosInstance.get(`/taskReport/getTasksBySpecies`, {
      params: filters,
    }),
  )

API.getTasksByType = filters =>
  validateRequest(() =>
    axiosInstance.get(`/taskReport/getTasksByType`, {
      params: filters,
    }),
  )

API.getTasksByOrigin = filters =>
  validateRequest(() =>
    axiosInstance.get(`/taskReport/getTasksByOrigin`, {
      params: filters,
    }),
  )

API.getTaskReportByMonth = date =>
  validateRequest(() =>
    axiosInstance.get(`/taskReport/getTaskReportByMonth`, {
      params: { date },
    }),
  )

API.getTaskPerformance = date =>
  validateRequest(() =>
    axiosInstance.get(`/taskReport/getTaskPerformance`, {
      params: { date },
    }),
  )

//TREES
API.getFilteredTrees = filters =>
  validateRequest(() =>
    axiosInstance.post(`/tree/getFilteredTrees`, { filters }),
  )
API.getMapFilteredTrees = filters =>
  validateRequest(() =>
    axiosInstance.post(`/tree/getMapFilteredTrees`, { filters }),
  )

API.downloadTreeCsv = filters =>
  validateRequest(() => axiosInstance.post(`/tree/downloadCsv`, { filters }))

API.getTreeFilters = unitId =>
  validateRequest(() => axiosInstance.post(`/tree/getTreeFilters`, { unitId }))

API.getTreeFields = (unitId, userId) =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/tree/getFields`, { unitId, userId }),
  )

API.getTreeById = treeId =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/tree/getTreeById`, { treeId }),
  )

API.editTree = (treeId, tree) =>
  validateRequest(() => axiosInstance.put(`/tree`, { treeId, tree }))

API.removeTree = treeId =>
  validateRequest(() => axiosInstance.delete(`/tree`, { data: { treeId } }))

API.getViewOrder = unitId =>
  validateRequest(() =>
    axiosInstance.get(`/tree/getViewOrder`, { params: { unitId } }),
  )
API.findByStreetName = ({ streetName, unitId }) =>
  validateRequest(() =>
    axiosInstance.get(`/tree/findByStreetName`, {
      params: { streetName, unitId },
    }),
  )
API.getTreesByUnitId = unitId =>
  validateRequest(() => axiosInstance.get(`/tree/getTrees?unitId=${unitId}`))

API.generateQR = (fromId, toId, userId) => {
  return axiosInstance.post(`/tree/generateQR`, { fromId, toId, userId })
}


//MAP TREES
API.getMapTreeFilters = municipalityId => {
  return axiosInstance.get(
    `/tree/getMapTreeFilters?municipalityId=${municipalityId}`,
  )
}

API.getFilteredMapTrees = filters => {
  return axiosInstance.post(`/tree/getFilteredMapTrees`, {
    filters,
  })
}



API.uploadTreeCsv = data => {
  let sData = JSON.stringify(data);
  return validateRequest(() => axiosInstance.post(`/tree/uploadTreeCsv`, { sData }))
}



// REFACTOR
// GENERAL
API.getRoles = () => validateRequest(() => axiosInstance.get(`/user/getRoles`))

API.getUnits = () => validateRequest(() => axiosInstance.get(`/unit`))

API.getUsers = (unitId, roleId) =>
  validateRequest(() =>
    axiosInstance.get(`/user?unitId=${unitId}&roleId=${roleId}`),
  )

API.addUser = user => validateRequest(() => axiosInstance.post(`/user`, user))

API.editUser = user => validateRequest(() => axiosInstance.put(`/user`, user))

API.removeUser = userId =>
  validateRequest(() => axiosInstance.delete(`/user`, { data: { userId } }))

//GENERIC FIELDS
API.getGenericFields = modelName =>
  validateRequest(() => axiosInstance.get(`/genericField/${modelName}`))

API.addGenericField = ({ modelName, ...fields }) =>
  validateRequest(() =>
    axiosInstance.post(`/genericField/${modelName}`, fields),
  )

API.editGenericField = ({ modelName, ...fields }) =>
  validateRequest(() =>
    axiosInstance.put(`/genericField/${modelName}`, { ...fields }),
  )

API.removeGenericField = ({ modelName, id }) =>
  validateRequest(() =>
    axiosInstance.delete(`/genericField/${modelName}`, { data: { id } }),
  )

// TASKS
API.assignTask = (taskId, assignedById, assignedToId, treeId) =>
  validateRequest(() =>
    axiosInstance.put(`${BASE_URL}/task/assignTask`, {
      taskId,
      assignedToId,
      assignedById,
      treeId,
    }),
  )

API.craeteTask = ({ task }) =>
  validateRequest(() =>
    axiosInstance.post(`/task/`, {
      task,
    }),
  )

API.createTaskByAddress = ({
  task,
  streetName,
  unitId,
  specieId,
  subUnitId,
  dapId,
  relativeAgeId,
}) =>
  validateRequest(() =>
    axiosInstance.post(`/task/createTaskByAddress`, {
      task,
      streetName,
      unitId,
      specieId,
      subUnitId,
      dapId,
      relativeAgeId,
    }),
  )

// CLIENTS
API.addUnit = (unit, projectInfo) =>
  validateRequest(() => axiosInstance.post(`${BASE_URL}/unit`, { unit, projectInfo }))

API.getUnitValues = unitId =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/unit/getUnitValues`, { unitId }),
  )

API.getOrderedFields = unitId =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/unit/getOrderedFields`, { unitId }),
  )

API.setOrderFields = fields =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/unit/setOrderFields`, { fields }),
  )

API.setupUnit = (unit, unitId) =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/unit/setupUnit`, { unit, unitId }),
  )

API.editUnit = (unitId, unit, projectInfo) =>
  validateRequest(() => axiosInstance.put(`${BASE_URL}/unit`, { unitId, unit, projectInfo }))

API.getFields = () =>
  validateRequest(() => axiosInstance.get(`${BASE_URL}/unit/getFields`))

API.getSpecies = () =>
  validateRequest(() => axiosInstance.get(`${BASE_URL}/unit/getSpecies`))

API.getDaps = () =>
  validateRequest(() => axiosInstance.get(`${BASE_URL}/unit/getDaps`))

API.getTaskTypes = () =>
  validateRequest(() => axiosInstance.get(`${BASE_URL}/unit/getTaskTypes`))

API.getRequestTypes = () =>
  validateRequest(() => axiosInstance.get(`${BASE_URL}/unit/getRequestTypes`))

API.uploadImgClient = (formData) => {
  return validateRequest(() => axiosInstance.post(`/unit/uploadImgClient`, formData,{headers: { 
    "Content-Type": "multipart/form-data",
      }}))
}

//SUB UNITS
API.getSubUnits = (unitId) =>
  validateRequest(() => axiosInstance.get(`${BASE_URL}/subUnit`, {
    params: { unitId },
  }))

API.createSubUnit = subunit =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/subUnit`, { ...subunit }),
  )

API.editSubUnit = (subUnitId, subunit) =>
  validateRequest(() =>
    axiosInstance.put(`${BASE_URL}/subUnit`, { subUnitId, subunit }),
  )

//REQUEST
API.getRequestFilters = unitId =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/request/getRequestFilters`, { unitId }),
  )

API.getFilteredRequests = filters =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/request/getFilteredRequests`, { filters }),
  )

API.assignRequest = (
  requestId,
  assignedInspectorId,
  treeId,
  citizenTokens,
  citizenMessage,
) =>
  validateRequest(() =>
    axiosInstance.put(`${BASE_URL}/request/assignRequest`, {
      requestId,
      assignedInspectorId,
      treeId,
      citizenTokens,
      citizenMessage,
    }),
  )

API.rejectRequest = (requestId, rejectedById, citizenTokens) =>
  validateRequest(() =>
    axiosInstance.put(`${BASE_URL}/request/rejectRequest`, {
      requestId,
      rejectedById,
      citizenTokens,
    }),
  )

API.deleteRequest = id =>
  validateRequest(() =>
    axiosInstance.delete(`${BASE_URL}/request/delete/${id}`),
  )

API.approveRequest = (
  requestId,
  treeId,
  createdById,
  taskTypeId,
  taskPriorityId,
  citizenTokens,
  observation,
) =>
  validateRequest(() =>
    axiosInstance.put(`${BASE_URL}/request/approveRequest`, {
      requestId,
      treeId,
      createdById,
      taskTypeId,
      taskPriorityId: taskPriorityId || 4,
      citizenTokens,
      observation,
    }),
  )

API.downloadRequestCsv = filters =>
  validateRequest(() => axiosInstance.post(`/request/downloadCsv`, { filters }))

API.massiveNotification = message =>
  validateRequest(() =>
    axiosInstance.post(`/unit/massiveNotification`, message),
  )

// Species
API.getAllSpecies = () =>
  validateRequest(() => axiosInstance.get(`specie/getAllSpecies`))
API.getSpecieById = specieId =>
  validateRequest(() =>
    axiosInstance.post(`${BASE_URL}/specie/getSpecieById`, { specieId }),
  )
API.editSpecie = (specieId, specie) =>
  validateRequest(() => axiosInstance.put(`/specie`, { specieId, specie }))
API.uploadImgSpecie = (formData) => {
  return validateRequest(() => axiosInstance.post(`/specie/uploadImgSpecie`, formData,{headers: { 
    "Content-Type": "multipart/form-data",
      }}))
}



// OT
API.getAllTask = () =>
  validateRequest(() => axiosInstance.get(`taskRequest/getAllTask/web`))
API.editPayTask = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest/pay`, payload))
API.getAllTaskWithFilters = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest/getAllTask`, payload))
API.getTaskPriorities = () =>
  validateRequest(() => axiosInstance.get(`task/getTaskPrioritiesWithColors`))
API.getCountTaskRequest = () =>
  validateRequest(() => axiosInstance.get(`taskRequest/report/count`))
API.getCountTaskRequestFilters = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest/report/count`, payload))
API.getStatusesTaskRequest = () =>
  validateRequest(() => axiosInstance.get(`taskRequest/getStatuses`))
API.downloadCsvTaskRequest = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest/downloadCsv`, payload))
API.getOrderTaskType = () =>
  validateRequest(() => axiosInstance.get(`orderTask/getOrderTasks`))
API.getTree = () =>
  validateRequest(() => axiosInstance.get(`orderTask/getLBTrees`))
  API.getZone = () =>
  validateRequest(() => axiosInstance.get(`orderTask/getLBZones`))
API.getUsersBoss = () =>
  validateRequest(() => axiosInstance.get(`orderTask/getUsers`))
API.assignTaskRequest = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest/assign`, payload))
API.getBilligTaskRequest = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest/billing`, payload))
API.generateCsvBilligTaskRequest = payload =>
  validateRequest(() =>
    axiosInstance.post(`taskRequest/billing/generateCsv`, payload),
  )
API.getPhytosanitaryStatesByUnitId = payload =>
  validateRequest(() =>
    axiosInstance.post(
      `genericField/PhytosanitaryState/getAllByUnitId`,
      payload,
    ),
  )
API.getLocationsByUnitId = payload =>
  validateRequest(() =>
    axiosInstance.post(`genericField/Location/getAllByUnitId`, payload),
  )
API.createTaskRequest = payload =>
  validateRequest(() => axiosInstance.post(`taskRequest`, payload))
API.editTaskRequest = payload =>
  validateRequest(() => axiosInstance.put(`taskRequest/updated`, payload))
API.getAllTaskPaginate = payload =>
  validateRequest(() =>
    axiosInstance.post(`taskRequest/getAllOrderTaskPaginate`, payload),
  )
API.deleteTaskRequest = taskId =>
  validateRequest(() => axiosInstance.delete(`taskRequest/${taskId}`))
API.getTaskRequestWithFines = () =>
  validateRequest(() => axiosInstance.get(`taskRequest/fines`))

export default API
