import React from 'react'
import { Icon, Drawer, Button } from '@blueprintjs/core'
import { Select } from 'antd'
import { format } from 'rut.js'
import moment from 'moment'

const Option = Select.Option

const drawerContainer = {
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  padding: `12px 20px`,
}

const dataContainer = {
  display: `flex`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  fontSize: 16,
  fontWeight: 600,
  color: `#042E48`,
  borderBottom: `1px solid #D8D8D8`,
  paddingBottom: 4,
  marginBottom: 8,
}

const assignContainer = {
  display: `flex`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  fontSize: 16,
  fontWeight: 600,
  color: `#042E48`,
  paddingBottom: 4,
  marginBottom: 8,
}

const reviewContainer = {
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  padding: `12px 20px`,
  marginTop: 10,
}

const buttonStyle = {
  marginTop: 6,
  boxShadow: `none`,
  backgroundImage: `none`,
  backgroundColor: `#81BB2A`,
  padding: `8px 20px`,
  color: `#ffffff`,
  fontSize: 18,
  borderRadius: 8,
}

const solicitudeTitle = {
  width: 200,
}

const TaskDrawer = ({
  data,
  onClose,
  handleChange,
  reviewSolicitude,
  assignSolicitude,
  workers = null,
  inspectors = null,
}) => {
  return (
    <Drawer
      icon="info-sign"
      onClose={() => onClose()}
      title="Detalle tarea:"
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      usePortal
      isOpen
    >
      <div style={drawerContainer}>
        <div style={dataContainer}>
          <span style={solicitudeTitle}>Fecha:</span>
          <div>
            <Icon
              icon="chevron-right"
              iconSize={18}
              style={{ color: `#042E48` }}
            />
            <span>{moment(data.createdAt).format(`LLLL`)}</span>
          </div>
        </div>
        <div style={dataContainer}>
          <span style={solicitudeTitle}>Creada por:</span>
          <div>
            <Icon
              icon="chevron-right"
              iconSize={18}
              style={{ color: `#042E48` }}
            />
            <span>
              {`${format(data.createdBy)} (${data.CreatedBy.firstName} ${
                data.CreatedBy.lastName
              })`}
            </span>
          </div>
        </div>
        <div style={dataContainer}>
          <span style={solicitudeTitle}>Servicio:</span>
          <div>
            <Icon
              icon="chevron-right"
              iconSize={18}
              style={{ color: `#042E48` }}
            />
            <span>{data.TaskType.description}</span>
          </div>
        </div>
        <div style={dataContainer}>
          <span style={solicitudeTitle}>ID Árbol:</span>
          <div>
            <Icon
              icon="chevron-right"
              iconSize={18}
              style={{ color: `#042E48` }}
            />
            <span>{data.Tree.id}</span>
          </div>
        </div>
        {data.AceptedBy && (
          <>
            <div style={dataContainer}>
              <span style={solicitudeTitle}>Fecha aprobación:</span>
              <div>
                <Icon
                  icon="chevron-right"
                  iconSize={18}
                  style={{ color: `#042E48` }}
                />
                <span>{moment(data.aceptedDate).format(`LLLL`)}</span>
              </div>
            </div>
            <div style={dataContainer}>
              <span style={solicitudeTitle}>Aceptada por:</span>
              <div>
                <Icon
                  icon="chevron-right"
                  iconSize={18}
                  style={{ color: `#042E48` }}
                />
                <span>{`${format(
                  `${data.AceptedBy.rut}${data.AceptedBy.dv}`,
                )} (${data.AceptedBy.firstName} ${
                  data.AceptedBy.lastName
                })`}</span>
              </div>
            </div>
          </>
        )}
        {data.FinishedBy && (
          <>
            <div style={dataContainer}>
              <span style={solicitudeTitle}>Fecha termino:</span>
              <div>
                <Icon
                  icon="chevron-right"
                  iconSize={18}
                  style={{ color: `#042E48` }}
                />
                <span>{moment(data.finishedDate).format(`LLLL`)}</span>
              </div>
            </div>
            <div style={dataContainer}>
              <span style={solicitudeTitle}>Terminada por:</span>
              <div>
                <Icon
                  icon="chevron-right"
                  iconSize={18}
                  style={{ color: `#042E48` }}
                />
                <span>{`${format(
                  `${data.FinishedBy.rut}${data.FinishedBy.dv}`,
                )} (${data.FinishedBy.firstName} ${
                  data.FinishedBy.lastName
                })`}</span>
              </div>
            </div>
          </>
        )}
        {data.DeletedBy && (
          <>
            <div style={dataContainer}>
              <span style={solicitudeTitle}>Fecha eliminación:</span>
              <div>
                <Icon
                  icon="chevron-right"
                  iconSize={18}
                  style={{ color: `#042E48` }}
                />
                <span>{moment(data.deletedDate).format(`LLLL`)}</span>
              </div>
            </div>
            <div style={dataContainer}>
              <span style={solicitudeTitle}>Eliminada por:</span>
              <div>
                <Icon
                  icon="chevron-right"
                  iconSize={18}
                  style={{ color: `#042E48` }}
                />
                <span>
                  {`${format(`${data.DeletedBy.rut}${data.DeletedBy.dv}`)} (${
                    data.DeletedBy.firstName
                  } ${data.DeletedBy.lastName})`}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      {data.taskStatusId === 1
        ? workers && (
            <div style={reviewContainer}>
              <div style={assignContainer}>
                <span style={solicitudeTitle}>Asignar a:</span>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Buscar Jefe de Cuadrilla"
                  optionFilterProp="children"
                  onChange={handleChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {workers.map((worker, i) => {
                    return (
                      <Option
                        key={i}
                        value={JSON.stringify(worker)}
                      >{`${worker.firstname} ${worker.lastname}`}</Option>
                    )
                  })}
                </Select>
              </div>
              <Button
                style={buttonStyle}
                intent="success"
                icon="tick"
                text="Enviar"
                onClick={assignSolicitude}
              />
            </div>
          )
        : inspectors && (
            <div style={reviewContainer}>
              <div style={assignContainer}>
                <span style={solicitudeTitle}>Asignar a:</span>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Buscar Inspector"
                  optionFilterProp="children"
                  onChange={handleChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {inspectors.map((worker, i) => {
                    return (
                      <Option
                        key={i}
                        value={JSON.stringify(worker)}
                      >{`${worker.firstname} ${worker.lastname}`}</Option>
                    )
                  })}
                </Select>
              </div>
              <Button
                style={buttonStyle}
                intent="success"
                icon="tick"
                text="Enviar"
                onClick={reviewSolicitude}
              />
            </div>
          )}
    </Drawer>
  )
}

export default TaskDrawer
