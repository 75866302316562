const statusPaidOTEnum = {
  PENDING: `Pendiente por pagar`,
  PAID: `Pagada`,
}

const treeAgesEnum = [
  { id: `Joven`, description: `Joven` },
  { id: `Intermedia`, description: `Intermedia` },
  { id: `Adulto`, description: `Adulto` },
  { id: `Senescente`, description: `Senescente` },
]

const generalStateEnum = [
  { id: `Bueno`, description: `Bueno` },
  { id: `Regular o medio`, description: `Regular o medio` },
  { id: `Malo o pobre`, description: `Malo o pobre` },
  { id: `Muerto`, description: `Muerto` },
]

const orderTaskPlantationEnum = 19

export {
  statusPaidOTEnum,
  treeAgesEnum,
  generalStateEnum,
  orderTaskPlantationEnum,
}
