import React from 'react'
import { Timer, Container } from '../../components'
import SideBar from './sideBar'
import Header from './header'
// import { Layout, Content, Container } from './styles'

export default ({ children }) => (
  <Container width="100%">
    <SideBar />
    <Container overflowY="auto" maxHeight="100vh" flex="1">
      <Header />
      <Container flex="1" width="100%" p="1em 2em">
        {children}
      </Container>
    </Container>
  </Container>
)
