/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable babel/no-unused-expressions */
export const taskStates = {
  1: { status: `Pendiente` },
  2: { status: `Recomendada` },
  3: { status: `Terminada` },
  4: { status: `Eliminada` },
  5: { status: `Revisión` },
}

export const roles = {
  0: { role: `Administrador` },
  1: { role: `Trabajador` },
  2: { role: `Inspector` },
  3: { role: `Catastrador` },
}

export const capitalizeFirstLetter = word => {
  return word.replace(/\w/, l => l.toUpperCase())
}

export const generateHeaders = tree => {
  const headers = [
    { label: `ID`, key: `id` },
    { label: `Creado Por`, key: `User.fullrut` },
    { label: `Fecha Creacion`, key: `createdAt` },
    { label: `Especie`, key: `Specie.description` },
    { label: `Nombre Cientifico`, key: `Specie.scientificName` },
    { label: `Calle`, key: `streetName` },
    { label: `Numero`, key: `streetNumber` },
  ]
  if (typeof tree.accurateDap !== undefined) {
    headers.push({ label: `DAP preciso`, key: `accurateDap` })
  }
  if (tree.height) {
    headers.push({ label: `Altura`, key: `height` })
  }
  if (tree.topDiameter) {
    headers.push({ label: `Diámetro de copa`, key: `topDiameter` })
  }
  if (tree.topRegress) {
    headers.push({ label: `Regresión de copa`, key: `topRegress` })
  }
  if (tree.firstBranchHeight) {
    headers.push({
      label: `Altura ramas principales`,
      key: `firstBranchHeight`,
    })
  }
  if (tree.branchNumber) {
    headers.push({ label: `Número de ramas principales`, key: `branchNumber` })
  }
  if (tree.topDiameterX) {
    headers.push({ label: `Diametro Copa X`, key: `topDiameterX` })
  }
  if (tree.topDiameterY) {
    headers.push({ label: `Diametro Copa Y`, key: `topDiameterY` })
  }
  if (tree.accurateDat) {
    headers.push({ label: `DAT preciso`, key: `accurateDat` })
  }
  if (tree.trunkForm) {
    headers.push({ label: `Forma de tronco`, key: `trunkForm` })
  }
  if (tree.barrack) {
    headers.push({ label: `Cuartel`, key: `barrack` })
  }
  if (tree.rowTrees) {
    headers.push({ label: `Arboles en la hilera`, key: `rowTrees` })
  }
  if (tree.newTrees) {
    headers.push({ label: `Arboles nuevos`, key: `newTrees` })
  }
  if (tree.liveTrees) {
    headers.push({ label: `Arboles vivos`, key: `liveTrees` })
  }
  if (tree.deadTrees) {
    headers.push({ label: `Arboles muertos`, key: `deadTrees` })
  }
  if (tree.totalTrees) {
    headers.push({ label: `Total Arboles`, key: `totalTrees` })
  }
  if (tree.plantingDate) {
    headers.push({ label: `Fecha de Plantación`, key: `plantingDate` })
  }
  if (tree.transferDate) {
    headers.push({ label: `Fecha de Traslado`, key: `transferDate` })
  }
  if (tree.overheadCables || tree.overheadCables === false) {
    headers.push({ label: `Cables aéreos`, key: `overheadCables` })
  }
  if (tree.roadSignsConflict || tree.roadSignsConflict === false) {
    headers.push({ label: `Conflicto señalización`, key: `roadSignsConflict` })
  }
  if (tree.luminairePresence || tree.luminairePresence === false) {
    headers.push({ label: `Presencia luminaria`, key: `luminairePresence` })
  }
  if (tree.luminaireDistance || tree.luminaireDistance === false) {
    headers.push({
      label: `Distancia luminaria lineal menor o igual a 3 metros`,
      key: `luminaireDistance`,
    })
  }
  if (tree.hasPlague || tree.hasPlague === false) {
    headers.push({ label: `Presencia de plagas`, key: `hasPlague` })
  }
  if (tree.observations !== undefined) {
    headers.push({ label: `Comentario`, key: `observations` })
  }
  if (tree.lonelyTree || tree.lonelyTree === false) {
    headers.push({
      label: `Árbol solitario`,
      key: `lonelyTree`,
    })
  }
  if (tree.hasRate || tree.hasRate === false) {
    headers.push({
      label: `Posee taza`,
      key: `hasRate`,
    })
  }
  if (tree.mechanicalShankDamage || tree.mechanicalShankDamage === false) {
    headers.push({
      label: `Daño mecánico en fuste`,
      key: `mechanicalShankDamage`,
    })
  }
  if (tree.SubUnit) {
    headers.push({
      label: `Unidad Vecinal`,
      key: `SubUnit.description`,
    })
  }
  if (tree.TreeType) {
    headers.push({
      label: `Tipo`,
      key: `TreeType.description`,
    })
  }
  if (tree.Location) {
    headers.push({
      label: `Ubicacion`,
      key: `Location.description`,
    })
  }
  if (tree.MaterialPlatabanda) {
    headers.push({
      label: `Material platabanda`,
      key: `MaterialPlatabanda.description`,
    })
  }
  if (tree.RelativeAge) {
    headers.push({
      label: `Fase de desarrollo`,
      key: `RelativeAge.description`,
    })
  }
  if (tree.Dap) {
    headers.push({ label: `DAP`, key: `Dap.description` })
  }
  if (tree.StructuralState) {
    headers.push({
      label: `Estado Estructural de copas`,
      key: `StructuralState.description`,
    })
  }
  if (tree.Oriented) {
    headers.push({
      label: `Orientación inclinación`,
      key: `Oriented.description`,
    })
  }
  if (tree.OrientedPark) {
    headers.push({
      label: `Orientación en Parque`,
      key: `OrientedPark.description`,
    })
  }
  if (tree.Architecture) {
    headers.push({
      label: `Arquitectura`,
      key: `Architecture.description`,
    })
  }
  if (tree.PoorArchitecture) {
    headers.push({
      label: `Arquitectura pobre`,
      key: `PoorArchitecture.description`,
    })
  }
  if (tree.Shape) {
    headers.push({ label: `Forma`, key: `Shape.description` })
  }
  if (tree.BaseDamage) {
    headers.push({
      label: `Daño en base del tronco`,
      key: `BaseDamage.description`,
    })
  }
  if (tree.TrunkDamage) {
    headers.push({
      label: `Daño en tronco`,
      key: `TrunkDamage.description`,
    })
  }
  if (tree.InsertionDamage) {
    headers.push({
      label: `Daño en inserción de ramas`,
      key: `InsertionDamage.description`,
    })
  }
  if (tree.BranchDamage) {
    headers.push({
      label: `Daño en ramas principales superiores a 10cm`,
      key: `BranchDamage.description`,
    })
  }
  if (tree.TopDamage) {
    headers.push({
      label: `Daño en copa`,
      key: `TopDamage.description`,
    })
  }
  if (tree.FirstPathogen) {
    headers.push({
      label: `Agente patógeno 1`,
      key: `FirstPathogen.description`,
    })
  }
  if (tree.FirstPathogenLocation) {
    headers.push({
      label: `Ubicación del agente patógeno 1`,
      key: `FirstPathogenLocation.description`,
    })
  }
  if (tree.FirstPathogenIntensity) {
    headers.push({
      label: `Intensidad del agente patógeno 1`,
      key: `FirstPathogenIntensity.description`,
    })
  }
  if (tree.SecondPathogen) {
    headers.push({
      label: `Agente patógeno 2`,
      key: `SecondPathogen.description`,
    })
  }
  if (tree.SecondPathogenLocation) {
    headers.push({
      label: `Ubicación del agente patógeno 2`,
      key: `SecondPathogenLocation.description`,
    })
  }
  if (tree.SecondPathogenIntensity) {
    headers.push({
      label: `Intensidad del agente patógeno 2`,
      key: `SecondPathogenIntensity.description`,
    })
  }
  if (tree.Recommendation) {
    headers.push({
      label: `Recomendación`,
      key: `Recommendation.description`,
    })
  }
  if (tree.PhytosanitaryState) {
    headers.push({
      label: `Estado fitosanitario`,
      key: `PhytosanitaryState.description`,
    })
  }
  if (tree.TreeState) {
    headers.push({
      label: `Estado general`,
      key: `TreeState.description`,
    })
  }
  if (tree.FoliageDensity) {
    headers.push({
      label: `Estado general`,
      key: `FoliageDensity.description`,
    })
  }
  if (tree.Interference) {
    headers.push({
      label: `Estado general`,
      key: `Interference.description`,
    })
  }
  if (tree.LandscapePosition) {
    headers.push({
      label: `Estado general`,
      key: `LandscapePosition.description`,
    })
  }
  
  return headers
}

export const isEmptyObj = obj => Object.entries(obj).length === 0

export const currencyFormat = num => {
  if (num && parseFloat(num)) {
    return `$${parseFloat(num)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`)}`
  } else {
    return ``
  }
}
