/* eslint-disable complexity */
import React, { useState } from 'react'
import moment from 'moment'
import confirm from 'antd/es/modal/confirm'
import { Modal, Container, Typography, Input, Button } from '../../components'
import { notification } from '../../utils'
import API from '../../config/API'

function ViewRequest({ onCancel }) {
  const [message, setMessage] = useState({
    title: null,
    message: null,
  })
  function handleChange(name, value) {
    setMessage(prevState => ({ ...prevState, [name]: value }))
  }
  async function onConfirm() {
    try {
      await API.massiveNotification(message)
      notification({
        type: `success`,
        message: `Notificaciones enviadas`,
      })
      onCancel()
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Hubo un problema con el servidor`
      notification({
        type: `error`,
        message: error,
      })
    }
  }
  return (
    <Modal
      onAccept={() =>
        confirm({
          title: `Acción masiva`,
          content: `¿Está seguro que desea realizar esta acción?. Recuerda que es una acción masiva y no se puede deshacer.`,
          okText: `Sí.`,
          cancelText: `No.`,
          onOk: onConfirm,
        })
      }
      onCancel={onCancel}
      visible={true}
      title="Mensaje masivo a vecinos"
      showCancel={false}
    >
      <Container overflowX="hidden" width="100%" flexDirection="column">
        <Typography type="subtitle">Cuerpo del mensaje</Typography>
        <Input
          onChange={handleChange}
          label="Titulo"
          width="100%"
          value={message.title}
          name="title"
        />
        <Input
          onChange={handleChange}
          label="Mensaje"
          width="100%"
          value={message.message}
          name="message"
        />
      </Container>
    </Modal>
  )
}

export default ViewRequest
