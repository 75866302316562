import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getTasksBySpecies, getTasksByType, getTasksByOrigin } from '../redux/actions'
import { Card, Container, Input, Typography, Button, Loader } from './'

const initialFilters = {
  taskType: ``,
  specie: ``,
  subUnit: ``,
  startDate: ``,
  endDate: ``,
  origin: ``,
  treeType: ``,
  interactionsWithUrbanElement: ``,
  interference: ``,
  treeState: ``,
}

function TaskReportFilter({
  taskReportFilters,
  getTasksBySpecies,
  loadingBySpecies,
  getTasksByType,
  loadingByType,
  getTasksByOrigin,
  loadingByOrigin,
  adminUnitId
}) {
  const [filters, setFilters] = useState(initialFilters)

  const handleInput = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const getTasks = () => {
    filters['adminUnitId'] = adminUnitId
    
    getTasksBySpecies(filters)
    getTasksByType(filters)
    getTasksByOrigin(filters)
  }

  if (loadingBySpecies || loadingByType || loadingByOrigin) {
    return <Loader />
  }

  if (!taskReportFilters) {
    return null
  }
  return (
    <Card
      width="100%"
      borderColor="gray"
      borderTop="4px solid"
      borderRadius={4}
    >
      <Container
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Typography type="label" fontWeight="bold" fontStyle="italic">
          Nueva búsqueda:
        </Typography>
        <Container>
          <Button
            onClick={getTasks}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
          />
          <Button
            onClick={() => setFilters(initialFilters)}
            text="Limpiar Búsqueda"
            color="primary.3"
            backgroundColor="transparent"
          />
        </Container>
      </Container>
      <Container justifyContent="space-between" marginTop="32px">
      <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Especie:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="specieId"
          keyValue="description"
          options={taskReportFilters.species}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.specieId === `` ? undefined : filters.specieId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Origen Especie:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="geographyOriginId"
          keyValue="id"
          options={taskReportFilters.origins}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.geographyOriginId === `` ? undefined : filters.geographyOriginId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Sub Unidad:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="subUnitId"
          keyValue="description"
          options={taskReportFilters.subUnits}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.subUnitId === `` ? undefined : filters.subUnitId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado General:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="treeStateId"
          keyValue="description"
          options={taskReportFilters.treeStates}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.treeStateId === `` ? undefined : filters.treeStateId}
        />
        <Input
          width="24%"
          type="select"
          optionFilterProp="children"
          label="Cables Aereos:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="overheadCables"
          keyValue="description"
          options={[{'id':0, 'description':'No'},{'id':1, 'description':'Si'}]}
        />
        <Input
          width="24%"
          type="select"
          optionFilterProp="children"
          label="Conflicto Señalización:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="roadSignsConflict"
          keyValue="description"
          options={[{'id':0, 'description':'No'},{'id':1, 'description':'Si'}]}
        />
        <Input
          width="24%"
          type="select"
          optionFilterProp="children"
          label="Presencia Luminaria:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="luminairePresence"
          keyValue="description"
          options={[{'id':0, 'description':'No'},{'id':1, 'description':'Si'}]}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Interferencia:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="interferenceId"
          keyValue="description"
          options={taskReportFilters.interferences}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.interferenceId === `` ? undefined : filters.interferenceId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Interacción con elementos Urbanos:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="interactionsWithUrbanElementId"
          keyValue="description"
          options={taskReportFilters.interactionsWithUrbanElements}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.interactionsWithUrbanElementsId === `` ? undefined : filters.interactionsWithUrbanElementsId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="treeTypeId"
          keyValue="description"
          options={taskReportFilters.treeTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.treeTypeId === `` ? undefined : filters.treeTypeId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo de tarea:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="taskTypeId"
          keyValue="description"
          options={taskReportFilters.taskTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.taskTypeId === `` ? undefined : filters.taskTypeId}
        />
      </Container>
    </Card>
  )
}

const mapStateToProps = ({
  tasks: { taskReportFilters, loadingBySpecies, loadingByType, loadingByOrigin },
  auth: { user },
}) => ({
  taskReportFilters,
  user,
  loadingBySpecies,
  loadingByType,
  loadingByOrigin,
})

const mapDispatchToProps = {
  getTasksBySpecies,
  getTasksByType,
  getTasksByOrigin,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskReportFilter)
