import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Container, Input, Typography, Button } from '../../components'
import API from '../../config/API'
import { notification } from '../../utils'

const initialState = user => ({
  createType: 1, //Creacion por direccion
  streetName: null,
  treeId: null,
  taskTypeId: null,
  createdById: user.id,
  taskStatusId: 1,
  taskPriorityId: null,
  assignedToId: null,
  assignedAt: moment(),
  observation: null,
  loadingSearch: false,
  loadingCreation: false,
  trees: [],
  filteredTrees: [],
  OT: null,
  species: [{ id: 0, description: `Todos` }],
  subUnits: [{ id: 0, description: `Todos` }],
  daps: [{ id: 0, description: `Todos` }],
  relativeAges: [{ id: 0, description: `Todos` }],
  specieId: 0,
  subUnitId: 0,
  dapId: 0,
  relativeAgeId: 0,
})

function CreateTask({ onCancel, taskTypes, user, admins, taskPriorities }) {
  const [state, setState] = useState(initialState(user))
  function onChange(name, value) {
    setState(prevState => ({ ...prevState, [name]: value }))
  }
  const { specieId, subUnitId, dapId, relativeAgeId, trees } = state
  useEffect(() => {
    // Filtrar arboles
    if (trees && trees.rows) {
      const filteredTrees = trees.rows.filter(tree => {
        if (
          (tree.dapId === dapId || dapId === 0) &&
          (tree.specieId === specieId || specieId === 0) &&
          (tree.subUnitId === subUnitId || subUnitId === 0) &&
          (tree.relativeAgeId === relativeAgeId || relativeAgeId === 0)
        ) {
          return true
        }
        return false
      })
      setState(prevState => ({
        ...prevState,
        filteredTrees: {
          rows: filteredTrees,
          count: filteredTrees.length,
        },
      }))
    }
  }, [specieId, subUnitId, dapId, relativeAgeId, trees])
  async function findTreesByAddress() {
    try {
      setState(prevState => ({ ...prevState, loadingSearch: true }))
      const { trees, options } = await API.findByStreetName({
        streetName: state.streetName,
        unitId: user.unitId,
      })
      setState(prevState => ({
        ...prevState,
        loadingSearch: false,
        trees,
        filteredTrees: trees,
        ...options,
      }))
    } catch (error) {
      //handle error
      setState(prevState => ({ ...prevState, loadingSearch: false }))
    }
  }
  async function onAccept() {
    const {
      streetName,
      createType,
      treeId,
      taskTypeId,
      createdById,
      taskStatusId,
      assignedToId,
      taskPriorityId,
      observation,
      assignedAt,
      OT,
      filteredTrees,
      specieId,
      subUnitId,
      dapId,
      relativeAgeId,
    } = state
    if (
      (createType === 1 && !filteredTrees.count) ||
      (createType === 2 && !treeId)
    ) {
      return notification({
        type: `error`,
        message: `No hay arboles asociados para crear la tarea`,
      })
    }
    setState(prevState => ({ ...prevState, loadingCreation: true }))
    try {
      const task = {
        treeId,
        taskTypeId,
        taskPriorityId,
        createdById,
        taskStatusId,
        assignedToId,
        observation,
        assignedAt,
        OT,
      }
      const request =
        createType === 1 ? API.createTaskByAddress : API.craeteTask
      await request({
        task,
        streetName,
        specieId,
        subUnitId,
        dapId,
        relativeAgeId,
        unitId: user.unitId,
      })
      notification({
        type: `success`,
        message: `Tarea(s) creada(s) correctamente`,
      })
      return onCancel()
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un error, reinteinte.`
      return notification({
        type: `error`,
        message: error,
      })
    }
  }
  const addressType = state.createType === 1
  return (
    <Modal
      onAccept={onAccept}
      onCancel={onCancel}
      visible={true}
      title="Crear tareas"
      showCancel={false}
      confirmLoading={state.loadingCreation}
    >
      <Container
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Container alignItems="baseline">
          <Typography marginRight="2" fontWeight="bold">
            Tipo de creación:
          </Typography>
          <Input
            type="radio"
            name="createType"
            value={state.createType}
            onChange={onChange}
            options={[
              { label: `Calle`, value: 1 },
              { label: `ID árbol`, value: 2 },
            ]}
          />
        </Container>
        <Container alignItems="baseline">
          <Typography marginRight="2" fontWeight="bold">
            {addressType ? `Nombre de calle` : `ID árbol`}:
          </Typography>
          <Input
            name={addressType ? `streetName` : `treeId`}
            onChange={onChange}
            value={addressType ? state.streetName : state.treeId}
          />
          {addressType && <Button text="Buscar" onClick={findTreesByAddress} />}
        </Container>
      </Container>
      {state.trees && state.trees.rows && state.trees.rows.length > 0 ? (
        <Container width="100%" alignItems="baseline">
          <Typography marginRight="2" fontWeight="bold">
            Filtros:
          </Typography>
          <Input
            type="select"
            label="DAP:"
            size="small"
            width="20%"
            options={state.daps}
            onChange={onChange}
            value={state.dapId}
            key="value"
            keyValue="description"
            optionFilterProp="children"
            showSearch
            name="dapId"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
          <Input
            type="select"
            label="Unidad vecinal:"
            size="small"
            width="20%"
            options={state.subUnits}
            onChange={onChange}
            value={state.subUnitId}
            key="value"
            keyValue="description"
            optionFilterProp="children"
            showSearch
            name="subUnitId"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
          <Input
            type="select"
            label="Especie:"
            size="small"
            width="20%"
            options={state.species}
            onChange={onChange}
            value={state.specieId}
            key="value"
            keyValue="description"
            optionFilterProp="children"
            showSearch
            name="specieId"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
          <Input
            type="select"
            label="Fase de desarrollo:"
            size="small"
            width="20%"
            options={state.relativeAges}
            onChange={onChange}
            value={state.relativeAgeId}
            key="value"
            keyValue="description"
            optionFilterProp="children"
            showSearch
            name="relativeAgeId"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
        </Container>
      ) : null}
      <Container flexDirection="column" width="50%">
        <Typography fontSize="6" type="subtitle">
          Detalle de la tarea
        </Typography>
        <Input
          label="Tipo de tarea:"
          width="100%"
          type="select"
          onChange={onChange}
          optionFilterProp="children"
          showSearch
          name="taskTypeId"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={state.taskTypeId}
          options={taskTypes}
          keyValue="description"
        />
        <Input
          label="Prioridad de tarea:"
          width="100%"
          type="select"
          onChange={onChange}
          name="taskPriorityId"
          value={state.taskPriorityId}
          options={taskPriorities}
          keyValue="description"
        />
        <Input
          label="Asignar a:"
          width="100%"
          type="select"
          onChange={onChange}
          name="assignedToId"
          value={state.assignedToId}
          options={admins}
          keyValue="description"
        />
        <Input
          label="Observación:"
          width="100%"
          onChange={onChange}
          name="observation"
          value={state.observation}
        />
        <Input
          label="OT:"
          width="100%"
          onChange={onChange}
          name="OT"
          value={state.OT}
        />
      </Container>
      {addressType && (
        <ShowTrees loading={state.loadingSearch} trees={state.filteredTrees} />
      )}
    </Modal>
  )
}

function ShowTrees({ trees, loading }) {
  if (loading) {
    return <Typography>Buscando...</Typography>
  }
  return (
    <Container
      width="50%"
      maxHeight="30em"
      overflowY="auto"
      flexDirection="column"
      padding="2"
      flexWrap="no-wrap"
    >
      <Typography type="subtitle" fontSize="6">
        Total árboles: {trees.count}
      </Typography>
      <Container
        overflowY="auto"
        flexDirection="column"
        width="100%"
        flexWrap="no-wrap"
      >
        {trees.rows &&
          trees.rows.map(tree => (
            <Container
              key={tree.id}
              width="100%"
              borderColor="gray.1"
              borderBottom="0.5px solid"
              padding="1"
              justifyContent="space-between"
            >
              <Typography>{tree.specie.description}</Typography>
              <Typography>
                {tree.streetName}, {tree.streetNumber}
              </Typography>
            </Container>
          ))}
      </Container>
    </Container>
  )
}

export default CreateTask
