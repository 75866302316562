import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  getBilligTaskRequestFilters,
  resetBilligTaskRequestFilters,
} from '../../../redux/actions'
import {
  Typography,
  Loader,
  Container,
  Card,
  Table,
  Input,
  Button,
  HeaderInfo,
  DownloadButton,
} from '../../../components'
import { notification } from '../../../utils/notification'

import { currencyFormat } from '../../../utils'
import API from '../../../config/API'

const columns = [
  { title: `ID`, dataIndex: `id` },
  {
    title: `Dirección`,
    dataIndex: `address`,
  },
  {
    title: `Especie`,
    dataIndex: `tree.description`,
  },
  {
    title: `Cant`,
    dataIndex: `qtyTree`,
  },
  {
    title: `Trabajo a realizar`,
    dataIndex: `orderTask.description`,
  },
  {
    title: `DAP`,
    dataIndex: `treeDap`,
  },
  {
    title: `Fecha`,
    dataIndex: `createdAt`,
  },
  {
    title: `Valor unitario`,
    dataIndex: `unitPrice`,
  },
  {
    title: `Valor neto`,
    dataIndex: `price`,
  },
  {
    title: `Subtotal (IVA incl.)`,
    dataIndex: `subtotal`,
  },
]

function OrdenTaskBilling({
  getBilligTaskRequestFilters,
  resetBilligTaskRequestFilters,
  orderTaskBilling,
  loading,
  valueTotal,
  user,
}) {
  const [state, setState] = useState({
    visiblePerOT: true,
    visiblePerUser: false,
    filters: {
      createdIn: ``,
    },
  })

  const { filters } = state

  if (loading) {
    return <Loader />
  }

  const handleResetFilters = () => {
    setState(prevState => ({
      ...prevState,
      filters: { createdIn: ``, orderBy: `DESC` },
    }))
  }

  const handleInput = (field, value) => {
    setState(prevState => {
      const { filters } = prevState
      const newFilters = { ...filters, [field]: value }
      return { ...prevState, filters: newFilters }
    })
  }

  const handleResetData = () => {
    resetBilligTaskRequestFilters()
    handleResetFilters()
  }

  const handleGetBillingTaskRequest = () => {
    if (!filters.createdIn.length) return false
    return getBilligTaskRequestFilters(filters)
  }

  const generateCsv = async () => {
    if (!filters.createdIn.length) return false
    try {
      const result = await API.generateCsvBilligTaskRequest(filters)
      return notification({
        type: `success`,
        message: result,
      })
    } catch (error) {
      return notification({
        type: `error`,
        message: `Error al generar archivo`,
      })
    }
  }

  return (
    <>
      <Container width="100%">
        <Container
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Container width="20%">
            <Typography type="title">Facturación</Typography>
          </Container>
          <Container
            width="60%"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Container
              display="flex"
              marginBottom="12px"
              alignItems="flex-start"
            >
              <div>
              <DownloadButton
                href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/OT_B${user.id}.csv`}
              >
                Descargar último CSV
              </DownloadButton>
              </div>
              <div style={({marginLeft:'15px'})}>
              <DownloadButton
                href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/OT_B${user.id}.xlsx`}
              >
                Descargar último Excel
              </DownloadButton>
              </div>
            </Container>
            <Button
              onClick={generateCsv}
              text="Generar Archivos"
              color="primary.3"
              backgroundColor="transparent"
              marginRight="10px"
            />
            <Input
              width="25%"
              type="dateRange"
              format="DD-MM-YYYY"
              label="Definir periodo"
              onChange={handleInput}
              name="createdIn"
              value={filters.createdIn === `` ? undefined : filters.createdIn}
            />
            <Container marginBottom="5px">
              <Button
                onClick={handleGetBillingTaskRequest}
                text="Buscar"
                boxShadow={true}
                backgroundColor="gray.2"
              />
              <Button
                onClick={handleResetData}
                text="Limpiar Búsqueda"
                color="primary.3"
                backgroundColor="transparent"
              />
            </Container>
          </Container>
        </Container>
        <Card
          width="100%"
          borderColor="primary.3"
          display="flex"
          marginTop="16px"
          paddingTop="0px"
          style={{ flexWrap: `wrap` }}
        >
          {orderTaskBilling.length > 0 && (
            <Container
              width="100%"
              marginTop="8px"
              justifyContent="space-around"
            >
              <HeaderInfo
                list={[
                  { title: orderTaskBilling.length, subtitle: `CANT OT` },
                  {
                    title: valueTotal,
                    subtitle: `TOTAL VALOR NETO DEL PERIODO`,
                  },
                ]}
              />
            </Container>
          )}
          <Container width="100%" marginTop="8px">
            <Table
              marginTop="16px"
              columns={columns}
              dataSource={orderTaskBilling}
              loading={loading}
              locale={{
                emptyText: `No existen orden de trabajo para el rango seleccionado`,
              }}
            />
          </Container>
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = ({
  orderTask: { orderTaskBilling, loading },
  auth: { user },
}) => {
  let valueTotal = 0
  const mapOrderTaskBilling = orderTaskBilling.map(task => {
    const {
      changeStatuses,
      price,
      orderTask: { unitPrice },
    } = task
    valueTotal += parseFloat(price)
    const subtotal = parseFloat(price) * 1.19
    return {
      ...task,
      price: currencyFormat(price),
      unitPrice: currencyFormat(unitPrice),
      createdAt: moment.utc(changeStatuses[0].createdAt).format(`DD/MM/YYYY`),
      subtotal: currencyFormat(subtotal),
    }
  })

  return {
    user,
    orderTaskBilling: mapOrderTaskBilling,
    loading,
    valueTotal: currencyFormat(valueTotal),
  }
}

const mapDispatchToProps = {
  getBilligTaskRequestFilters,
  resetBilligTaskRequestFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdenTaskBilling)
