import React from 'react'
import styled from 'styled-components'
import { compose, color, space, typography } from 'styled-system'

const LinkStyle = styled.a`
  ${compose(
    color,
    space,
    typography,
  )}
  ${({ isUnderline }) => isUnderline && `text-decoration: underline;`}
`
LinkStyle.defaultProps = {
  color: `primary.2`,
  fontWeight: 500,
  fontSize: `1`,
  isUnderline: false,
}

const Link = ({ onClick, children, isUnderline }) => {
  return (
    <LinkStyle onClick={() => onClick()} isUnderline={isUnderline}>
      {children}
    </LinkStyle>
  )
}
export default Link
