import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getRequests } from '../redux/actions'
import { notification } from '../utils/notification'
import API from '../config/API'
import { useListenerEnter } from '../hooks'
import { Card, Container, Input, Typography, Button, DownloadButton } from './'

function RequestFilter({
  adminUnitId,
  requestFilters,
  getRequests,
  user,
  pagination,
  filters,
  setFilters,
}) {
  useListenerEnter({ enterFn: onSearch, dependencies: [filters] })
  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      setFilters({
        ...filters,
        unitId,
      })
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      setFilters({
        ...filters,
        unitId: adminUnitId,
      })
    }
  }, [adminUnitId])

  const handleInput = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const generateCsv = async () => {
    try {
      const result = await API.downloadRequestCsv(filters)
      notification({
        type: `success`,
        message: result,
      })
    } catch (error) {
      notification({ type: `error`, message: `Error al generar archivo` })
    }
  }
  function onSearch() {
    getRequests({ ...filters, pagination })
  }

  if (!requestFilters) {
    return null
  }
  return (
    <Card
      width="100%"
      borderColor="gray"
      borderTop="4px solid"
      borderRadius={4}
    >
      <Container
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Typography type="label" fontWeight="bold" fontStyle="italic">
          Nueva búsqueda:
        </Typography>
        <Container alignItems="center">
          <div>
          <DownloadButton
            href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/solicitudes_${user.id}.csv`}
          >
            Descargar último CSV
          </DownloadButton>
          </div>
          <div style={({marginLeft:'15px'})}>
          <DownloadButton
            href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/solicitudes_${user.id}.xlsx`}
          >
            Descargar último Excel
          </DownloadButton>
          </div>
          <Button
            onClick={generateCsv}
            text="Generar Archivos"
            color="primary.3"
            backgroundColor="transparent"
            marginRight="10px"
          />
          <Button
            onClick={onSearch}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
          />
          <Button
            onClick={() => setFilters({ unitId: filters.unitId })}
            text="Limpiar Búsqueda"
            color="primary.3"
            backgroundColor="transparent"
          />
        </Container>
      </Container>
      <Container marginTop="32px">
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo de solicitud:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="requestTypeId"
          keyValue="description"
          options={requestFilters.requestTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={
            filters.requestTypeId === `` ? undefined : filters.requestTypeId
          }
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="requestStatusId"
          keyValue="description"
          options={requestFilters.requestStatuses}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={
            filters.requestStatusId === `` ? undefined : filters.requestStatusId
          }
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Asignada a:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="assignedInspectorId"
          keyValue="description"
          options={requestFilters.workers}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={
            filters.assignedInspectorId === ``
              ? undefined
              : filters.assignedInspectorId
          }
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Rechazada por:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="rejectedById"
          keyValue="description"
          options={requestFilters.users}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.rejectedById === `` ? undefined : filters.rejectedById}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Unidad vecinal:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="subUnitId"
          keyValue="description"
          options={requestFilters.subUnits}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.subUnitId === `` ? undefined : filters.subUnitId}
        />
        <Input
          width="24%"
          label="Id árbol:"
          placeholder="Escriba el id del árbol"
          onChange={handleInput}
          name="treeId"
          value={filters.treeId === `` ? undefined : filters.treeId}
        />
        <Input
          width="24%"
          label="Id solicitud:"
          placeholder="Escriba el id de la solicitud"
          onChange={handleInput}
          name="id"
          value={filters.id === `` ? undefined : filters.id}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Ubicación:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="locationId"
          keyValue="description"
          options={requestFilters.locations}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.locationId === `` ? undefined : filters.locationId}
        />
        {requestFilters.barrackSelect.length > 0 ? (
          <Input
            width="24%"
            showSearch
            type="select"
            optionFilterProp="children"
            label="Cuartel:"
            placeholder="Elija una opción"
            onChange={handleInput}
            name="barrackSelectId"
            keyValue="description"
            options={requestFilters.barrackSelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={
              filters.barrackSelectId === ``
                ? undefined
                : filters.barrackSelectId
            }
          />
        ) : null}
      </Container>
    </Card>
  )
}

const mapStateToProps = ({
  requests: { requestFilters },
  auth: { user },
  general: { adminUnitId },
}) => ({
  requestFilters,
  user,
  adminUnitId,
})

const mapDispatchToProps = {
  getRequests,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestFilter)
