import _ from 'lodash'
import {
  LOADING_TASKS,
  ERROR_TASKS,
  GET_TASKS,
  GET_TASK_FILTERS,
  SUCCESS_ASSIGN_TASK,
  GET_TASK_REPORT_FILTERS,
  LOADING_TASKS_BY_SPECIES,
  ERROR_TASKS_BY_SPECIES,
  GET_TASKS_BY_SPECIES,
  LOADING_TASKS_BY_TYPE,
  ERROR_TASKS_BY_TYPE,
  GET_TASKS_BY_TYPE,
  REVIEW_TASK,
  CLOSE_TASK,
  DELETE_TASK,
} from '../actions/types'

const initialState = {
  loading: false,
  loadingBySpecies: false,
  loadingByType: false,
  taskFilters: null,
  taskReportFilters: null,
  assignTasks: [],
  tasksById: {},
  tasks: [],
  count: 0,
  tasksBySpecies: [],
  tasksByType: [],
  amounts: null,
}

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK_FILTERS:
      return {
        ...state,
        taskFilters: action.taskFilters,
        amounts: action.amounts,
      }
    case GET_TASK_REPORT_FILTERS:
      return {
        ...state,
        taskReportFilters: action.taskReportFilters,
      }
    case LOADING_TASKS:
      return {
        ...state,
        loading: true,
        tasks: [],
      }
    case ERROR_TASKS:
      return {
        ...state,
        loading: false,
      }
    case GET_TASKS: {
      const { tasks, tasksById, count } = action
      return {
        ...state,
        loading: false,
        tasks,
        tasksById,
        count,
      }
    }
    case SUCCESS_ASSIGN_TASK: {
      const { tasks, tasksById } = state
      const cloneTasksById = _.cloneDeep(tasksById)
      delete cloneTasksById[action.taskId]
      return {
        ...state,
        tasks: tasks.filter(item => item !== action.taskId),
        tasksById: cloneTasksById,
      }
    }
    case REVIEW_TASK: {
      const { tasks, tasksById } = state
      const cloneTasksById = _.cloneDeep(tasksById)
      delete cloneTasksById[action.taskId]
      return {
        ...state,
        tasks: tasks.filter(item => item !== action.taskId),
        tasksById: cloneTasksById,
      }
    }
    case CLOSE_TASK: {
      const { tasks, tasksById } = state
      const cloneTasksById = _.cloneDeep(tasksById)
      delete cloneTasksById[action.taskId]
      return {
        ...state,
        tasks: tasks.filter(item => item !== action.taskId),
        tasksById: cloneTasksById,
      }
    }
    case DELETE_TASK: {
      const { tasks, tasksById } = state
      const cloneTasksById = _.cloneDeep(tasksById)
      delete cloneTasksById[action.taskId]
      return {
        ...state,
        tasks: tasks.filter(item => item !== action.taskId),
        tasksById: cloneTasksById,
      }
    }
    case LOADING_TASKS_BY_SPECIES:
      return {
        ...state,
        loadingBySpecies: true,
        tasksBySpecies: [],
      }
    case ERROR_TASKS_BY_SPECIES:
      return {
        ...state,
        loadingBySpecies: false,
      }
    case GET_TASKS_BY_SPECIES: {
      return {
        ...state,
        loadingBySpecies: false,
        tasksBySpecies: action.tasksBySpecies,
      }
    }
    case LOADING_TASKS_BY_TYPE:
      return {
        ...state,
        loadingByType: true,
        tasksByType: [],
      }
    case ERROR_TASKS_BY_TYPE:
      return {
        ...state,
        loadingByType: false,
      }
    case GET_TASKS_BY_TYPE: {
      return {
        ...state,
        loadingByType: false,
        tasksByType: action.tasksByType,
      }
    }
    default:
      return state
  }
}
