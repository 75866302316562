import React, { useState } from 'react'
import { connect } from 'react-redux'
import { loginUser } from '../../redux/actions'
import { Container, Typography, Button, Img, Input } from '../../components'
import logo from '../../assets/logo.svg'

const Login = ({ loginUser }) => {
  const [fields, setFields] = useState({
    email: ``,
    password: ``,
  })
  const handleInput = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }
  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundColor="primary.0"
    >
      <Container
        width="35%"
        height="70%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Container padding="30px 5px">
          <Img src={logo} alt="logo saltala" width="213px" />
        </Container>
        <Container
          padding="5px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="370px"
        >
          <Container marginBottom="30px">
            <Typography
              fontSize="4"
              color="secondary.2"
              letterSpacing="1.7px"
              fontWeight="bold"
            >
              Ingresa tus datos
            </Typography>
            <Typography
              fontSize="4"
              color="secondary.2"
              letterSpacing="1.7px"
              marginLeft="6px"
            >
              para iniciar sesión
            </Typography>
          </Container>
          <Input
            width="100%"
            height="50px"
            paddingLeft="16px"
            backgroundColor="primary.6"
            color="secondary.2"
            borderRadius="6px"
            border="none"
            placeholder="Usuario"
            name="email"
            value={fields.email}
            onChange={handleInput}
            autocomplete={true}
          />
          <Input
            width="100%"
            height="50px"
            paddingLeft="16px"
            backgroundColor="primary.6"
            color="secondary.2"
            borderRadius="6px"
            border="none"
            placeholder="Contraseña"
            password={true}
            name="password"
            onChange={handleInput}
            value={fields.password}
          />
          <Button
            width="100%"
            marginTop="16px"
            onClick={() => loginUser(fields.email, fields.password)}
            text="Iniciar sesión"
            backgroundColor="primary.3"
          />
        </Container>
      </Container>
    </Container>
  )
}

const mapDispatchToProps = {
  loginUser,
}

export default connect(
  null,
  mapDispatchToProps,
)(Login)
