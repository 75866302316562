/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { format } from 'rut.js'
import { getTasks } from '../redux/actions'
import { notification } from '../utils/notification'
import API from '../config/API'
import { useListenerEnter } from '../hooks'
import { Card, Container, Input, Typography, Button, DownloadButton } from './'

function TaskFilter({
  adminUnitId,
  taskFilters,
  getTasks,
  user,
  pagination,
  filters,
  setFilters,
}) {
  useListenerEnter({ enterFn: onSearch, dependencies: [filters] })
  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      setFilters({
        ...filters,
        unitId,
      })
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      setFilters({
        ...filters,
        unitId: adminUnitId,
      })
    }
  }, [adminUnitId])

  const handleInput = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const generateCsv = async () => {
    try {
      const result = await API.downloadTaskCsv(filters)
      notification({
        type: `success`,
        message: result,
      })
    } catch (error) {
      notification({ type: `error`, message: `Error al generar archivo` })
    }
  }

  if (!taskFilters) {
    return null
  }
  function onSearch() {
    const cleanFilters = { ...filters }
    //Se limpian los filtros vacios
    Object.entries(cleanFilters).forEach(element => {
      const [key, value] = element
      if (value === `` || value === undefined) {
        delete cleanFilters[key]
      }
    })
    getTasks({ ...cleanFilters, pagination })
  }

  return (
    <Card
      width="100%"
      borderColor="gray"
      borderTop="4px solid"
      borderRadius={4}
    >
      <Container
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Typography type="label" fontWeight="bold" fontStyle="italic">
          Nueva búsqueda:
        </Typography>
        <Container alignItems="center">
          <div>
          <DownloadButton
            href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/tareas_${user.id}.csv`}
          >
            Descargar último CSV
          </DownloadButton>
          </div>
          <div style={({marginLeft:'15px'})}>
          <DownloadButton
            href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/tareas_${user.id}.xlsx`}
          >
            Descargar último Excel
          </DownloadButton>
          </div>
          <Button
            onClick={generateCsv}
            text="Generar Archivos"
            color="primary.3"
            backgroundColor="transparent"
            marginRight="10px"
          />
          <Button
            onClick={onSearch}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
          />
          <Button
            onClick={() => setFilters({ unitId: filters.unitId })}
            text="Limpiar Búsqueda"
            color="primary.3"
            backgroundColor="transparent"
          />
        </Container>
      </Container>
      <Container marginTop="32px">
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo de tarea:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="taskTypeId"
          keyValue="description"
          options={taskFilters.taskTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.taskTypeId === `` ? undefined : filters.taskTypeId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="taskStatusId"
          keyValue="description"
          options={taskFilters.taskStatuses}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.taskStatusId === `` ? undefined : filters.taskStatusId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Creado por:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="createdById"
          keyValue="description"
          options={taskFilters.creators.map(
            ({ fullrut, description, ...rest }) => ({
              ...rest,
              description: `${description} ${format(fullrut)}`,
            }),
          )}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.createdById === `` ? undefined : filters.createdById}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Terminado por:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="finishedById"
          keyValue="description"
          options={taskFilters.workers.map(
            ({ fullrut, description, ...rest }) => ({
              ...rest,
              description: `${description} ${format(fullrut)}`,
            }),
          )}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.finishedById === `` ? undefined : filters.finishedById}
        />
        {(user.roleId === 1 || user.roleId === 2 || user.roleId === 7) && (
          <Input
            width="24%"
            showSearch
            type="select"
            optionFilterProp="children"
            label="Asignadas a:"
            placeholder="Elija una opción"
            onChange={handleInput}
            name="assignedToId"
            keyValue="description"
            options={taskFilters.workers.map(
              ({ fullrut, description, ...rest }) => ({
                ...rest,
                description: `${description} ${format(fullrut)}`,
              }),
            )}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={
              filters.assignedToId === `` ? undefined : filters.assignedToId
            }
          />
        )}
        <Input
          width="24%"
          label="Id árbol:"
          placeholder="Escriba el id del árbol"
          onChange={handleInput}
          name="treeId"
          value={filters.treeId === `` ? undefined : filters.treeId}
        />
        <Input
          width="24%"
          label="Id tarea:"
          placeholder="Escriba el id de la tarea"
          onChange={handleInput}
          name="id"
          value={filters.id === `` ? undefined : filters.id}
        />
        <Input
          optionFilterProp="children"
          showSearch
          width="24%"
          type="select"
          label="Unidad vecinal:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="subUnitId"
          keyValue="description"
          value={filters.subUnitId === `` ? undefined : filters.subUnitId}
          options={taskFilters.subUnits}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        />
        <Input
          optionFilterProp="children"
          showSearch
          width="24%"
          type="select"
          label="Prioridad:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="taskPriorityId"
          keyValue="description"
          value={
            filters.taskPriorityId === `` ? undefined : filters.taskPriorityId
          }
          options={taskFilters.taskPriorities}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        />
        <Input
          width="24%"
          type="dateRange"
          label="Rango de fecha:"
          onChange={handleInput}
          name="createdIn"
          format="DD-MM-YYYY"
          value={filters.createdIn === `` ? undefined : filters.createdIn}
        />
        <Input
          width="24%"
          label="Nombre de calle:"
          onChange={handleInput}
          name="streetName"
          value={filters.streetName === `` ? undefined : filters.streetName}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Ubicación:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="locationId"
          keyValue="description"
          options={taskFilters.locations}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.locationId === `` ? undefined : filters.locationId}
        />
        {taskFilters.barrackSelect.length > 0 ? (
          <Input
            width="24%"
            showSearch
            type="select"
            optionFilterProp="children"
            label="Cuartel:"
            placeholder="Elija una opción"
            onChange={handleInput}
            name="barrackSelectId"
            keyValue="description"
            options={taskFilters.barrackSelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={
              filters.barrackSelectId === ``
                ? undefined
                : filters.barrackSelectId
            }
          />
        ) : null}
      </Container>
    </Card>
  )
}

const mapStateToProps = ({
  tasks: { taskFilters },
  auth: { user },
  general: { adminUnitId },
}) => {
  return {
    taskFilters,
    user,
    adminUnitId,
  }
}

const mapDispatchToProps = {
  getTasks,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskFilter)
