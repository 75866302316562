import _ from 'lodash'
import {
  LOADING_REQUESTS,
  ERROR_REQUESTS,
  GET_REQUESTS,
  GET_REQUEST_FILTERS,
  SUCCESS_ASSIGN_REQUEST,
  SUCCESS_REJECT_REQUEST,
  SUCCESS_APPROVE_REQUEST,
  SUCCESS_DELETE_REQUEST,
} from '../actions/types'

const initialState = {
  loading: false,
  count: 0,
  requestFilters: null,
  requestsById: {},
  requests: [],
  amounts: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST_FILTERS:
      return {
        ...state,
        requestFilters: action.requestFilters,
        amounts: action.amounts,
      }
    case LOADING_REQUESTS:
      return {
        ...state,
        loading: true,
        requests: [],
      }
    case ERROR_REQUESTS:
      return {
        ...state,
        loading: false,
      }
    case GET_REQUESTS: {
      const { requests, requestsById, count } = action
      return {
        ...state,
        loading: false,
        requests,
        requestsById,
        count,
      }
    }
    case SUCCESS_ASSIGN_REQUEST: {
      const { requests, requestsById } = state
      const cloneRequestsById = _.cloneDeep(requestsById)
      delete cloneRequestsById[action.requestId]
      return {
        ...state,
        requests: requests.filter(item => item !== action.requestId),
        requestsById: cloneRequestsById,
      }
    }
    case SUCCESS_REJECT_REQUEST: {
      const { requests, requestsById } = state
      const cloneRequestsById = _.cloneDeep(requestsById)
      delete cloneRequestsById[action.requestId]
      return {
        ...state,
        requests: requests.filter(item => item !== action.requestId),
        requestsById: cloneRequestsById,
      }
    }
    case SUCCESS_DELETE_REQUEST: {
      const { requests, requestsById } = state
      const cloneRequestsById = _.cloneDeep(requestsById)
      delete cloneRequestsById[action.requestId]
      return {
        ...state,
        requests: requests.filter(item => item !== action.requestId),
        requestsById: cloneRequestsById,
      }
    }
    case SUCCESS_APPROVE_REQUEST: {
      const { requests, requestsById } = state
      const cloneRequestsById = _.cloneDeep(requestsById)
      delete cloneRequestsById[action.requestId]
      return {
        ...state,
        requests: requests.filter(item => item !== action.requestId),
        requestsById: cloneRequestsById,
      }
    }
    default:
      return state
  }
}
