import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getAllOrderTasks } from '../../redux/actions'
import {
  Container,
  Input,
  Typography,
  Button,
  Loader,
  DownloadButton,
} from '../../components'
import { notification } from '../../utils/notification'
import API from '../../config/API'

const initialFilters = {
  taskType: ``,
  specie: ``,
  subUnit: ``,
  startDate: ``,
  endDate: ``,
  statusId: ``,
  code: ``,
  createdIn: ``,
}

function OrderTaskFilter({
  getAllOrderTasks,
  orderTaskFilters,
  loading,
  filtersSelected,
  user,
  getTaskRequest,
}) {
  const [filters, setFilters] = useState(
    filtersSelected ? filtersSelected : initialFilters,
  )
  const { taskTypes, species, subUnits, statuses } = orderTaskFilters
  const handleInput = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const getTasks = () => {
    getTaskRequest(filters)
  }

  const handleResetDataGetTasksAll = () => {
    setFilters(initialFilters)
    getAllOrderTasks()
  }

  const generateCsv = async () => {
    try {
      const result = await API.downloadCsvTaskRequest(filters)
      notification({
        type: `success`,
        message: result,
      })
    } catch (error) {
      notification({ type: `error`, message: `Error al generar archivo` })
    }
  }

  if (loading) {
    return <Loader />
  }

  if (!orderTaskFilters) {
    return null
  }
  return (
    <Container width="100%">
      <Container
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Typography type="label" fontWeight="bold" fontStyle="italic">
          Nueva búsqueda:
        </Typography>
        <Container alignItems="center">
          <div>
          <DownloadButton
            href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/OT_${user.id}.csv`}
          >
            Descargar último CSV
          </DownloadButton>
          </div>
          <div style={({marginLeft:'15px'})}>
          <DownloadButton
            href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/OT_${user.id}.xlsx`}
          >
            Descargar último Excel
          </DownloadButton>
          </div>
          <Button
            onClick={generateCsv}
            text="Generar Archivos"
            color="primary.3"
            backgroundColor="transparent"
            marginRight="10px"
          />
          <Button
            onClick={getTasks}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
          />
          <Button
            onClick={handleResetDataGetTasksAll}
            text="Limpiar Búsqueda"
            color="primary.3"
            backgroundColor="transparent"
          />
        </Container>
      </Container>
      <Container justifyContent="space-between" marginTop="32px" width="100%">
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo de tarea:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="taskTypeId"
          keyValue="description"
          options={taskTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.taskTypeId === `` ? undefined : filters.taskTypeId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Especie:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="specieId"
          keyValue="description"
          options={species}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.specieId === `` ? undefined : filters.specieId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado de OT:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="statusId"
          keyValue="description"
          options={statuses}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.statusId === `` ? undefined : filters.statusId}
        />
        <Input
          width="24%"
          showSearch
          type="input"
          optionFilterProp="children"
          label="Codigo de OT:"
          placeholder=""
          onChange={handleInput}
          name="code"
          value={filters.code === `` ? undefined : filters.code}
        />
        <Input
          width="25%"
          type="dateRange"
          format="DD-MM-YYYY"
          label="Definir periodo"
          onChange={handleInput}
          name="createdIn"
          value={filters.createdIn === `` ? undefined : filters.createdIn}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Sub Unidad:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="subUnitId"
          keyValue="description"
          options={subUnits}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.subUnitId === `` ? undefined : filters.subUnitId}
        />
      </Container>
    </Container>
  )
}

const mapStateToProps = ({
  orderTask: { orderTaskFilters, loading, filtersSelected },
  auth: { user },
}) => ({
  orderTaskFilters,
  user,
  loading,
  filtersSelected,
})

const mapDispatchToProps = {
  getAllOrderTasks,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderTaskFilter)
