import {
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  LOADING_LOGIN,
  LOG_OUT,
} from '../actions/types'

const initialState = {
  user: null,
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_LOGIN:
      return {
        ...state,
        user: action.user,
        loading: false,
      }
    case ERROR_LOGIN:
      return {
        ...state,
        error: true,
        loading: false,
      }
    case LOADING_LOGIN:
      return {
        ...state,
        loading: true,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
