import styled from 'styled-components'
import {
  compose,
  layout,
  space,
  typography,
  color,
  border,
  background,
} from 'styled-system'
import { Input, InputNumber, Select, DatePicker, Radio } from 'antd'

const { RangePicker } = DatePicker

export const RangeStyle = styled(RangePicker)`
  & .ant-calendar-picker-input.ant-input {
    ${compose(
      border,
      layout,
      space,
    )}
  }
`
RangeStyle.defaultProps = {
  height: `46px`,
}

export const RadioStyle = styled(Radio)`
  &.ant-radio-group {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(
      background,
      layout,
      space,
      color,
      border,
      typography,
    )}
    &::placeholder {
      ${color}
    }
  }
`

export const Label = styled.span`
  ${compose(
    typography,
    color,
    space,
  )}
`
Label.defaultProps = {
  fontWeight: `500`,
  fontSize: `2`,
  color: `secondary.2`,
  marginBottom: `4px`,
}

export const InputStyle = styled(Input)`
  &.ant-input {
    ${compose(
      border,
      layout,
      color,
      space,
    )}
  }
`
InputStyle.defaultProps = {
  border: `1px solid gray.3`,
  borderRadius: `3px`,
  height: `46px`,
  width: `100%`,
  color: `secondary.2s`,
  backgroundColor: `gray.0`,
  marginBottom: `10px`,
}

export const SelectStyle = styled(Select)`
  ${compose(
    border,
    layout,
    space,
  )}
  .ant-select-selection {
    padding-top: auto;
    min-height: 46px;
    .ant-select-selection__rendered {
      min-height: 46px;
    }
  }
  .ant-select-selection-selected-value {
    padding-top: 7px;
  }
`
SelectStyle.defaultProps = {
  border: `1px solid gray.3`,
  borderRadius: `3px`,
  width: `100%`,
  overflow: `hidden`,
  marginBottom: `10px`,
}

export const DateStyle = styled(DatePicker)`
  ${compose(
    border,
    layout,
    space,
  )}
  .ant-input {
    padding-top: auto;
    height: 46px;
  }
`
DateStyle.defaultProps = {
  border: `1px solid gray.3`,
  borderRadius: `3px`,
  width: `100%`,
  height: `46px`,
  marginBottom: `10px`,
}

export const InputTableStyle = styled(InputNumber)`
  &.ant-input {
    ${compose(
      border,
      layout,
      color,
      space,
    )}
  }
`
InputTableStyle.defaultProps = {
  height: `46px`,
}
