import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import LoadingAnimation from '../assets/loader.json'

const Container = styled.div`
  margin-top: 60px;
`

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid slice`,
    },
  }

  return (
    <Container>
      <Lottie options={defaultOptions} height={200} width={200} />
    </Container>
  )
}

export default Loader
