import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getTaskFilters, assignTask, getTasks } from '../../redux/actions'
import {
  MainContainer,
  HeaderInfo,
  Container,
  Card,
  Table,
  Typography,
  Link,
  Button,
} from '../../components'
import TaskFilter from '../../components/TaskFilter'
import { isEmptyObj } from '../../utils'
import ViewTask from './ViewTask'
import CreateTask from './Form'

function TasksTable({
  tasks,
  count,
  loading,
  getTaskFilters,
  getTasks,
  user,
  taskFilters,
  amounts,
  assignTask,
  adminUnitId,
}) {
  const [visibleViewModal, setVisibleViewModal] = useState(false)
  const [visibleTaskModal, setVisibleTaskModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)
  const [filters, setFilters] = useState({})
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [orders, setOrders] = useState({})

  const columns = [
    { title: `Id tarea`, dataIndex: `id`, sorter: true },
    { title: `Id Árbol`, dataIndex: `treeId`, sorter: true },
    { title: `Especie`, dataIndex: `tree.specie.description`, sorter: true },
    { title: `Fecha Creación`, dataIndex: `createdAt`, sorter: true },
    { title: `Tipo de Tarea`, dataIndex: `taskType.description`, sorter: true },
    {
      title: `Dirección`,
      dataIndex: `tree.address`,
      sorter: true,
      sorterPath: `tree.streetName`,
    },
    {
      title: `Unidad vecinal`,
      align: `center`,
      dataIndex: `tree.subUnit.description`,
      sorter: true,
    },
    { title: `Prioridad`, dataIndex: `taskPriority.description`, sorter: true },
    {
      title: `Días Restantes`,
      align: `center`,
      dataIndex: `remainingDays`,
      sorter: true,
      render: (value, row) => {
        const taskStatus = [4, 5, 6]
        return taskStatus.includes(row.taskStatusId) ? null : value
      },
    },
    { title: `Estado`, dataIndex: `taskStatus.description`, sorter: true },
    {
      title: `Detalle`,
      width: 100,
      key: `u`,
      render: (text, record) => {
        return (
          <Link
            onClick={() => {
              setEntityWork(record)
              setVisibleViewModal(true)
            }}
          >
            Ver
          </Link>
        )
      },
    },
  ]
  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getTaskFilters(unitId)
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getTaskFilters(adminUnitId)
    }
  }, [adminUnitId])

  const onTableChange = (pagination, filtersTable, sorter) => {
    const directions = {
      ascend: `ASC`,
      descend: `DESC`,
    }
    const sorters = { ...orders }
    if (!isEmptyObj(sorter)) {
      let field = sorter.column.sorterPath || sorter.field
      if (field === `remainingDays`) {
        field = `expirationDate`
      }
      sorters[field] = [...field.split(`.`), directions[sorter.order]]
      setOrders({ ...sorters })
    }
    if (!isEmptyObj(pagination)) {
      setPagination({ page: pagination.current, pageSize: pagination.pageSize })
    }
    getTasks({
      ...filters,
      pagination: { page: pagination.current, pageSize: pagination.pageSize },
      order: Object.values(sorters),
    })
  }
  return (
    <>
      {visibleViewModal && (
        <ViewTask
          onCancel={() => {
            setVisibleViewModal(false)
          }}
          data={entityWork}
          workers={taskFilters.workers}
          taskTypes={taskFilters.taskTypes}
          assignTask={assignTask}
          userRole={user.roleId}
          userId={user.id}
          showDeleteTask={user.roleId == 1 || user.Unit.showDeleteTask}
        />
      )}
      {visibleTaskModal && (
        <CreateTask
          onCancel={() => setVisibleTaskModal(false)}
          taskTypes={taskFilters.taskTypes}
          admins={taskFilters.admins}
          taskPriorities={taskFilters.taskPriorities}
          user={user}
        />
      )}
      {!taskFilters && user.roleId === 1 ? (
        <Container marginBottom="3">
          <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
            Seleccione Unidad
          </Typography>
        </Container>
      ) : null}
      {amounts && (
        <MainContainer
          action={
            [1, 3, 5].includes(user.roleId) && (
              <Button
                text="Agregar tareas"
                onClick={() => setVisibleTaskModal(true)}
              />
            )
          }
          title="Tareas"
          justifyContent="space-around"
        >
          <HeaderInfo
            list={[
              { title: amounts.pending, subtitle: `PENDIENTES` },
              { title: amounts.assigned, subtitle: `ASIGNADAS` },
              { title: amounts.finished, subtitle: `TERMINADAS/REVISADAS` },
            ]}
          />
        </MainContainer>
      )}
      <TaskFilter
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
      />
      <Card width="100%" borderColor="primary.3">
        <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
          Resultados búsqueda:
        </Typography>
        <Table
          marginTop="16px"
          columns={columns}
          dataSource={tasks.map(task => ({
            ...task,
            createdAt: moment(task.createdAt).format(`DD/MM/YYYY`),
          }))}
          loading={loading}
          locale={{
            emptyText: `No existen tareas con los parametros ingresados`,
          }}
          onChange={onTableChange}
          pagination={{
            showSizeChanger: true,
            pageSize: pagination.pageSize,
            current: pagination.page,
            total: tasks.length > 0 ? count : 0,
            pageSizeOptions: [`10`, `20`, `30`],
          }}
        />
      </Card>
    </>
  )
}

const mapStateToProps = ({
  tasks: { tasks: taskIds, tasksById, loading, taskFilters, count, amounts },
  auth: { user },
  general: { adminUnitId },
}) => {
  let filteredTasks = []
  const tasks = taskIds.map(taskId => {
    const { createdAt, ...task } = tasksById[taskId]
    if (task.taskPriority) {
      const estimatedDate = moment(createdAt).add(
        task.taskPriority.days,
        `days`,
      )
      const remainingDays = moment(estimatedDate).diff(moment(), `days`)
      return { createdAt, remainingDays, ...task }
    }
    return { createdAt, remainingDays: null, ...task }
  })
  if (user.roleId === 4) {
    filteredTasks = tasks.filter(task => task.taskStatusId !== 2)
  } else {
    filteredTasks = tasks
  }
  return {
    loading,
    tasks: filteredTasks,
    count,
    amounts,
    taskFilters,
    user,
    adminUnitId,
  }
}

const mapDispatchToProps = {
  getTasks,
  getTaskFilters,
  assignTask,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TasksTable)
