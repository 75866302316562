import React from 'react'
import styled from 'styled-components'
import { compose, layout, space, color } from 'styled-system'
import { Table } from 'antd'

const TableStyled = styled(Table)`
  ${compose(
    layout,
    space,
  )}
  .ant-table-row:hover {
    ${color}
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    ${color}
  }
`
TableStyled.defaultProps = {
  width: `100%`,
  zIndex: 10000,
  backgroundColor: `primary.5`,
}

const TableCustom = ({ dataSource, columns, ...props }) => {
  return <TableStyled dataSource={dataSource} columns={columns} {...props} />
}

export default TableCustom
