import {
  USERS_LOADING,
  GET_USERS,
  GET_USERS_ERROR,
  ADD_USER,
  ADD_USER_ERROR,
  EDIT_USER,
  EDIT_USER_ERROR,
  REMOVE_USER,
  REMOVE_USER_ERROR,
} from '../actions/types'

const initialState = {
  loading: false,
  error: null,
  users: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_USERS: {
      const { users } = action
      return {
        ...state,
        users,
        loading: false,
      }
    }
    case GET_USERS_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case ADD_USER: {
      const { id, user } = action
      const nextUser = {
        ...user,
        id,
      }
      return {
        ...state,
        users: [...state.users, nextUser],
        loading: false,
      }
    }
    case ADD_USER_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case EDIT_USER: {
      const {
        user: { user, userId },
      } = action
      const { users } = state
      const index = users.map(x => x.id).indexOf(userId)
      users[index] = user
      return {
        ...state,
        users,
        loading: false,
      }
    }
    case EDIT_USER_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case REMOVE_USER: {
      const { userId } = action
      const { users } = state
      const index = users.map(x => x.id).indexOf(userId)
      users.splice(index, 1)
      return {
        ...state,
        users,
        loading: false,
      }
    }
    case REMOVE_USER_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    default:
      return state
  }
}
