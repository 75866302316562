import _ from 'lodash'
import {
  SPECIES_LOADING,
  SPECIES_ERROR,
  GET_SPECIES,
  GET_SPECIE_FIELDS,
  SET_SPECIE_VALUES,
  ADD_SPECIES,
  GET_SPECIE_BY_ID,
  EDIT_SPECIE,
  SETUP_SPECIE_LOADING,
  SETUP_SPECIE_SUCCESS,
  SETUP_SPECIE_ERROR,
} from '../actions/types'

const initialState = {
  species: [],
  loading: false,
  setupLoading: false,
  specieFilters: null,
  specieFields: null,
}

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case SPECIES_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case SPECIES_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case GET_SPECIE_BY_ID:
      return {
        ...state,
      }
    case GET_SPECIES: {
      return {
        ...state,
        loading: false,
        species: action.result,
      }
    }
    case GET_SPECIE_FIELDS: {
      return {
        ...state,
        specieFields: action.result,
      }
    }
    case EDIT_SPECIE: {
      
    }
    case SET_SPECIE_VALUES: {
      return {
        ...state,
        specieValues: {
          selectedRowKeys: action.selectedRowKeys,
          selectedIds: action.selectedIds,
        },
      }
    }
    case SETUP_SPECIE_LOADING: {
      return {
        ...state,
        setupLoading: true,
      }
    }
    case SETUP_SPECIE_SUCCESS: {
      return {
        ...state,
        setupLoading: false,
      }
    }
    case SETUP_SPECIE_ERROR: {
      return {
        ...state,
        setupLoading: false,
      }
    }
    default:
      return state
  }
}
