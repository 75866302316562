import styled from 'styled-components'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 100vh;
`
const Content = styled.div`
  flex: 1;
  position: absolute;
  left: 230px;
  top: 56px;
  width: calc(100% - 230px);
`
const Container = styled.div`
  padding: 1em 2em;
`

export { Layout, Content, Container }
