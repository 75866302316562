import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Input, Loader, Button } from '../../components'
import { addUnit, editUnit, getGenericFields } from '../../redux/actions'
import ImageUploaderClient from '../../components/ImageUploaderClient'

const getInitialState = {
  name: ``,
  rut: ``,
  subUnitName: ``,
  isActive: false,
  hasNFC: true,
  unitLatitude: ``,
  unitLongitude: ``,
  species: [],
}

const getInitialProject = {
  title: ``,
  body: ``,
}

const Form = ({
  data,
  addUnit,
  editUnit,
  onCancel,
  getGenericFields,
  genericFieldsById,
  loading,
}) => {
  const [fields, setFields] = useState(getInitialState)
  const [projectInfo, setProjectInfo] = useState(getInitialProject)

  useEffect(() => {
    if (data) {
      setFields({
        ...fields,
        ...data,
      })
      if (data.ProjectInfo) {
        setProjectInfo({
          ...projectInfo,
          ...data.ProjectInfo
        })
      }
    }
  }, [])

  useEffect(() => {
    setFields({
      ...fields,
      ...data,
    })
  }, [data])

  useEffect(() => {
    getGenericFields(`Specie`)
  }, [])

  useEffect(() => {
    if (data) {
      const { id: unitId } = data

      const oldSpecie = Object.values(genericFieldsById).reduce((acc, item) => {
        const { unitConfig, id } = item
        if (
          unitConfig &&
          unitConfig.belongsTo &&
          unitConfig.belongsTo.includes(unitId)
        ) {
          acc.push(id)
        }
        return acc
      }, [])

      setFields(prevState => ({ ...prevState, species: oldSpecie }))
    }
  }, [genericFieldsById])

  const onInputChange = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }

  const onProejctInfoChange = (field, value) => {
    setProjectInfo({
      ...projectInfo,
      [field]: value,
    })
  }

  const handleToggleSelectAllSpecie = () => {
    const specieIds = Object.values(genericFieldsById)
      .filter(({ description }) => description && description.length > 0)
      .map(({ id }) => id)

    setFields({
      ...fields,
      species: specieIds,
    })
  }

  const onAccept = () => {
    if (data) {
      const { id } = data
      editUnit(id, fields, projectInfo)
    } else {
      addUnit(fields, projectInfo)
    }
    onCancel()
  }

  return (
    <Modal
      onAccept={onAccept}
      onCancel={onCancel}
      visible={true}
      title="Creación o edición de cliente"
    >
      <Input
        width="48%"
        label="Ingrese nombre:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.name}
        name="name"
      />
      <Input
        width="48%"
        label="Ingrese rut:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.rut}
        name="rut"
      />
      <Input
        width="48%"
        label="Ingrese nombre sub unidad:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.subUnitName}
        name="subUnitName"
      />
      <Input
        width="48%"
        label="Posee NFC:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.hasNFC}
        name="hasNFC"
        type="select"
        keyValue="description"
        options={[
          { id: true, description: `Si` },
          { id: false, description: `No` },
        ]}
      />
      <Input
        width="48%"
        label="Ingrese latitud por defecto:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.unitLatitude}
        name="unitLatitude"
      />
      <Input
        width="48%"
        label="Ingrese longitud por defecto:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.unitLongitude}
        name="unitLongitude"
      />
      <Input
        width="97%"
        label="Ingrese título de proyecto:"
        placeholder="Escriba aqui"
        onChange={onProejctInfoChange}
        value={projectInfo.title}
        name="title"
      />
      <Input
        width="97%"
        label="Ingrese descripción del proyecto:"
        placeholder="Escriba aqui"
        onChange={onProejctInfoChange}
        value={projectInfo.body}
        name="body"
        maxLength={5000}
      />

      {fields.id && <>
      <h3>Logo de Cliente</h3>
      <hr/>
      <ImageUploaderClient
      client={{ clientId: fields.id }}/>
      <img
        label="Imagen Actual"
        width="96%"
        src={fields.logoUrl}
        name="url"
      />
      </>
      }

      {loading ? (
        <Loader />
      ) : (
        <>
          <Input
            width="97%"
            name="species"
            label="Especies (opcional)"
            type="select"
            onChange={onInputChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            keyValue="description"
            mode="multiple"
            value={fields.species}
            options={Object.values(genericFieldsById)}
            showArrow
          />
          <Button
            text="Seleccionar todas"
            onClick={handleToggleSelectAllSpecie}
            color="primary.3"
            backgroundColor="transparent"
          />
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = ({
  genericFields: { genericFieldsById, loading },
}) => ({
  genericFieldsById,
  loading,
})

const mapDispatchToProps = { addUnit, editUnit, getGenericFields }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
