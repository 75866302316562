import API from '../../config/API'
import { notification } from '../../utils'
import {
  CLIENTS_LOADING,
  CLIENTS_ERROR,
  ADD_CLIENT,
  EDIT_CLIENT,
  SET_CURRENT_UNIT,
  GET_CLIENTS,
  GET_FIELDS,
  GET_SPECIES,
  GET_DAPS,
  GET_TASK_TYPES,
  GET_REQUEST_TYPES,
  SETUP_CLIENT_LOADING,
  SETUP_CLIENT_SUCCESS,
  SETUP_CLIENT_ERROR,
} from './types'

export const addUnit = (unit, projectInfo) => async dispatch => {
  dispatch({ type: CLIENTS_LOADING })

  try {
    const id = await API.addUnit(unit, projectInfo)
    notification({
      type: `success`,
      message: `Se ha agregado el cliente correctamente`,
    })
    dispatch({ type: ADD_CLIENT, id, unit })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const editUnit = (unitId, unit, projectInfo) => async dispatch => {
  dispatch({ type: CLIENTS_LOADING })

  try {
    const result = await API.editUnit(unitId, unit, projectInfo)
    notification({
      type: `success`,
      message: result,
    })
    dispatch({ type: EDIT_CLIENT, unitId, unit })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const getClients = () => async dispatch => {
  dispatch({ type: CLIENTS_LOADING })
  try {
    const result = await API.getUnits()
    dispatch({
      type: GET_CLIENTS,
      result,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
    dispatch({ type: CLIENTS_ERROR })
  }
}

export const getFields = () => async dispatch => {
  try {
    const result = await API.getFields()
    dispatch({
      type: GET_FIELDS,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const setValues = (type, selectedRowKeys, selectedIds) => dispatch => {
  dispatch({
    type,
    selectedRowKeys,
    selectedIds,
  })
}

export const setCurrentUnit = unitId => async dispatch => {
  try {
    const result = await API.getUnitValues(unitId)
    dispatch({
      type: SET_CURRENT_UNIT,
      unitId,
      unitValues: result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const getSpecies = () => async dispatch => {
  try {
    const result = await API.getSpecies()
    dispatch({
      type: GET_SPECIES,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const getDaps = () => async dispatch => {
  try {
    const result = await API.getDaps()
    dispatch({
      type: GET_DAPS,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const getTaskTypes = () => async dispatch => {
  try {
    const result = await API.getTaskTypes()
    dispatch({
      type: GET_TASK_TYPES,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const getRequestTypes = () => async dispatch => {
  try {
    const result = await API.getRequestTypes()
    dispatch({
      type: GET_REQUEST_TYPES,
      result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const setupUnit = (unit, currentUnitId) => async dispatch => {
  dispatch({ type: SETUP_CLIENT_LOADING })
  try {
    const result = await API.setupUnit(unit, currentUnitId)
    notification({
      type: `success`,
      message: result,
    })
    dispatch({ type: SETUP_CLIENT_SUCCESS })
  } catch (error) {
    notification({ type: `error`, message: error })
    dispatch({ type: SETUP_CLIENT_ERROR })
  }
}
