import {
  GET_ASIGN_TASKS,
  LOADING_ASIGN_TASKS,
  ERROR_ASIGN_TASKS,
} from '../actions/types'

const initialState = {
  loading: false,
  tasks: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ASIGN_TASKS:
      return {
        ...state,
        loading: true,
        tasks: [],
      }
    case ERROR_ASIGN_TASKS:
      return {
        ...state,
        loading: false,
      }
    case GET_ASIGN_TASKS:
      return {
        ...state,
        loading: false,
        tasks: action.tasks,
      }
    default:
      return state
  }
}
