import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import Supercluster from 'supercluster'
import moment from 'moment'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { getAllOrderTasks, getOrderTaskFilters } from '../../../redux/actions'
import {
  Typography,
  Loader,
  Container,
  Card,
  Icon,
  Img,
  Modal,
} from '../../../components'
import ShowOrderTask from '../showOrderTask'
import OrderTaskFilterToMap from './filtersOrdenTaskToMaps'

const treeStates = {
  1: {
    color: `green`,
    path: `/assets/map-pin1.svg`,
  },
  2: {
    color: `yellow`,
    path: `/assets/map-pin2.svg`,
  },
  3: {
    color: `red`,
    path: `/assets/map-pin3.svg`,
  },
  4: {
    color: `black`,
    path: `/assets/map-pin4.svg`,
  },
  5: {
    color: `orange`,
    path: `/assets/map-pin5.svg`,
  },
}

const handleDaysAllow = ({ timeLimit, createdAt, statuses }) => {
  const statusDone = statuses.find(el => el.statusId === 2)
  if (statusDone) return { days: 0, background: `black`, color: `#FFFFFF` }

  const remainingDays = timeLimit + moment(createdAt).diff(moment(), `days`)
  if (remainingDays >= 4 && remainingDays <= 6) {
    return { days: remainingDays, background: `yellow` }
  }
  if (remainingDays >= 7) {
    return { days: remainingDays, background: `green` }
  }

  return { days: remainingDays, background: `red` }
}

const Marker = ({ isCluster, task, showModal }) => {
  if (isCluster) return <Icon icon={faCircle} style={{ color: `blue` }} />
  const { background } = handleDaysAllow({
    timeLimit: task.timeLimit,
    createdAt: task.dateCreated,
    statuses: task.changeStatuses,
  })

  return (
    <>
      <Img
        onClick={() => showModal(task)}
        src={`${process.env.PUBLIC_URL}${
          Object.values(treeStates).find(state => state.color === background)
            .path
        }`}
        width="2em"
        height="2em"
        alt="tree icon"
      />
    </>
  )
}

const defaultProps = {
  defaultCenter: {
    lat: -33.4330527,
    lng: -70.6345756,
  },
  defaultZoom: 15,
  center: {
    lat: -33.4330527,
    lng: -70.6345756,
  },
  filters: {},
  filteredStreets: [],
  clusters: [],
  showModal: false,
  selectedTree: {},
  isStateColor: true,
  tasks: [],
  bounds: null,
  zoom: 15,
  task: null,
  img: null,
  showImg: false,
}

const clusterizeOrderTask = ({ tasks, bounds, zoom }) => {
  if (!bounds) return false
  const boundArray = [
    bounds.nw.lng,
    bounds.sw.lat,
    bounds.se.lng,
    bounds.ne.lat,
  ]
  const clusterizer = new Supercluster({
    radius: 60,
    maxZoom: 16,
  })
  const taskPoints = tasks.map(task => {
    const { id, longitude, latitude, streetName, streetNumber } = task
    return {
      type: `Feature`,
      geometry: {
        type: `Point`,
        coordinates: [longitude, latitude],
      },
      // Agregar acá propiedades del árbol para el marker
      properties: {
        taskId: id,
        address: `${streetName}, ${streetNumber}`,
        task,
      },
    }
  })
  clusterizer.load(taskPoints)
  const clusterArray = clusterizer.getClusters(boundArray, zoom)
  return clusterArray
}

function OrdenTaskMap({
  loading,
  getOrderTaskFilters,
  getAllOrderTasks,
  orderTasks,
  orderTaskFilters,
  adminUnitId,
  user,
}) {
  const [state, setState] = useState(defaultProps)
  const {
    defaultCenter,
    defaultZoom,
    tasks,
    bounds,
    zoom,
    clusters,
    isStateColor,
    task,
    showModal,
    center,
    img,
    showImg,
  } = state

  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getOrderTaskFilters(unitId)
    }

    getAllOrderTasks()
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getOrderTaskFilters(adminUnitId)
    }
  }, [adminUnitId])

  useEffect(() => {
    if (orderTasks.length > 0) {
      const clusterArray = clusterizeOrderTask({
        tasks: orderTasks,
        bounds,
        zoom,
      })
      setState(prevState => ({
        ...prevState,
        tasks: orderTasks,
        center: {
          lat: orderTasks[0].latitude,
          lng: orderTasks[0].longitude,
        },
        clusters: clusterArray,
      }))
    }
  }, [orderTasks])

  if (loading) {
    return <Loader />
  }

  const createMapOptions = maps => {
    return {
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
      },
    }
  }

  const onMapChange = ({ zoom, bounds }) => {
    const clusterArray = clusterizeOrderTask({ tasks, bounds, zoom })
    setState(prevState => ({
      ...prevState,
      zoom,
      bounds,
      clusters: clusterArray,
    }))
  }

  const handleShowOT = task => {
    setState(prevState => ({ ...prevState, task, showModal: true }))
  }

  const handleCloseModal = () => {
    setState(prevState => ({ ...prevState, task: null, showModal: false }))
  }

  const handleCloseShowImg = () => {
    setState(prevState => ({
      ...prevState,
      visibleShowOrderTaskModal: true,
      img: null,
      showImg: false,
    }))
  }

  return (
    <>
      <Container width="100%">
        <Typography type="title">Mapa</Typography>
        {showModal && (
          <ShowOrderTask
            task={task}
            handleCloseShowOrderTask={handleCloseModal}
            setState={setState}
          />
        )}
        {orderTaskFilters && <OrderTaskFilterToMap />}
        {orderTasks.length > 0 && (
          <Card width="100%" borderColor="primary.3" marginTop="16px">
            <Container marginTop="16px" height="80vh" width="100%">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: `AIzaSyDTveYGct8Kmle1JLGtmvMcxPwU4FeeevE`,
                }}
                options={createMapOptions}
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}
                onChange={onMapChange}
                center={center}
              >
                {clusters.length > 0 &&
                  clusters.map(cluster => {
                    const {
                      cluster: isCluster,
                      point_count_abbreviated: clusterCount,
                      taskId,
                      address,
                      task,
                    } = cluster.properties
                    const [lng, lat] = cluster.geometry.coordinates
                    return (
                      <Marker
                        lat={lat}
                        lng={lng}
                        isCluster={isCluster}
                        clusterCount={clusterCount}
                        taskId={taskId}
                        address={address}
                        task={task}
                        isStateColor={isStateColor}
                        showModal={handleShowOT}
                      />
                    )
                  })}
              </GoogleMapReact>
            </Container>
          </Card>
        )}
        {!orderTasks.length && (
          <Card width="100%" borderColor="primary.3" marginTop="16px">
            <Typography type="subtitle">No existe data para mostrar</Typography>
          </Card>
        )}
        {showImg && (
          <Modal
            onAccept={handleCloseShowImg}
            onCancel={handleCloseShowImg}
            showCancel={false}
            visible={true}
          >
            <Container width="100%" justifyContent="center">
              <img src={img} alt="img-change-status-ot" width="50%" />
            </Container>
          </Modal>
        )}
      </Container>
    </>
  )
}

const mapStateToProps = ({
  auth: { user },
  orderTask: { loading, orderTasks, orderTaskFilters },
  general: { adminUnitId },
}) => {
  const mapOrderTask = orderTasks.map(task => {
    return {
      ...task,
      dateCreated: task.createdAt,
      createdAt: moment(task.createdAt).format(`DD/MM/YYYY`),
      updatedAt: moment(task.updatedAt).format(`DD/MM/YYYY`),
    }
  })

  return {
    user,
    loading,
    orderTasks: mapOrderTask,
    orderTaskFilters,
    adminUnitId,
  }
}

const mapDispatchToProps = {
  getAllOrderTasks,
  getOrderTaskFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdenTaskMap)
