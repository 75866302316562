import styled from 'styled-components'
import { Link } from 'react-router-dom'

const SideBarContainer = styled.div`
  background-color: #81bb2a;
  min-height: 100vh;
  /* flex: 0.12; */
  width: 5%;
  padding: 1em 0 0 1em;
  & > img {
    width: 10em;
  }
  position: fixed;
  left: 0;
  top: 0;
  width: 230px;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Logo = styled.img`
  ${({ isCollapsed }) => `
  width: ${isCollapsed ? `55px` : `159px`}
`}
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1em;
  & a {
    text-decoration: none;
    color: #fff;
  }
`
const LinkStyled = styled(Link)`
  ${({ isCollapsed }) =>
    `justify-content: ${isCollapsed ? `center` : `flex-start`}`}
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.8em;
  transition: all 0.5s ease;
  margin-bottom: 0.3em;
  & span {
    margin-left: 1em;
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    background-color: rgba(171, 214, 105, 0.634124);
    border-radius: 200px 10px 10px 200px;
    -moz-border-radius: 200px 10px 10px 200px;
    -webkit-border-radius: 200px 10px 10px 200px;
    text-decoration: none;
    & > span {
      margin-left: 1em;
      color: #fff;
      text-decoration: none;
    }
  }
  ${({ active }) =>
    active &&
    `background-color: #62A004; 
    border-radius: 200px 10px 10px 200px;
    -moz-border-radius: 200px 10px 10px 200px;
    -webkit-border-radius: 200px 10px 10px 200px;
    padding-top:10;
    padding-bottom:10;
    text-decoration: none;
    padding-right: 300;   
    text-align:'center';  
    & > span {
      margin-left: 1em;
      color: #fff;
      text-decoration: none
    }
  `}
`

const LinkChildStyled = styled(LinkStyled)`
  padding: 0 0.8em;
  margin-bottom: 0.5rem;
  font-weight: 100;
  & span {
    margin-left: 3.5em;
  }
  &:hover {
    background-color: initial;
    text-decoration: underline;
    & > span {
      margin-left: 3.5em;
    }
  }
  ${({ active }) =>
    active &&
    `background-color: initial; 
    text-decoration: underline;
    font-weight: 600;
    & > span {
      margin-left: 3.5em;
    }
  `}
`

const LogOutStyled = styled.a`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding: 0.8em;
  transition: all 0.5s ease;
  margin-top: 3em;
  padding-left: 2em;
  & span {
    margin-left: 1em;
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    background-color: #62a004;
    border-radius: 200px 10px 10px 200px;
    -moz-border-radius: 200px 10px 10px 200px;
    -webkit-border-radius: 200px 10px 10px 200px;
    padding-right: 0;
    text-decoration: none;
    & > span {
      margin-left: 1em;
      color: #fff;
      text-decoration: none;
    }
  }
`

export {
  SideBarContainer,
  LogoContainer,
  Logo,
  Links,
  LinkStyled,
  LogOutStyled,
  LinkChildStyled,
}
