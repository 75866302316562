import styled from 'styled-components'
import { Button } from '@blueprintjs/core'
import { Select } from 'antd'

export const HeaderContainer = styled.div`
  background-color: #a7cf6a;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  padding: 0 2em;
  margin: 0;
  height: 4em;
  position: fixed;
  left: 230px;
  top: 0;
  width: calc(100% - 230px);
  z-index: 100;
`
export const TextProfile = styled.h4`
  color: #fff;
  font-size: 16px;
  margin-top: 0.5em;
  margin-left: 1em;
`

export const LogOut = styled.h4`
  color: #fff;
  font-size: 16px;
  margin-top: 0.5em;
  margin-left: 1em;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonStyled = styled(Button)`
  color: #fff !important;
`
export const RoundedInput = styled.input`
  &::placeholder {
    color: #fff;
  }
  border-radius: 1em;
  background-color: rgba(196, 241, 254, 0.39);
  border: none;
  padding: 0.5em;
  width: 300px;
  color: #fff;
`

export const SelectStyle = styled(Select)`
  ${({ width = `18%` }) => `width: ${width};`}
`
