import React, {useState} from 'react';
import API from '../config/API';
import { notification } from '../utils/notification'

const CSVLoader = () => {
    const MAX_NUM_FILES = 3;
    const BATCH_SIZE = 25; // Maximo numero de filas a cargar a la vez
    const GLOBAL_VAR = { failed: 0 };
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [file3, setFile3] = useState();
    const fileReader1 = new FileReader();
    const fileReader2 = new FileReader();
    const fileReader3 = new FileReader();
    const columnNameDict = {
        Latitud: 'latitude',
        Longitud: 'longitude',
        Latitude: 'latitude',
        Longitude: 'longitude',
        CoorEste: 'coorEste',
        CoorNorte: 'coorNorte',
        Direccion: 'streetName',
        direccion: 'streetName',
        Ubicacion: 'location',
        ubicacion: 'location',
        Tipo: 'refToTreeType', //A diferencia de otros, este campo es el nombre, a partir de el se debe buscar id en TreeTypes
        tipo: 'refToTreeType'  //A diferencia de otros, este campo es el nombre, a partir de el se debe buscar id en TreeTypes
    };

    const handleFileInput = (e) => {
        try {
            setFile1(null);
            setFile2(null);
            setFile3(null);

            if (e.target.files.length <= MAX_NUM_FILES) {
                setFile1(e.target.files[0]);
                setFile2(e.target.files[1]);
                setFile3(e.target.files[2]);
            } else {
                notification({
                    type: `error`,
                    message: "Máximo "+MAX_NUM_FILES+" archivos a la vez",
                })
            }
        } catch (err) {
            console.log(err);
            notification({
                type: `error`,
                message: "Ocurrió un problema al cargar CSV",
            })
        }
    }

    const convertCSVToArray = (fileDataString) => {
        fileDataString = fileDataString.replaceAll(/�/g,'_');
        fileDataString = fileDataString.replaceAll(/\"/g,'');
        const csvRows = fileDataString.split("\n");
        let cleanHeader = csvRows[0].replace(/\r/, '');
        const headers = handleCSVSeparator(cleanHeader);
        translateNames(headers);
        const jsonCSV = [];
        const jsonBatch = [];
        let batchSizeCounter = 0;
        for (let r = 1; r < csvRows.length; r++) {
            const entry = handleCSVSeparator(csvRows[r].replace(/\r/, ''));
            const jsonRow = {};

            if (entry.length != headers.length) {
                GLOBAL_VAR.failed += 1;
                continue;
            }

            for (let c = 0; c < headers.length; c++) {
                if (c >= entry.length) {
                    break;
                } else {
                    if (entry[c] != '') {
                        jsonRow[headers[c]] = entry[c];
                    }
                }
            }

            if (Object.keys(jsonRow).length) {
                jsonBatch.push(Object.assign({}, jsonRow));
                batchSizeCounter++;
            }

            if (batchSizeCounter >= BATCH_SIZE) {
                jsonCSV.push([...jsonBatch]);
                batchSizeCounter = 0;
                jsonBatch.length = 0;
            }
        }
        if (batchSizeCounter > 0) {
            jsonCSV.push([...jsonBatch]);
        }
        return jsonCSV;
    }

    // Arregla nombres de columnas para coincidir con los de BD
    const translateNames = (namesArr) => {
        for (let i=0; i < namesArr.length; i++) {
            if (namesArr[i] in columnNameDict) {
                namesArr[i] = columnNameDict[namesArr[i]];
            }    
        }
    }

    const handleCSVSeparator = (str) => {
        str = str.replace(/\r/, '');
        const arr = str.split(";");
        if (arr.length == 1) {
            return str.split(",");
        }
        return arr;
    }

    const handleSubmit = (e) => {
        try {
            GLOBAL_VAR.failed = 0;
            e.preventDefault();
            
            if (file1) {
                processFile(file1, fileReader1);
            } else {
                notification({
                    type: `error`,
                    message: "No se ha cargado un archivo CSV",
                })
            }
            if (file2) {
                processFile(file2, fileReader2);
            }
            if (file3) {
                processFile(file3, fileReader3);
            }
        } catch (err) {
            console.log(err);
            notification({
                type: `error`,
                message: "Ocurrió un problema al enviar CSV",
            })
        }
    }

    const processFile = async (file, fileReader) => {
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;
        };
        fileReader.readAsText(file, 'Windows-1252');
        fileReader.addEventListener('loadend', async () => {
            const arrCSV = convertCSVToArray(fileReader.result);

            notification({
                type: `info`,
                message: "Cargando CSV...",
            })

            let totalSuccess = true;
            for (const arr of arrCSV) {
                let success = await callApi(arr);
                totalSuccess = success && totalSuccess;
            }

            showNotification(totalSuccess, GLOBAL_VAR.failed);
        })
    }

    const callApi = async (arr) => {
        try{
            const resp = await API.uploadTreeCsv(arr);
            GLOBAL_VAR.failed += resp.failed;
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    const showNotification = (success, rowsFailed) => {
        if (success) {
            if (rowsFailed === 0) {
                notification({
                    type: `success`,
                    message: "CSV enviado correctamente",
                })
            } else {
                notification({
                    type: `error`,
                    message: "Error al cargar " + rowsFailed + " filas",
                })
            }
        } else {
            notification({
                type: `error`,
                message: "Error al enviar CSV",
            })
        }
    }

    return (
        <div className="file-uploader" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, width: `100%`, flexDirection: `column`}}>
            <div style={{width: `100%`, display: `flex`, flex: 1, alignItems: `center`, flexDirection: `column`}}>
                <label style={{flex: 1, alignItems: `center`}}>
                    Actualizar datos de árboles desde CSV (máximo {MAX_NUM_FILES} archivos a la vez)
                </label>
                <input type={"file"} accept={".csv"} onChange={handleFileInput} multiple style={{flex: 1}}/>
            </div>

            <div style={{flex: 1}}>
            <button onClick={(e) => {handleSubmit(e)}}>
                Enviar CSV
            </button>
            </div>
        </div>
    )
}
export default CSVLoader