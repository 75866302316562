import React from 'react'
import moment from 'moment'
import Container from './Container'
import Typography from './Typography'
import {
  InputStyle,
  SelectStyle,
  DateStyle,
  RangeStyle,
  RadioStyle,
} from './styles'

const inputType = {
  input({
    value,
    onChange,
    placeholder,
    name,
    file,
    password,
    disabled,
    number,
    time,
    autocomplete = false,
    ...props
  }) {
    if (password) {
      return (
        <InputStyle
          onChange={({ target: { name, value } }) => onChange(name, value)}
          placeholder={placeholder || `Escribe aquí`}
          name={name}
          value={value}
          type="password"
          disabled={disabled}
          {...props}
        />
      )
    }
    return (
      <InputStyle
        onChange={({ target: { name, value } }) => onChange(name, value)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        autocomplete={autocomplete ? `on` : `off`}
        type={file ? `file`: number ? `number` : time ? `time` : `text`}
        {...props}
      />
    )
  },
  select: ({
    value,
    treeSelect = false,
    onChange,
    placeholder,
    options = [],
    name,
    keyValue,
    valueName,
    ...props
  }) => {
    if (treeSelect) {
      return (
        <SelectStyle
          onChange={value => onChange(name, valueName, value)}
          placeholder={placeholder || `Seleccionar`}
          name={name}
          value={value}
          key={name}
          {...props}
        >
          {options.filter(x => x).map(option => {
            return (
              <SelectStyle.Option
                key={option.id}
                value={JSON.stringify(option)}
              >
                {option.description}
              </SelectStyle.Option>
            )
          })}
        </SelectStyle>
      )
    }
    return (
      <SelectStyle
        onChange={value => onChange(name, value)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        {...props}
      >
        {options.filter(x => x).map(option => (
          <SelectStyle.Option key={option.id || null} value={option.id || null}>
            {option[keyValue]}
          </SelectStyle.Option>
        ))}
      </SelectStyle>
    )
  },
  boolean: ({
    value,
    onChange,
    placeholder,
    options = [
      { id: false, description: `No` },
      { id: true, description: `Si` },
    ],
    name,
    ...props
  }) => (
    <SelectStyle
      onChange={value => onChange(name, value)}
      placeholder={placeholder || `Seleccionar`}
      name={name}
      value={value}
      key={name}
      {...props}
    >
      {options.filter(x => x).map(option => {
        return (
          <SelectStyle.Option key={option.id} value={option.id}>
            {option.description}
          </SelectStyle.Option>
        )
      })}
    </SelectStyle>
  ),
  address: ({ value, onSearch, onSelect, options = [], name }) => (
    <SelectStyle
      showSearch
      showArrow={false}
      notFoundContent={null}
      filterOption={false}
      defaultActiveFirstOption={false}
      onSearch={onSearch}
      onSelect={onSelect}
      placeholder="Nombre de calle"
      value={value}
      key={name}
    >
      {options &&
        options.filter(x => x).map(street => (
          <SelectStyle.Option value={JSON.stringify(street)}>
            {street.description}
          </SelectStyle.Option>
        ))}
    </SelectStyle>
  ),
  find: ({
    value,
    onSearch,
    onSelect,
    placeholder,
    options = [],
    name,
    keyValue,
    ...props
  }) => (
    <SelectStyle
      onSearch={value => onSearch(value)}
      onSelect={value => onSelect(name, value)}
      placeholder={placeholder || `Seleccionar`}
      name={name}
      value={value}
      key={name}
      {...props}
    >
      {options.filter(x => x).map(option => (
        <SelectStyle.Option key={option[keyValue]} value={option[keyValue]}>
          {option[keyValue]}
        </SelectStyle.Option>
      ))}
    </SelectStyle>
  ),
  file:({ value, onChange, placeholder, name, ...props }) => (
    <InputStyle
      onChange={value => onChange(name, value)}
      placeholder="subir imagen"
      name={name}
      value={value}
      key={name}
      {...props}
    />
  ),
  date: ({ value, onChange, placeholder, name, ...props }) => (
    <DateStyle
      onChange={value => onChange(name, value ? moment(value).format() : value)}
      placeholder="Seleccionar"
      name={name}
      format="DD/MM/YYYY"
      value={value ? moment(value) : null}
      key={name}
      {...props}
    />
  ),
  dateRange({ value, onChange, name, ...props }) {
    return (
      <RangeStyle
        value={value}
        onChange={value => onChange(name, value)}
        name={name}
        {...props}
      />
    )
  },
  radio({ value, onChange, placeholder, options = [], name, ...props }) {
    return (
      <RadioStyle.Group
        onChange={({ target: { value } }) => onChange(name, value)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        {...props}
      >
        {options.filter(x => x).map(option => (
          <RadioStyle key={option.value} value={option.value}>
            {option.label}
          </RadioStyle>
        ))}
      </RadioStyle.Group>
    )
  },
}

const Input = ({
  type = `input`,
  label,
  name,
  theme,
  error,
  margin = null,
  width = null,
  maxLength = 250,
  paddingWrapper = `6px 6px 0 0`,
  marginWrapper = `4px`,
  onChange = () => console.error(`You should provide an onChange function.`),
  ...props
}) => {
  const Component = inputType[type]
  return (
    <Container
      flexDirection="column"
      width={width}
      margin={marginWrapper}
      padding={paddingWrapper}
    >
      {label && (
        <Typography color="input" margin="0 0 5px 0px" fontWeight="500">
          {label}
        </Typography>
      )}
      <Component name={name} maxLength={maxLength} error={error} onChange={onChange} {...props} />
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </Container>
  )
}
export default Input
