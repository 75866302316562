import API from '../../config/API'
import { notification } from '../../utils'
import { SUCCESS_LOGIN, ERROR_LOGIN, LOADING_LOGIN, LOG_OUT } from './types'

export const loginUser = (email, password) => async dispatch => {
  dispatch({ type: LOADING_LOGIN })
  try {
    const result = await API.loginUser({ email, password })
    localStorage.setItem(`arbotag-token`, result.token)
    dispatch({
      type: SUCCESS_LOGIN,
      user: result,
    })
  } catch (error) {
    notification({ type: `error`, message: `Usuario no autorizado` })
    dispatch({ type: ERROR_LOGIN })
  }
}

export const logOut = () => ({ type: LOG_OUT })
