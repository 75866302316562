import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Input from './Input'
import Button from './Button'
import Container from './Container'

const HeaderContainer = ({
  hasCreate = false,
  hasSearch = false,
  hasSave = false,
  personalizedHeader = null,
  handleCreate,
  handleSearch,
  handleSearchInput,
  handleSave,
  search,
  defaultData,
  searchText,
}) => {
  if (!hasSearch && !personalizedHeader && !hasCreate && !hasSave) {
    return null
  }
  return (
    <Container
      justifyContent="space-between"
      width="100%"
      marginBottom="32px"
      flexWrap="nowrap"
    >
      <>
        {hasSearch && (
          <>
            <Input
              onChange={handleSearchInput}
              marginBottom="0"
              value={search}
              placeholder={searchText}
              paddingWrapper="0"
              marginWrapper="0"
              width="100%"
            />
            <Button
              text="Buscar"
              onClick={handleSearch}
              boxShadow={true}
              backgroundColor="gray.2"
              marginLeft="8px"
            />
            <Button
              marginLeft="8px"
              text="Limpiar búsqueda"
              onClick={defaultData}
              color="primary.3"
              backgroundColor="transparent"
            />
          </>
        )}
        {personalizedHeader && personalizedHeader}
        {hasCreate && (
          <Button
            marginLeft="8px"
            onClick={handleCreate}
            icon={faPlusCircle}
            backgroundColor="primary.3"
            text="Agregar nuevo"
            boxShadow={true}
          />
        )}
        {hasSave && (
          <Button
            marginLeft="8px"
            onClick={handleSave}
            text="Guardar"
            boxShadow={true}
            backgroundColor="gray.2"
          />
        )}
      </>
    </Container>
  )
}

export default HeaderContainer
