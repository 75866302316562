import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { notification } from '../utils/notification'
import API from '../config/API'
import { useListenerEnter } from '../hooks'
import {
  Card,
  Container,
  Input,
  Typography,
  Button,
  Loader,
  DownloadButton,
} from './'
import { SelectStyle } from './styles'

// eslint-disable-next-line complexity
function TreeFilter({
  paramsTreeId,
  adminUnitId,
  treeFilters,
  getTrees,
  setUseParams = () => {},
  user,
  loading,
  isMap = null,
  isStateColor,
  changeColorType,
}) {
  const [filters, setFilters] = useState({})
  const [textAddress, setTextAddress] = useState(``)
  const [streetData, setStreetData] = useState([])

  useListenerEnter({ enterFn: onSearch, dependencies: [filters] })

  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      setFilters({
        ...filters,
        unitId,
      })
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      setFilters({
        unitId: adminUnitId,
      })
    }
  }, [adminUnitId])

  useEffect(() => {
    if (paramsTreeId) {
      const { unitId } = user
      setFilters({
        ...filters,
        treeId: paramsTreeId,
        unitId: adminUnitId || unitId,
      })
    }
  }, [paramsTreeId])
  const handleInput = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const onSearchAddressChange = value => {
    setTextAddress(value)
    if (value.length < 2) {
      return setStreetData([])
    }
    if (value.length > 5) {
      const autocomplete = new window.google.maps.places.AutocompleteService()
      autocomplete.getPlacePredictions(
        { input: value, componentRestrictions: { country: `cl` } },
        predictions => {
          setStreetData(predictions)
        },
      )
    }
  }

  const [debounceSearch] = useDebouncedCallback(
    value => onSearchAddressChange(value),
    3000,
  )

  const onSelect = data => {
    const street = JSON.parse(data)
    setTextAddress(street.structured_formatting.main_text)
    const streetNumber = street.structured_formatting.main_text.match(/\d+/g)
    if (streetNumber) {
      setFilters({
        ...filters,
        streetName: street.structured_formatting.main_text.replace(
          ` ${streetNumber[0]}`,
          ``,
        ),
        streetNumber: streetNumber[0],
      })
      return setStreetData([])
    }
    return setFilters({
      ...filters,
      streetName: street.structured_formatting.main_text,
      streetNumber: null,
    })
  }

  const generateCsv = async () => {
    try {
      const result = await API.downloadTreeCsv(filters)
      notification({
        type: `success`,
        message: result,
      })
    } catch (error) {
      notification({ type: `error`, message: `Error al generar archivo` })
    }
  }

  function onSearch() {
    getTrees(filters)
    setUseParams(false)
  }

  if (loading) {
    return <Loader />
  }

  if (!treeFilters) {
    return null
  }
  return (
    <Card
      width="100%"
      borderColor="gray"
      borderTop="4px solid"
      borderRadius={4}
    >
      <Container
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
        margin="0px 0px 10px 0px"
      >
        <Typography type="label" fontWeight="bold" fontStyle="italic">
          Nueva búsqueda:
        </Typography>
        <Container width={isMap ? `70%` : null} alignItems="center">
          {!isMap && (
            <>
              <div>
              <DownloadButton
                href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/arboles_${user.id}.csv`}
              >
                Descargar último CSV
              </DownloadButton>
              </div>
              <div style={({marginLeft:'15px'})}>
              <DownloadButton
                href={`https://arbotagfiles.s3-sa-east-1.amazonaws.com/informes/arboles_${user.id}.xlsx`}
              >
                Descargar último Excel
              </DownloadButton>
              </div>
              <Button
                onClick={generateCsv}
                text="Generar Archivos"
                color="primary.3"
                backgroundColor="transparent"
                marginRight="10px"
              />
            </>
          )}
          {isMap && (
            <>
              <Typography
                type="label"
                fontWeight="bold"
                fontStyle="italic"
                marginRight="16px"
              >
                Seleccione tipo color:
              </Typography>
              <SelectStyle
                onChange={value => changeColorType(value)}
                placeholder="Elija una opción"
                value={isStateColor}
                width="30%"
              >
                <SelectStyle.Option value={0}>
                  Color por estado arbol
                </SelectStyle.Option>
                <SelectStyle.Option value={1}>
                  Color por tipo
                </SelectStyle.Option>
                <SelectStyle.Option value={2}>
                  Color por prioridad tarea
                </SelectStyle.Option>
              </SelectStyle>
            </>
          )}
          <Button
            onClick={onSearch}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
            marginLeft="10px"
          />
          <Button
            onClick={() => setFilters({ unitId: filters.unitId })}
            text="Limpiar Búsqueda"
            color="primary.3"
            backgroundColor="transparent"
          />
        </Container>
      </Container>
      <Container justifyContent="flex-start" marginTop="32px">
        <Input
          width="24%"
          label="ID Árbol:"
          placeholder="Escriba aqui"
          onChange={handleInput}
          value={filters.id}
          name="id"
        />
        <Input
          width="24%"
          type="address"
          label="Calle"
          value={textAddress === `` ? undefined : textAddress}
          onSearch={debounceSearch}
          options={streetData}
          name="address"
          onSelect={onSelect}
        />
        {/*TODO: EL STREETNUMBER ES STRING, Y ESTA FILTRANDO RARO*/}
        {/* treeFilters.streetName !== `` && (
          <>
            <Input
              width="24%"
              label="Desde:"
              placeholder="Escriba aqui"
              onChange={handleInput}
              value={filters.initStreetNumber}
              name="initStreetNumber"
            />
            <Input
              width="24%"
              label="Hasta:"
              placeholder="Escriba aqui"
              onChange={handleInput}
              value={filters.endStreetNumber}
              name="endStreetNumber"
            />
          </>
        ) */}
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Unidad vecinal:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="subUnitId"
          keyValue="description"
          options={treeFilters.subUnits}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.subUnitId === `` ? undefined : filters.subUnitId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Especie:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="specieId"
          keyValue="description"
          options={treeFilters.species}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.specieId === `` ? undefined : filters.specieId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Origen:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="typeOrigin"
          keyValue="description"
          options={treeFilters.typeOrigins}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.typeOrigin === `` ? undefined : filters.typeOrigin}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Clasificación:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="clasification"
          keyValue="description"
          options={treeFilters.clasifications}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={
            filters.clasification === `` ? undefined : filters.clasification
          }
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Creado por:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="createdById"
          keyValue="description"
          options={treeFilters.users}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.createdById === `` ? undefined : filters.createdById}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado árbol:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="treeStateId"
          keyValue="description"
          options={treeFilters.treeStates}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.treeStateId === `` ? undefined : filters.treeStateId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="DAP:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="dapId"
          keyValue="description"
          options={treeFilters.daps}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.dapId === `` ? undefined : filters.dapId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Estado fitosanitario:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="phytosanitaryStateId"
          keyValue="description"
          options={treeFilters.phytosanitaryStates}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={
            filters.phytosanitaryStateId === ``
              ? undefined
              : filters.phytosanitaryStateId
          }
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Fase de desarrollo:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="relativeAgeId"
          keyValue="description"
          options={treeFilters.relativeAges}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={
            filters.relativeAgeId === `` ? undefined : filters.relativeAgeId
          }
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Ubicación:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="locationId"
          keyValue="description"
          options={treeFilters.locations}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.locationId === `` ? undefined : filters.locationId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Sector"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="zoneId"
          keyValue="description"
          options={treeFilters.zones}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.zoneId === `` ? undefined : filters.zoneId}
        />
        <Input
          width="24%"
          showSearch
          type="select"
          optionFilterProp="children"
          label="Tipo:"
          placeholder="Elija una opción"
          onChange={handleInput}
          name="treeTypeId"
          keyValue="description"
          options={treeFilters.treeTypes}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={filters.treeTypeId === `` ? undefined : filters.treeTypeId}
        />
        {treeFilters.barrackSelect.length > 0 ? (
          <Input
            width="24%"
            showSearch
            type="select"
            optionFilterProp="children"
            label="Cuartel:"
            placeholder="Elija una opción"
            onChange={handleInput}
            name="barrackSelectId"
            keyValue="description"
            options={treeFilters.barrackSelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={
              filters.barrackSelectId === ``
                ? undefined
                : filters.barrackSelectId
            }
          />
        ) : null}
      </Container>
    </Card>
  )
}

const mapStateToProps = ({
  trees: { trees, loading },
  auth: { user },
  general: { adminUnitId },
}) => ({
  trees,
  user,
  loading,
  adminUnitId,
})

export default connect(
  mapStateToProps,
  null,
)(TreeFilter)
