import React, { useEffect } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { LocaleProvider } from 'antd'
import es_ES from 'antd/lib/locale-provider/es_ES'
import { getGeneralData } from './redux/actions'
import routes from './config/routes'
import { Login, Layout, Trees } from './screens'

const MainApp = ({ user, getGeneralData, units }) => {
  useEffect(() => {
    if (user) {
      getGeneralData()
    }
  }, [])
  useEffect(() => {
    if (!units) {
      getGeneralData()
    }
  }, [user])
  return user ? (
    <LocaleProvider locale={es_ES}>
      <HashRouter>
        <Layout>
          <Switch>
            <Route path="/" component={Trees} exact />
            <Route path="/arboles/:treeId" component={Trees} exact />
            {routes.map(route => {
              if (
                route.rolesIncluded &&
                !route.rolesIncluded.includes(user.roleId)
              ) {
                return null
              }
              if (route.key !== `5-1`) {
                return (
                  <Route path={route.path} component={route.component} exact />
                )
              } else if (user.unitId === 1 || user.roleId === 1) {
                return (
                  <Route path={route.path} component={route.component} exact />
                )
              }
            })}
          </Switch>
        </Layout>
      </HashRouter>
    </LocaleProvider>
  ) : (
    <Login />
  )
}
const mapStateToProps = ({ auth, general: { units } }) => ({
  ...auth,
  units,
})

const mapDispatchToProps = {
  getGeneralData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainApp)
