import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { getClients } from '../../redux/actions'
import {
  MainContainer,
  Container,
  Card,
  Table,
  Popover,
  Button,
  Typography,
  Modal,
  Loader,
} from '../../components'
import Form from './Form'

function Managment({ getClients, clients, loading }) {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [entityWork, setEntityWork] = useState(null)

  useEffect(() => {
    getClients()
  }, [])

  const columns = [
    {
      title: `Nombre`,
      dataIndex: `name`,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: `Rut (empresa)`,
      dataIndex: `rut`,
      sorter: (a, b) => a.rut.localeCompare(b.rut),
    },
    { title: `Habilitado`, dataIndex: `status` },
    { title: `Posee NFC`, dataIndex: `NFC` },
    {
      title: `Acción`,
      key: `action`,
      dataIndex: `action`,
      render: (text, record) => (
        <Popover
          title="Acción"
          icon={faEllipsisV}
          trigger="click"
          color="gray.2"
          content={
            <Container flexDirection="column">
              <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Editar"
                icon={faEdit}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleModal(true)
                }}
              />
              {/* <Button
                backgroundColor="transparent"
                color="primary.3"
                text="Eliminar"
                icon={faTrashAlt}
                onClick={() => {
                  setEntityWork(record)
                  setVisibleDeleteModal(true)
                }}
              /> */}
            </Container>
          }
        />
      ),
    },
  ]

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {visibleModal && (
        <Form
          data={entityWork}
          onCancel={() => {
            setEntityWork(null)
            setVisibleModal(false)
          }}
          onAccept={() => {
            setVisibleModal(false)
          }}
        />
      )}
      {visibleDeleteModal && (
        <Modal
          onCancel={() => {
            setVisibleDeleteModal(false)
          }}
          title="Eliminar cliente"
          onAccept={() => console.log(`eliminar cliente`)}
          visible={true}
        >
          <Typography>
            {`¿Está seguro que desea eliminar el cliente ${entityWork.name}?`}
          </Typography>
        </Modal>
      )}
      {clients.length > 0 && (
        <MainContainer
          title="Clientes"
          justifyContent="space-around"
          hasCreate={true}
          handleCreate={() => {
            setVisibleModal(true)
          }}
        >
          <Table
            marginTop="16px"
            columns={columns}
            dataSource={clients.map(client => {
              return {
                status: client.isActive ? `Si` : `No`,
                NFC: client.hasNFC ? `Si` : `No`,
                ...client,
              }
            })}
            locale={{
              emptyText: `No existen clientes`,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: [`10`, `20`, `30`, `40`],
            }}
          />
        </MainContainer>
      )}
    </>
  )
}

const mapStateToProps = ({ clients: { clients, loading } }) => ({
  clients,
  loading,
})

const mapDispatchToProps = { getClients }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Managment)
