import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { faHistory, faImage } from '@fortawesome/free-solid-svg-icons'

import {
  Modal,
  Container,
  Typography,
  Icon,
  Input,
  Button,
} from '../../components'
import { statusPaidOTEnum } from '../../utils/enums'
import { notification } from '../../utils/notification'
import API from '../../config/API'
import { currencyFormat } from '../../utils'

function FieldsWrapper({ title = ``, content }) {
  return (
    <Container flexDirection="column" width="48%" marginBottom="16px">
      <Typography fontWeight="bold">{title}</Typography>
      <Typography backgroundColor="gray.1" width="100%" padding="4px 8px">
        {content}
      </Typography>
    </Container>
  )
}

function ShowOrderTask({
  handleCloseShowOrderTask,
  task,
  setState = () => {},
  users,
}) {
  const {
    id: taskId,
    code,
    orderTask: { description: taskTypeName = `` },
    timeLimit,
    createdAt,
    price,
    paymentDateCollect,
    paymentDateReceive,
    paymentStatus,
    address,
    changeStatuses,
    generalState,
    location,
    phytosanitaryState,
    subUnit,
    treeAge,
    treeDap,
    treeHeight,
    tree,
    qtyTree,
    dateCreated,
    assignedBy,
  } = task
  const [userAssignId, setUserAssignId] = useState(
    assignedBy ? assignedBy.id : ``,
  )

  const handleInput = (field, value) => {
    setUserAssignId(value)
  }

  const handleRenderText = text => {
    if (text) {
      return text
    } else {
      return `--`
    }
  }

  const handleRenderDate = date => {
    if (date) {
      return moment(date).format(`DD/MM/YYYY`)
    } else {
      return `--`
    }
  }

  const handleShowImg = img => {
    setState(prevState => ({
      ...prevState,
      img,
      visibleShowOrderTaskModal: false,
      showImg: true,
    }))
  }

  const handleDaysAllow = () => {
    const statusFinish = changeStatuses.find(el => el.statusId === 5)
    if (statusFinish) return false

    const remainingDays = timeLimit + moment(dateCreated).diff(moment(), `days`)
    if (remainingDays >= 4 && remainingDays <= 6) {
      return { days: remainingDays, background: `#EFFE00`, color: `#000000` }
    }
    if (remainingDays >= 7) {
      return { days: remainingDays, background: `#4DFF00`, color: `#000000` }
    }

    return { days: remainingDays, background: `#FF0000`, color: `#FFFFFF` }
  }

  const handleCheckStatus = () => {
    const statusDone = changeStatuses.find(el => el.statusId === 2)
    return !statusDone
  }

  const handleAssignTaskRequest = async () => {
    try {
      await API.assignTaskRequest({ taskId, userAssignId })
      notification({ type: `success`, message: `OT asignada exitosamente` })
      setState(prevState => ({ ...prevState, isUpdated: true }))
    } catch (error) {
      notification({
        type: `error`,
        message: `Ha ocurrido un error, por favor intente nuevamente`,
      })
    }
  }

  return (
    <Modal
      onAccept={handleCloseShowOrderTask}
      onCancel={handleCloseShowOrderTask}
      visible={true}
      title={`OT #${code}`}
      showCancel={true}
    >
      <Container width="100%" justifyContent="space-between">
        <FieldsWrapper title="Código" content={`#${code}`} />
        <FieldsWrapper title="Tipo de tarea" content={taskTypeName} />
        <FieldsWrapper
          title="Plazo"
          content={
            handleDaysAllow() ? (
              <Container
                style={{
                  backgroundColor: handleDaysAllow().background,
                  color: handleDaysAllow().color,
                  padding: `0px 8px`,
                  borderRadius: 50,
                  width: 100,
                  justifyContent: `center`,
                }}
              >{`${handleDaysAllow().days} Día(s)`}</Container>
            ) : (
              `-`
            )
          }
        />
        <FieldsWrapper title="Fecha de creación" content={createdAt} />
        <FieldsWrapper
          title="Sub unidad"
          content={handleRenderText(subUnit.description)}
        />
        <FieldsWrapper title="Dirección" content={handleRenderText(address)} />
        <FieldsWrapper title="Edad" content={handleRenderText(treeAge)} />
        <FieldsWrapper title="D.A.P" content={handleRenderText(treeDap)} />
        <FieldsWrapper title="Altura" content={handleRenderText(treeHeight)} />
        <FieldsWrapper
          title="Número de árboles"
          content={handleRenderText(qtyTree)}
        />
        <FieldsWrapper
          title="Estado general"
          content={handleRenderText(generalState)}
        />
        <FieldsWrapper
          title="Estado fitosanitario"
          content={handleRenderText(phytosanitaryState.description)}
        />
        <FieldsWrapper
          title="Ubicación"
          content={handleRenderText(location.description)}
        />
        <FieldsWrapper
          title="Especie"
          content={
            <>
              {handleRenderText(tree.description)}
              {` `}
              <i>({handleRenderText(tree.specie)})</i>
            </>
          }
        />
        <FieldsWrapper
          title="Estado OT"
          content={changeStatuses.map(
            ({
              createdAt,
              status,
              observation,
              img,
              user: { firstName, lastName },
            }) => (
              <>
                <Container>
                  <Icon icon={faHistory} marginRight="8px" color="primary.3" />
                  {`${moment(createdAt).format(`lll`)} -
                ${handleRenderText(status.description)}`}
                </Container>
                <Container>
                  <b style={{ marginRight: 8 }}>Usuario:</b>
                  {`${firstName} ${lastName}`}
                </Container>
                <Container>
                  <b style={{ marginRight: 8 }}>Observación:</b>
                  {handleRenderText(observation)}
                </Container>
                {img && (
                  <Typography
                    width="100%"
                    color="primary.1"
                    display="flex"
                    style={{ alignItems: `center`, cursor: `pointer` }}
                    onClick={() => handleShowImg(img)}
                  >
                    <Icon icon={faImage} marginRight="8px" color="primary.3" />
                    Ver imagen adjunta
                  </Typography>
                )}
              </>
            ),
          )}
        />
        <FieldsWrapper title="Precio" content={currencyFormat(price)} />
        <FieldsWrapper
          title="Estado de pago"
          content={handleRenderText(statusPaidOTEnum[paymentStatus])}
        />
        <FieldsWrapper
          title="Fecha de pago"
          content={handleRenderDate(paymentDateCollect)}
        />
        <FieldsWrapper
          title="Fecha de pago recibido"
          content={handleRenderDate(paymentDateReceive)}
        />
        {assignedBy && (
          <FieldsWrapper
            title="Asignado a:"
            content={handleRenderText(
              `${assignedBy.firstName} ${assignedBy.lastName}`,
            )}
          />
        )}
        {handleCheckStatus() && (
          <Container flexDirection="column" width="48%" marginBottom="16px">
            <Input
              width="100%"
              showSearch
              type="select"
              optionFilterProp="children"
              label="Seleccione Jefe de Cuadrilla a asignar:"
              placeholder="Elija una opción"
              onChange={handleInput}
              options={users}
              name="userAssignId"
              keyValue="description"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={userAssignId === `` ? undefined : userAssignId}
            />
            <Button
              onClick={handleAssignTaskRequest}
              text="Asignar OT"
              boxShadow={true}
              backgroundColor="gray.2"
              marginTop="8px"
              marginLeft="5px"
            />
          </Container>
        )}
      </Container>
    </Modal>
  )
}

const mapStateToProps = () => {}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowOrderTask)
