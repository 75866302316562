import API from '../../config/API'
import { notification } from '../../utils'
import {
  GET_MAP_TREE_FILTERS,
  GET_MAP_TREES,
  LOADING_MAP_TREES,
  ERROR_MAP_TREES,
  ERROR_MESSAGE,
} from './types'

export const getMapTreeFilters = unitId => async dispatch => {
  try {
    const result = await API.getTreeFilters(unitId)
    dispatch({
      type: GET_MAP_TREE_FILTERS,
      treeFilters: result,
    })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const getMapTrees = filters => async dispatch => {
  dispatch({ type: LOADING_MAP_TREES })
  try {
    const result = await API.getMapFilteredTrees(filters)
    if (result.length > 0) {
      dispatch({
        type: GET_MAP_TREES,
        trees: result,
      })
    } else {
      notification({ type: `error`, message: `No se han encontrado árboles` })
      dispatch({ type: ERROR_MAP_TREES })
    }
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ERROR_MAP_TREES })
  }
}
