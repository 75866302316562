import React from 'react'
import styled from 'styled-components'
import { color, position } from 'styled-system'
import { Modal } from 'antd'
import Button from './Button'
import Container from './Container'

const ModalStyled = styled(Modal)`
  ${position}
  .ant-modal-header {
    ${color}
    .ant-modal-title {
      ${color}
    }
  }
`

ModalStyled.defaultProps = {
  padding: 0,
  backgroundColor: `primary.3`,
  color: `gray.0`,
  top: `20px`,
  width: `80%`,
}

const ModalCustom = ({
  children,
  onAccept,
  onCancel,
  onDelete,
  visible,
  title,
  acceptText = `Aceptar`,
  showCancel = true,
  showDelete = false,
}) => {
  return (
    <ModalStyled
      title={title}
      visible={visible}
      centered
      onCancel={onCancel}
      footer={
        <Container justifyContent="flex-end">
          {showDelete && (
            <Button onClick={onDelete} text="Eliminar" backgroundColor="red" />
          )}
          {showCancel && (
            <Button
              onClick={onCancel}
              text="Cancelar"
              backgroundColor="transparent"
              color="secondary.2"
            />
          )}
          {onAccept && (
            <Button
              onClick={onAccept}
              text={acceptText}
              backgroundColor="primary.3"
            />
          )}
        </Container>
      }
    >
      <Container maxHeight={600} overflow="auto">
        {children}
      </Container>
    </ModalStyled>
  )
}

export default ModalCustom
