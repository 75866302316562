import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { capitalizeFirstLetter } from '../utils'
import Typography from './Typography'

export default function Timer() {
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(moment())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Typography fontStyle="italic" fontWeight={700} fontSize="3" color="white ">
      {capitalizeFirstLetter(
        moment(timer).format(`dddd DD [ de ] MMMM YYYY, HH:mm:ss  [hrs.]`),
      )}
    </Typography>
  )
}
