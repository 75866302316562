import {
  GET_MAP_TREE_FILTERS,
  GET_MAP_TREES,
  LOADING_MAP_TREES,
  ERROR_MAP_TREES,
} from '../actions/types'

const initialState = {
  loading: false,
  trees: [],
  treeFilters: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    //MAP TREES ACTIONS
    case GET_MAP_TREE_FILTERS:
      return {
        ...state,
        treeFilters: action.treeFilters,
      }
    case LOADING_MAP_TREES:
      return {
        ...state,
        loading: true,
        trees: [],
      }
    case ERROR_MAP_TREES:
      return {
        ...state,
        loading: false,
      }
    case GET_MAP_TREES:
      return {
        ...state,
        loading: false,
        trees: action.trees,
      }
    default:
      return state
  }
}
