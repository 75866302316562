//AUTH
export const SUCCESS_LOGIN = `SUCCES_LOGIN`
export const ERROR_LOGIN = `ERROR_LOGIN`
export const LOADING_LOGIN = `LOADING_LOGIN`
export const LOG_OUT = `LOG_OUT`

//GENERAL
export const GENERAL_LOADING = `GENERAL_LOADING`
export const GET_GENERAL_DATA = `GET_GENERAL_DATA`
export const GET_GENERAL_DATA_ERROR = `GET_GENERAL_DATA_ERROR`
export const SET_UNIT_ID = `SET_UNIT_ID`
export const ERROR_MESSAGE = `Ha ocurrido un error`

//ASIGN/REVIEW TASKS
export const GET_ASIGN_TASKS = `GET_ASIGN_TASKS`
export const LOADING_ASIGN_TASKS = `LOADING_ASIGN_TASKS`
export const ERROR_ASIGN_TASKS = `ERROR_ASIGN_TASKS`
export const GET_WORKERS = `GET_WORKERS`
export const GET_INSPECTORS = `GET_INSPECTORS`

//TASKS
export const GET_TASK_FILTERS = `GET_TASK_FILTERS`
export const GET_TASKS = `GET_TASKS`
export const LOADING_TASKS = `LOADING_TASKS`
export const ERROR_TASKS = `ERROR_TASKS`
export const GET_FILTERED_TASKS = `GET_FILTERED_TASKS`
export const SUCCESS_ASSIGN_TASK = `SUCCESS_ASSIGN_TASK`
export const REVIEW_TASK = `REVIEW_TASK`
export const CLOSE_TASK = `CLOSE_TASK`
export const DELETE_TASK = `DELETE_TASK`

//REQUESTS
export const GET_REQUEST_FILTERS = `GET_REQUEST_FILTERS`
export const GET_REQUESTS = `GET_REQUESTS`
export const LOADING_REQUESTS = `LOADING_REQUESTS`
export const ERROR_REQUESTS = `ERROR_REQUESTS`
export const SUCCESS_ASSIGN_REQUEST = `SUCCESS_ASSIGN_REQUEST`
export const SUCCESS_REJECT_REQUEST = `SUCCESS_REJECT_REQUEST`
export const SUCCESS_DELETE_REQUEST = `SUCCESS_DELETE_REQUEST`
export const SUCCESS_APPROVE_REQUEST = `SUCCESS_APPROVE_REQUEST`
export const GET_TASK_REPORT_FILTERS = `GET_TASK_REPORT_FILTERS`
export const GET_TASKS_BY_SPECIES = `GET_TASKS_BY_SPECIES`
export const LOADING_TASKS_BY_SPECIES = `LOADING_TASKS_BY_SPECIES`
export const LOADING_TASKS_BY_ORIGIN = `LOADING_TASKS_BY_ORIGIN`
export const ERROR_TASKS_BY_SPECIES = `ERROR_TASKS_BY_SPECIES`
export const GET_TASKS_BY_TYPE = `GET_TASKS_BY_TYPE`
export const GET_TASKS_BY_ORIGIN = `GET_TASKS_BY_ORIGIN`
export const LOADING_TASKS_BY_TYPE = `LOADING_TASKS_BY_TYPE`
export const ERROR_TASKS_BY_TYPE = `ERROR_TASKS_BY_TYPE`
export const ERROR_TASKS_BY_ORIGIN = `ERROR_TASKS_BY_ORIGIN`

//TREES
export const GET_TREE_FILTERS = `GET_TREE_FILTERS`
export const GET_TREE_FIELDS = `GET_TREE_FIELDS`
export const GET_TREE_BY_ID = `GET_TREE_BY_ID`
export const SUCCESS_EDIT = `SUCCESS_EDIT`
export const SUCCESS_DELETE = `SUCCESS_DELETE`
export const GET_TREES = `GET_TREES`
export const TREES_LOADING = `TREES_LOADING`
export const ERROR_TREES = `ERROR_TREES`
export const GET_TREES_BY_UNIT = `GET_TREES_BY_UNIT`
export const ERROR_TREES_BY_UNIT = `ERROR_TREES_BY_UNIT`
export const LOAD_TREES_BY_UNIT = `LOAD_TREES_BY_UNIT`

//MAP
export const GET_MAP_TREE_FILTERS = `GET_MAP_TREE_FILTERS`
export const GET_MAP_TREES = `GET_MAP_TREES`
export const LOADING_MAP_TREES = `LOADING_MAP_TREES`
export const ERROR_MAP_TREES = `ERROR_MAP_TREES`

//USERS
export const USERS_LOADING = `USERS_LOADING`
export const GET_USERS = `GET_USERS`
export const GET_USERS_ERROR = `GET_USERS_ERROR`
export const ADD_USER = `ADD_USER`
export const ADD_USER_ERROR = `ADD_USER_ERROR`
export const EDIT_USER = `EDIT_USER`
export const EDIT_USER_ERROR = `EDIT_USER_ERROR`
export const REMOVE_USER = `REMOVE_USER`
export const REMOVE_USER_ERROR = `REMOVE_USER_ERROR`

//GENERIC FIELDS
export const GENERIC_FIELDS_LOADING = `GENERIC_FIELDS_LOADING`
export const GET_GENERIC_FIELDS = `GET_GENERIC_FIELDS`
export const GET_GENERIC_FIELDS_ERROR = `GET_GENERIC_FIELDS_ERROR`
export const ADD_GENERIC_FIELD = `ADD_GENERIC_FIELD`
export const ADD_GENERIC_FIELD_ERROR = `ADD_GENERIC_FIELD_ERROR`
export const EDIT_GENERIC_FIELD = `EDIT_GENERIC_FIELD`
export const EDIT_GENERIC_FIELD_ERROR = `EDIT_GENERIC_FIELD_ERROR`
export const REMOVE_GENERIC_FIELD = `REMOVE_GENERIC_FIELD`
export const REMOVE_GENERIC_FIELD_ERROR = `REMOVE_GENERIC_FIELD_ERROR`

//CLIENTS
export const CLIENTS_LOADING = `CLIENTS_LOADING`
export const CLIENTS_ERROR = `CLIENTS_ERROR`
export const GET_CLIENTS = `GET_CLIENTS`
export const ADD_CLIENT = `ADD_CLIENT`
export const CLIENTS_SAVE_CHANGES = `CLIENTS_SAVE_CHANGES`
export const EDIT_CLIENT = `EDIT_CLIENT`
export const DELETE_CLIENT = `DELETE_CLIENT`
export const SET_CURRENT_UNIT = `SET_CURRENT_UNIT`
export const GET_FIELDS = `GET_FIELDS`
export const SET_FIELD_VALUES = `SET_FIELD_VALUES`
export const GET_SPECIES = `GET_SPECIES`
export const SET_SPECIE_VALUES = `SET_SPECIE_VALUES`
export const GET_DAPS = `GET_DAPS`
export const SET_DAP_VALUES = `SET_DAP_VALUES`
export const GET_TASK_TYPES = `GET_TASK_TYPES`
export const SET_TASK_TYPE_VALUES = `SET_TASK_TYPE_VALUES`
export const GET_REQUEST_TYPES = `GET_REQUEST_TYPES`
export const SET_REQUEST_TYPE_VALUES = `SET_REQUEST_TYPE_VALUES`
export const SETUP_CLIENT_LOADING = `SETUP_CLIENT_LOADING`
export const SETUP_CLIENT_SUCCESS = `SETUP_CLIENT_SUCCESS`
export const SETUP_CLIENT_ERROR = `SETUP_CLIENT_ERROR`

//SPECIES
export const SPECIES_LOADING = `SPECIES_LOADING`
export const SPECIES_ERROR = `SPECIES_ERROR`
export const SPECIES_SAVE_CHANGES = `SPECIES_SAVE_CHANGES`
export const EDIT_SPECIE = `EDIT_SPECIE`
export const SETUP_SPECIE_LOADING = `SETUP_SPECIE_LOADING`
export const SETUP_SPECIE_SUCCESS = `SETUP_SPECIE_SUCCESS`
export const SETUP_SPECIE_ERROR = `SETUP_SPECIE_ERROR`
export const GET_SPECIE_FIELDS = `GET_SPECIE_FIELDS`
export const GET_SPECIE_BY_ID = `GET_SPECIE_BY_ID`

//SUBUNITS
export const GET_SUB_UNITS = `GET_SUB_UNITS`
export const ADD_SUB_UNITS = `ADD_SUB_UNITS`
export const EDIT_SUB_UNITS = `EDIT_SUB_UNITS`

// OT's
export const GET_OT_ALL = `GET_OT_ALL`
export const OT_LOADING = `OT_LOADING`
export const OT_IS_LOADING = `OT_IS_LOADING`
export const GET_OT_ERROR = `GET_OT_ERROR`
export const SUCCESS_EDIT_PAY_OT = `SUCCESS_EDIT_PAY_OT`
export const GET_OT_REPORT_FILTERS = `GET_OT_REPORT_FILTERS`
export const GET_OT_WITH_FILTER = `GET_OT_WITH_FILTER`
export const GET_COUNT_OT = `GET_COUNT_OT`
export const GET_OT_BILLING = `GET_OT_BILLING`
export const GET_OT_WITH_FINES = `GET_OT_WITH_FINES`
export const GET_VALUE_UTM = `GET_VALUE_UTM`
