import React, { useState } from 'react'
import { DatePicker } from 'antd'
import { connect } from 'react-redux'
import API from '../../config/API'
import { getTaskReportFilters } from '../../redux/actions'
import {
  Container,
  Card,
  Typography,
  Table,
  Loader,
  DownloadButton,
} from '../../components'
const { MonthPicker } = DatePicker

function TaskReports() {
  const [filterMonth, setFilterMonth] = useState(``)
  const [pdfUrl, setPdfUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(``)
  const [tasks, setTasks] = useState([])
  const getData = async date => {
    setIsLoading(true)
    try {
      setFilterMonth(`${date.format(`MMMM`)}, ${date.format(`YYYY`)}`)
      const { tasks, totalEjecutadas, pdfUrl } = await API.getTaskReportByMonth(
        date.format(),
      )
      setPdfUrl(pdfUrl)
      setTasks(tasks)
      setTotal(totalEjecutadas)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const columns = [
    { title: `Labores Mantención Mensual`, dataIndex: `taskType` },
    { title: `Cantidad`, dataIndex: `amount`, align: `center` },
    { title: `%`, dataIndex: `percentage`, align: `center` },
  ]

  return (
    <>
      <Container
        marginTop="10px"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography type="title">Labores Mantención Mensual</Typography>
        {pdfUrl && <DownloadButton href={pdfUrl}>Descargar PDF</DownloadButton>}
        <MonthPicker
          format="MMMM, YYYY"
          onChange={date => getData(date)}
          placeholder="Selecciona Mes"
        />
      </Container>
      {isLoading ? (
        <Container
          marginTop="10px"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Loader />
        </Container>
      ) : (
        <Card width="100%" marginTop="3" borderColor="primary.3">
          <Table
            marginTop="16px"
            columns={columns}
            size="small"
            dataSource={tasks}
            bordered={true}
            locale={{
              emptyText: `No existen tareas con los parametros ingresados`,
            }}
            pagination={false}
          />
          {filterMonth && (
            <Typography marginTop="4" type="subtitle" color="secondary.2">
              {`*Total de labores según bases de licitación, mes de ${filterMonth}: `}
              <Typography
                fontWeight="500"
                fontSize="4"
              >{`${total} labores`}</Typography>
            </Typography>
          )}
        </Card>
      )}
    </>
  )
}

const mapStateToProps = ({
  tasks: {
    tasksBySpecies,
    loadingBySpecies,
    tasksByType,
    loadingByType,
    taskReportFilters,
  },
  auth: { user },
  general: { adminUnitId },
}) => {
  return {
    loadingBySpecies,
    tasksBySpecies,
    loadingByType,
    tasksByType,
    taskReportFilters,
    user,
    adminUnitId,
  }
}

const mapDispatchToProps = {
  getTaskReportFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskReports)
