import {
  GET_OT_ALL,
  OT_LOADING,
  GET_OT_ERROR,
  SUCCESS_EDIT_PAY_OT,
  GET_OT_REPORT_FILTERS,
  GET_OT_WITH_FILTER,
  GET_COUNT_OT,
  GET_OT_BILLING,
  OT_IS_LOADING,
  GET_OT_WITH_FINES,
  GET_VALUE_UTM,
} from '../actions/types'

const initialState = {
  loading: false,
  error: null,
  orderTasks: [],
  orderTaskFilters: null,
  filtersSelected: null,
  tasksCountByType: null,
  tasksCountByUser: null,
  orderTaskBilling: [],
  orderTaskWithFines: [],
  count: 0,
  valueUTM: 0,
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OT_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case OT_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case GET_OT_ALL: {
      const { orderTasks, count } = action
      return {
        ...state,
        orderTasks,
        count,
        filtersSelected: null,
        loading: false,
        isLoading: false,
      }
    }
    case SUCCESS_EDIT_PAY_OT: {
      const { orderTasks } = action
      return {
        ...state,
        orderTasks,
        loading: false,
      }
    }
    case GET_OT_REPORT_FILTERS: {
      const { orderTaskFilters } = action
      return {
        ...state,
        orderTaskFilters,
        loading: false,
      }
    }
    case GET_OT_WITH_FILTER: {
      const { orderTasks, filters } = action
      return {
        ...state,
        orderTasks,
        filtersSelected: filters,
        loading: false,
      }
    }
    case GET_COUNT_OT: {
      const { tasksCountByType, tasksCountByUser } = action
      return {
        ...state,
        tasksCountByType,
        tasksCountByUser,
        loading: false,
      }
    }
    case GET_OT_BILLING: {
      const { orderTaskBilling } = action
      return {
        ...state,
        orderTaskBilling,
        loading: false,
      }
    }
    case GET_OT_WITH_FINES: {
      const { orderTaskWithFines } = action
      return {
        ...state,
        orderTaskWithFines,
        loading: false,
      }
    }
    case GET_VALUE_UTM: {
      const { valueUTM } = action
      return {
        ...state,
        valueUTM,
      }
    }
    case GET_OT_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    default:
      return state
  }
}
