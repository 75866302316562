import API from '../../config/API'
import { notification } from '../../utils'
import {
  LOADING_TASKS,
  ERROR_TASKS,
  GET_TASKS,
  GET_TASK_FILTERS,
  ERROR_MESSAGE,
  SUCCESS_ASSIGN_TASK,
  GET_TASK_REPORT_FILTERS,
  LOADING_TASKS_BY_SPECIES,
  ERROR_TASKS_BY_SPECIES,
  GET_TASKS_BY_SPECIES,
  LOADING_TASKS_BY_TYPE,
  ERROR_TASKS_BY_TYPE,
  GET_TASKS_BY_TYPE,
  REVIEW_TASK,
  CLOSE_TASK,
  DELETE_TASK,
  GET_TASKS_BY_ORIGIN,
  LOADING_TASKS_BY_ORIGIN,
  ERROR_TASKS_BY_ORIGIN,
} from './types'

export const getTaskFilters = unitId => async dispatch => {
  try {
    const { amounts, ...taskFilters } = await API.getTaskFilters(unitId)
    dispatch({
      type: GET_TASK_FILTERS,
      taskFilters,
      amounts,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
  }
}

export const getTasks = filters => async dispatch => {
  dispatch({ type: LOADING_TASKS })
  try {
    const { count, rows } = await API.getFilteredTasks(filters)
    const tasksById = {}
    const tasks = rows.map(task => {
      tasksById[task.id] = task
      return task.id
    })
    if (tasks.length > 0) {
      dispatch({
        type: GET_TASKS,
        tasks,
        tasksById,
        count,
      })
    } else {
      notification({ type: `error`, message: `No se han encontrado tareas` })
      dispatch({ type: ERROR_TASKS })
    }
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ERROR_TASKS })
  }
}

export const assignTask = (
  taskId,
  assignedById,
  assignedToId,
  treeId,
) => async dispatch => {
  try {
    const result = await API.assignTask(
      taskId,
      assignedById,
      assignedToId,
      treeId,
    )
    notification({ type: `success`, message: result })
    dispatch({
      type: SUCCESS_ASSIGN_TASK,
      taskId,
    })
  } catch (error) {
    notification({ type: `error`, message: error })
  }
}

export const getTaskReportFilters = unitId => async dispatch => {
  try {
    const { 
      taskTypes, 
      species, 
      subUnits,
      origins,
      typeOrigins,
      treeTypes,
      interactionsWithUrbanElements,
      interferences,
      treeStates
    } = await API.getTaskReportFilters(
      unitId,
    )
    const taskReportFilters = { 
      taskTypes, 
      species, 
      subUnits,
      origins,
      typeOrigins,
      treeTypes,
      interactionsWithUrbanElements,
      interferences,
      treeStates
    }
    dispatch({
      type: GET_TASK_REPORT_FILTERS,
      taskReportFilters,
    })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
  }
}

export const getTasksBySpecies = filters => async dispatch => {
  dispatch({ type: LOADING_TASKS_BY_SPECIES })
  try {
    const tasksBySpecies = await API.getTasksBySpecies(filters)
    if (tasksBySpecies.length > 0) {
      dispatch({
        type: GET_TASKS_BY_SPECIES,
        tasksBySpecies,
      })
    } else {
      notification({ type: `error`, message: `No se han encontrado tareas` })
      dispatch({ type: ERROR_TASKS })
    }
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ERROR_TASKS_BY_SPECIES })
  }
}

export const getTasksByType = filters => async dispatch => {
  dispatch({ type: LOADING_TASKS_BY_TYPE })
  try {
    const tasksByType = await API.getTasksByType(filters)
    if (tasksByType.length > 0) {
      dispatch({
        type: GET_TASKS_BY_TYPE,
        tasksByType,
      })
    } else {
      notification({ type: `error`, message: `No se han encontrado tareas` })
      dispatch({ type: ERROR_TASKS })
    }
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ERROR_TASKS_BY_TYPE })
  }
}


export const getTasksByOrigin = filters => async dispatch => {
  dispatch({ type: LOADING_TASKS_BY_ORIGIN })
  try {
    const tasksByType = await API.getTasksByOrigin(filters)
    if (tasksByType.length > 0) {
      dispatch({
        type: GET_TASKS_BY_ORIGIN,
        tasksByType,
      })
    } else {
      notification({ type: `error`, message: `No se han encontrado tareas` })
      dispatch({ type: ERROR_TASKS })
    }
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ERROR_TASKS_BY_ORIGIN })
  }
}

export const reviewTask = (taskId, task) => async dispatch => {
  try {
    await API.editTask(taskId, task)
    notification({ type: `success`, message: `Tarea revisada con éxito` })
    dispatch({ type: REVIEW_TASK, taskId })
  } catch (error) {
    notification({
      type: `error`,
      message: `Error al revisar la tarea, intente nuevamente`,
    })
  }
}

export const closeTask = (taskId, task) => async dispatch => {
  try {
    await API.editTask(taskId, task)
    notification({ type: `success`, message: `Tarea cerrada con éxito` })
    dispatch({ type: CLOSE_TASK, taskId })
  } catch (error) {
    notification({
      type: `error`,
      message: `Error al cerrar la tarea, intente nuevamente`,
    })
  }
}

export const deleteTask = (taskId, task) => async dispatch => {
  try {
    await API.deleteTask(taskId, task)
    notification({ type: `success`, message: `Tarea eliminada con éxito` })
    dispatch({ type: DELETE_TASK, taskId })
  } catch (error) {
    notification({
      type: `error`,
      message: `Error al eliminar la tarea, intente nuevamente`,
    })
  }
}
