import {
  GENERIC_FIELDS_LOADING,
  GET_GENERIC_FIELDS,
  GET_GENERIC_FIELDS_ERROR,
  ADD_GENERIC_FIELD,
  ADD_GENERIC_FIELD_ERROR,
  EDIT_GENERIC_FIELD,
  EDIT_GENERIC_FIELD_ERROR,
  REMOVE_GENERIC_FIELD,
  REMOVE_GENERIC_FIELD_ERROR,
} from '../actions/types'

const initialState = {
  loading: false,
  error: null,
  genericFields: [],
  genericFieldsById: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GENERIC_FIELDS_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_GENERIC_FIELDS: {
      const { genericFields, genericFieldsById } = action
      return {
        ...state,
        genericFields,
        genericFieldsById,
        loading: false,
      }
    }
    case GET_GENERIC_FIELDS_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case ADD_GENERIC_FIELD: {
      const { field } = action
      return {
        ...state,
        genericFields: [...state.genericFields, field.id],
        genericFieldsById: { ...state.genericFieldsById, [field.id]: field },
        loading: false,
      }
    }
    case ADD_GENERIC_FIELD_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case EDIT_GENERIC_FIELD: {
      const { id, type, unitsToBelong, ...fields } = action
      return {
        ...state,
        genericFieldsById: {
          ...state.genericFieldsById,
          [id]: {
            id,
            unitConfig: { belongsTo: unitsToBelong },
            ...fields,
          },
        },
        loading: false,
      }
    }
    case EDIT_GENERIC_FIELD_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case REMOVE_GENERIC_FIELD: {
      const { id } = action
      const { genericFields: prevFields, genericFieldsById: prevById } = state
      const genericFields = prevFields.filter(item => item !== id)
      const genericFieldsById = { ...prevById }
      delete genericFieldsById[id]
      return {
        ...state,
        genericFields,
        genericFieldsById,
        loading: false,
      }
    }
    case REMOVE_GENERIC_FIELD_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        loading: false,
      }
    }
    default:
      return state
  }
}
