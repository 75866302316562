import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Input } from '../../components'
import { createSubUnit, editSubUnit } from '../../redux/actions'

const getInitialState = {
  description: ``,
  unitId: ``,
}

const Form = ({ data, units, createSubUnit, editSubUnit, onCancel }) => {
  const [fields, setFields] = useState(getInitialState)
  useEffect(() => {
    if (data) {
      setFields({
        ...fields,
        ...data,
      })
    }
  }, [])

  useEffect(() => {
    setFields({
      ...fields,
      ...data,
    })
  }, [data])

  const onInputChange = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }

  const onAccept = () => {
    if (data) {
      const { id } = data
      editSubUnit(id, fields)
    } else {
      createSubUnit(fields)
    }
    onCancel()
  }

  return (
    <Modal
      onAccept={onAccept}
      onCancel={onCancel}
      visible={true}
      title="Creación o edición de sub unidad"
    >
      <Input
        width="48%"
        label="Ingrese nombre:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.description}
        name="description"
      />
      <Input
        width="48%"
        showSearch
        type="select"
        optionFilterProp="children"
        label="Selecciona Unidad:"
        placeholder="Elija una opción"
        onChange={onInputChange}
        name="unitId"
        keyValue="name"
        options={units}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={fields.unitId === `` ? undefined : fields.unitId}
      />
    </Modal>
  )
}

const mapStateToProps = ({ general: { units } }) => ({ units })

const mapDispatchToProps = { createSubUnit, editSubUnit }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
