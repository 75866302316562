import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { getTreeFields, getTreeById, modifyTree } from '../../redux/actions'
import { Modal, Container, Input } from '../../components'

const Form = ({
  data,
  userId,
  roleId,
  modifyTree,
  onCancel,
  getTreeFields,
  getTreeById,
  treeFields,
  editableTree,
  adminUnitId,
  getTrees,
}) => {
  const [fields, setFields] = useState({})
  const [values, setValues] = useState({})
  const [streetData, setStreetData] = useState([])
  const [textAddress, setTextAddress] = useState(``)
  const [addressData, setAddressData] = useState(null)
  const [longitude, setLongitude] = useState(data.longitude)
  const [latitude, setLatitude] = useState(data.latitude)
  const [latLng, setLatLng] = useState(`${data.latitude},${data.longitude}`)

  useEffect(() => {
    const [latitude, longitude] = latLng.split(`,`)
    setLatitude(latitude)
    setLongitude(longitude)
  }, [latLng])
  useEffect(() => {
    const { unit, id } = data
    getTreeFields(unit.id || adminUnitId, userId)
    getTreeById(id)
    setTextAddress(`${data.streetName} ${data.streetNumber}`)
  }, [])

  useEffect(() => {
    if (editableTree) {
      setFields(editableTree.treeFields)
      setValues(editableTree.treeValues)
    }
  }, [editableTree])

  const onSelectChange = (field, valueName, value) => {
    const obj = JSON.parse(value)
    setFields({
      ...fields,
      [field]: obj.id,
    })
    setValues({
      ...values,
      [valueName]: obj.description,
    })
  }

  const onInputChange = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }

  const onSearchAddressChange = value => {
    setTextAddress(value)
    if (value.length < 2) {
      return setStreetData([])
    }
    if (value.length > 5) {
      const autocomplete = new window.google.maps.places.AutocompleteService()
      autocomplete.getPlacePredictions(
        { input: value, componentRestrictions: { country: `cl` } },
        predictions => {
          setStreetData(predictions)
        },
      )
    }
  }

  const [debounceSearch] = useDebouncedCallback(
    value => onSearchAddressChange(value),
    3000,
  )

  
  /* Hot Fix problemas API Maps */
  const addressSplitter = (fullAddress) => {
    // Metodo (demasiado) simple para dividir nombre y nro de calle
    let numIndex = fullAddress.search(/[0-9]/)
    if (numIndex == -1) {
      return [fullAddress, '']
    }
    let streetName = fullAddress.substring(0, numIndex)
    let streetNum = fullAddress.substring(numIndex, fullAddress.length)
    return [streetName, streetNum]
  }

  const onSetAddress = data => {
    setTextAddress(data)
    const splittedAddress = addressSplitter(data)

    return setAddressData({
      streetName: splittedAddress[0],
      streetNumber: splittedAddress[1],
    })
  }

  /*
  const onSelect = data => {
    const street = JSON.parse(data)
    setTextAddress(street.structured_formatting.main_text)
    let recievedStreetName = ""
    let recievedStreetNumber = 0
    let streetNameInfo = street.structured_formatting.main_text_matched_substrings["0"]
    let streetNumberInfo = street.structured_formatting.main_text_matched_substrings["1"]
    if (streetNameInfo) {
      recievedStreetName = street.structured_formatting.main_text.substring(streetNameInfo.offset, streetNameInfo.length + streetNameInfo.offset)
    }

    if (streetNumberInfo) {
      recievedStreetNumber = street.structured_formatting.main_text.substring(streetNumberInfo.offset, streetNumberInfo.length + streetNumberInfo.offset)
    }

    return setAddressData({
      streetName: recievedStreetName,
      streetNumber: recievedStreetNumber,
    })
  }
  */

  const editTree = () => {
    let tree = { ...fields, latitude, longitude }
    if (addressData) {
      tree = { ...tree, ...addressData }
    }
    modifyTree(data.id, tree)
    onCancel()
  }

  return (
    <Modal
      onAccept={editTree}
      onCancel={onCancel}
      visible={true}
      title="Editar árbol"
    >
      <Container justifyContent="space-between">
        <Input
          disabled={true}
          width="48%"
          label="ID:"
          placeholder="Escriba aqui"
          onChange={onInputChange}
          value={fields.id}
          name="id"
        />
        {treeFields &&
          treeFields.map(
            ({ treeValue, treeField, fieldName, fieldType, options }) => {
              if (fieldType === `address`) {
                return (
                  <>
                    <Input
                      width="48%"
                      type="input"
                      label="Dirección"
                      value={textAddress}
                      //onSearch={debounceSearch}
                      //options={streetData}
                      name="address"
                      //onSelect={onSelect}
                      onChange={(name, value) => onSetAddress(value)}
                    />
                    {roleId === 1 ? (
                      <>
                        <Input
                          width="48%"
                          type="input"
                          label="Latitud/Longitud"
                          value={latLng}
                          name="latitude"
                          onChange={(name, value) => setLatLng(value)}
                        />
                        <Input
                          width="48%"
                          type="input"
                          label="Latitud"
                          value={latitude}
                          name="latitude"
                          onChange={(name, value) => setLatitude(value)}
                        />
                        <Input
                          width="48%"
                          type="input"
                          label="Longitud"
                          value={longitude}
                          name="longitude"
                          onChange={(name, value) => setLongitude(value)}
                        />
                      </>
                    ) : null}
                  </>
                )
              }
              if (treeValue === `specieValue`) {
                return (
                  <Input
                    width="48%"
                    showSearch
                    type="select"
                    treeSelect
                    optionFilterProp="children"
                    label={fieldName}
                    onChange={onSelectChange}
                    placeholder="Elija una opción"
                    valueName={treeValue}
                    name={treeField}
                    keyValue="description"
                    options={options}
                    filterOption={(input, option) =>
                      option.props.children ? option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    value={values[treeValue]}
                  />
                )
              }
              if (treeField === `observations`) {
                return (
                  <Input
                    width="48%"
                    maxLength={5000}
                    label={fieldName}
                    placeholder="Escriba aqui"
                    treeSelect={true}
                    valueName={treeValue}
                    onChange={ onInputChange }
                    value={ fields[treeField] }
                    name={treeField}
                    type={fieldType}
                    keyValue="description"
                    options={options}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                )
              }
              return (
                <Input
                  width="48%"
                  label={fieldName}
                  placeholder="Escriba aqui"
                  treeSelect={true}
                  valueName={treeValue}
                  onChange={
                    fieldType === `select` ? onSelectChange : onInputChange
                  }
                  value={
                    fieldType === `select`
                      ? values[treeValue]
                      : fields[treeField]
                  }
                  name={treeField}
                  type={fieldType}
                  keyValue="description"
                  options={options}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              )
            },
          )}
      </Container>
    </Modal>
  )
}

const mapStateToProps = ({
  auth: {
    user: { id: userId, roleId },
  },
  trees: { treeFields, editableTree },
}) => ({
  userId,
  roleId,
  treeFields,
  editableTree,
})

const mapDispatchToProps = { getTreeFields, getTreeById, modifyTree }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
