const supportedModels = [
  { label: `Arquitectura`, routeModel: `Architecture`, id: 0 },
  { label: `Daño en base del tronco`, routeModel: `BaseDamage`, id: 1 },
  { label: `Copa Muerta`, routeModel: `DeadTop`, id: 2 },
  { label: `Agente patógeno 1`, routeModel: `FirstPathogen`, id: 3 },
  {
    label: `Intensidad agente patógeno 1`,
    routeModel: `FirstPathogenIntensity`,
    id: 4,
  },
  {
    label: `Ubicación agente patógeno 1`,
    routeModel: `FirstPathogenLocation`,
    id: 5,
  },
  { label: `Inclinación`, routeModel: `Inclination`, id: 6 },
  { label: `Ubicación`, routeModel: `Location`, id: 7 },
  { label: `Material Platabanda`, routeModel: `MaterialPlatabanda`, id: 8 },
  { label: `Orientación`, routeModel: `Oriented`, id: 9 },
  { label: `Orientación en Parque`, routeModel: `OrientedPark`, id: 10 },
  { label: `Estado Fitosanitario`, routeModel: `PhytosanitaryState`, id: 11 },
  { label: `Arquitectura pobre`, routeModel: `PoorArchitecture`, id: 12 },
  { label: `Recomendaciones 1`, routeModel: `Recommendation`, id: 13 },
  {
    label: `Recomendaciones 2`,
    routeModel: `SecondaryRecommendation`,
    id: 14,
  },
  {
    label: `Recomendaciones 3`,
    routeModel: `TertiaryRecommendation`,
    id: 15,
  },
  { label: `Fase de desarrollo`, routeModel: `RelativeAge`, id: 16 },
  { label: `Agente patógeno 2`, routeModel: `SecondPathogen`, id: 17 },
  {
    label: `Intensidad agente patógeno 2`,
    routeModel: `SecondPathogenIntensity`,
    id: 18,
  },
  {
    label: `Ubicación agente patógeno 2`,
    routeModel: `SecondPathogenLocation`,
    id: 19,
  },
  { label: `Forma`, routeModel: `Shape`, id: 20 },
  { label: `Estado estructural`, routeModel: `StructuralState`, id: 21 },
  { label: `Estado general`, routeModel: `TreeState`, id: 22 },
  { label: `Tipo de árbol`, routeModel: `TreeType`, id: 23 },
  { label: `Daño en tronco`, routeModel: `TrunkDamage`, id: 24 },
  {
    label: `Daño en inserción de ramas`,
    routeModel: `InsertionDamage`,
    id: 25,
  },
  { label: `Daño en ramas principales`, routeModel: `BranchDamage`, id: 26 },
  { label: `Daño en copa`, routeModel: `TopDamage`, id: 27 },
  { label: `DAP`, routeModel: `Dap`, id: 28 },
  {
    label: `Especie`,
    routeModel: `Specie`,
    id: 29,
    additionalFields: [
      { name: `scientificName`, label: `Nombre científico` },
      { name: `typeOrigin`, label: `Origen` },
      { name: `geographyOrigin`, label: `Origen geográfico` },
      { name: `clasification`, label: `Clasificación` },
      { name: `code`, label: `Código` },
    ],
  },
  {
    label: `Tipo Tareas`,
    routeModel: `TaskType`,
    id: 30,
  },
  {
    label: `Tipo Solicitudes`,
    routeModel: `RequestType`,
    id: 31,
  },
  {
    label: `Recomendación Inmediata`,
    routeModel: `ImmediateRecommendation`,
    id: 32,
  },
  {
    label: `Recomendación CP/MP`,
    routeModel: `ShortRecommendation`,
    id: 33,
  },
  {
    label: `Condición`,
    routeModel: `Condition`,
    id: 34,
  },
  {
    label: `Interferencia`,
    routeModel: `Interference`,
    id: 35,
  },
  {
    label: `Densidad de follaje`,
    routeModel: `FoliageDensity`,
    id: 36,
  },
  {
    label: `Presencia de`,
    routeModel: `PresenceField`,
    id: 88,
  },
  {
    label: `Tipo de Urbanización`,
    routeModel: `UrbanizationType`,
    id: 89,
  },
  {
    label: `Ancho de veredón`,
    routeModel: `SidewalkWidth`,
    id: 90,
  },
  {
    label: `Árbol más próximo`,
    routeModel: `NearestTree`,
    id: 91,
  },
  {
    label: `Interacción con elementos Urbanos`,
    routeModel: `InteractionWithUrbanElement`,
    id: 93,
  },
  {
    label: `Fenología`,
    routeModel: `Fenologias`,
    id: 94,
  },
  {
    label: `Valor en el Paisaje`,
    routeModel: `LandscapeWorth`,
    id: 95,
  },
  {
    label: `Emplazamiento`,
    routeModel: `Emplacement`,
    id: 96,
  },
  {
    label: `Sector`,
    routeModel: `Zone`,
    id: 97,
  },
  {
    label: `Ubicación referencial`,
    routeModel: `ReferentialLocation`,
    id: 98,
  },
]

export default supportedModels
