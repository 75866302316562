import { useEffect, useRef } from 'react'

function useListenerEnter({ enterFn, dependencies = [] }) {
  const listener = useRef(null)
  useEffect(() => {
    listener.current = event => {
      if (event.code === `Enter` || event.code === `NumpadEnter`) {
        enterFn()
      }
    }
    document.addEventListener(`keydown`, listener.current)
    return () => {
      document.removeEventListener(`keydown`, listener.current)
    }
  }, dependencies)
}

export default useListenerEnter
