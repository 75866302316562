import {
  faUserCircle,
  faTree,
  faTasks,
  faUsers,
  faLeaf, // Especies
  faQuestionCircle,
  faMapMarkerAlt,
  faCog,
  faAlignCenter,
  faListOl,
  faDollarSign,
  faClock,
  faQrcode
} from '@fortawesome/free-solid-svg-icons'
import Clients from '../screens/Clients'
import ClientManagement from '../screens/ClientManagement'
import OrderFields from '../screens/OrderFields'
import SubUnits from '../screens/SubUnits'
import GenericFields from '../screens/GenericFields'
import TaskReports from '../screens/TaskReports'
import TableReports from '../screens/TableReports'
import PerformanceReports from '../screens/PerformanceReports'
import Users from '../screens/Users'
import Trees from '../screens/Trees'
import Tasks from '../screens/Tasks'
import Requests from '../screens/Requests'
import Map from '../screens/Map'
import OrderTask from '../screens/OrderTask'
import OrderTaskMap from '../screens/OrderTask/Map'
import OrderTaskReport from '../screens/OrderTask/Report'
import OrderBilling from '../screens/OrderTask/Billing'
import OrderFines from '../screens/OrderTask/Fines'
import Species from '../screens/Species'
import QRGenerator from '../screens/QRGenerator'

export default [
  {
    key: 1,
    title: `Arboles`,
    icon: faTree,
    component: Trees,
    rolesIncluded: [1, 2, 3, 4, 5, 6, 7],
    path: `/arboles`,
  },
  {
    key: 2,
    title: `Gestion Clientes`,
    icon: faCog,
    component: ClientManagement,
    rolesIncluded: [1],
    path: `/gestion-clientes`,
  },
  {
    key: 11,
    title: `Orden Formulario`,
    icon: faListOl,
    component: OrderFields,
    rolesIncluded: [1],
    path: `/orden-formulario`,
  },
  {
    key: 3,
    title: `Sub Unidades`,
    icon: faCog,
    component: SubUnits,
    rolesIncluded: [1],
    path: `/gestion-subunidades`,
  },
  {
    key: 4,
    title: `Campos genéricos`,
    icon: faAlignCenter,
    component: GenericFields,
    rolesIncluded: [1],
    path: `/campos-genericos`,
  },
  {
    key: 5,
    title: `Reporteria`,
    icon: faAlignCenter,
    component: TaskReports,
    rolesIncluded: [1, 2, 3, 4, 5, 6, 7],
    path: `/reporteria`,
  },
  {
    key: `5-1`,
    title: `Reporteria Tareas`,
    icon: faAlignCenter,
    component: TableReports,
    rolesIncluded: [1, 2, 3, 5, 7],
    path: `/labores-mantencion-mensual`,
  },
  {
    key: `5-2`,
    title: `Cumplimiento`,
    icon: faAlignCenter,
    component: PerformanceReports,
    rolesIncluded: [1, 2, 3, 5, 7],
    path: `/cumplimiento`,
  },
  {
    key: 6,
    title: `Usuarios`,
    icon: faUserCircle,
    component: Users,
    rolesIncluded: [1],
    path: `/usuarios`,
  },
  {
    key: 7,
    title: `Clientes`,
    icon: faUsers,
    component: Clients,
    rolesIncluded: [1],
    path: `/clientes`,
  },
  {
    key: 17,
    title: `Generar QR`,
    icon: faQrcode,
    component: QRGenerator,
    rolesIncluded: [1],
    path: `/generarqr`
  },
  {
    key: 8,
    title: `Tareas`,
    icon: faTasks,
    component: Tasks,
    rolesIncluded: [1, 2, 3, 5, 7],
    path: `/tareas`,
  },
  {
    key: 9,
    title: `Solicitudes`,
    icon: faQuestionCircle,
    component: Requests,
    rolesIncluded: [1],
    path: `/solicitudes`,
  },
  {
    key: 10,
    title: `Mapa`,
    icon: faMapMarkerAlt,
    component: Map,
    rolesIncluded: [1, 2, 3, 4, 5, 6, 7],
    path: `/mapa-arboles`,
  },
  {
    key: 11,
    title: `Especies`,
    icon: faLeaf,
    component: Species,
    rolesIncluded: [1],
    path: `/especies`,
  },
  {
    key: 12,
    title: `Ordenes de trabajo`,
    icon: faTasks,
    component: OrderTask,
    rolesIncluded: [1, 8, 9],
    path: `/ot-barnechea`,
    lb: true,
    showDivider: true,
  },
  {
    key: 13,
    title: `Mapa`,
    icon: faMapMarkerAlt,
    component: OrderTaskMap,
    rolesIncluded: [1, 8, 9],
    path: `/mapa-ot-barnechea`,
    lb: true,
  },
  {
    key: 14,
    title: `Reportería`,
    icon: faAlignCenter,
    component: OrderTaskReport,
    rolesIncluded: [1, 8, 9],
    path: `/reporteria-ot-barnechea`,
    lb: true,
  },
  {
    key: 15,
    title: `Facturación`,
    icon: faDollarSign,
    component: OrderBilling,
    rolesIncluded: [1, 8, 9],
    path: `/billing`,
    lb: true,
  },
  {
    key: 16,
    title: `Multas`,
    icon: faClock,
    component: OrderFines,
    rolesIncluded: [1, 8, 9],
    path: `/fines`,
    lb: true,
  },
]
