import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  getOrderTaskCount,
  getOrderTaskCountFilters,
} from '../../../redux/actions'
import {
  Typography,
  Loader,
  Container,
  Card,
  Table,
  Input,
  Button,
} from '../../../components'

const tabs = {
  cursor: `pointer`,
  justifyContent: `center`,
}

const columns = [
  {
    title: ``,
    dataIndex: `name`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `Creadas`,
    dataIndex: `created`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `Pendientes`,
    dataIndex: `pending`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `No realizadas`,
    dataIndex: `notDone`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `Realizadas`,
    dataIndex: `done`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `Rechazadas`,
    dataIndex: `declined`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `Aprobadas`,
    dataIndex: `approved`,
    render: (value, row, index) =>
      index ? (
        value
      ) : (
        <Typography fontSize="18px" fontWeight="700">
          {value}
        </Typography>
      ),
  },
  {
    title: `Pagadas`,
    dataIndex: `paund`,
    render: (value, row, index) => {
      return {
        props: {
          style: { borderLeft: `2px solid #EBEBEB` },
        },
        children: index ? (
          value
        ) : (
          <Typography fontSize="18px" fontWeight="700">
            {value}
          </Typography>
        ),
      }
    },
  },
]

function OrdenTaskReport({
  getOrderTaskCount,
  getOrderTaskCountFilters,
  loading,
  tasksCountByType,
  tasksCountByUser,
}) {
  const [state, setState] = useState({
    visiblePerOT: true,
    visiblePerUser: false,
    filters: {
      createdIn: ``,
      orderBy: `DESC`,
    },
  })

  const { visiblePerOT, visiblePerUser, filters } = state

  useEffect(() => {
    getOrderTaskCount()
  }, [])

  if (loading) {
    return <Loader />
  }

  const handleShowTabs = () => {
    setState(prevState => ({
      ...prevState,
      visiblePerOT: !visiblePerOT,
      visiblePerUser: !visiblePerUser,
    }))
  }

  const handleResetFilters = () => {
    setState(prevState => ({
      ...prevState,
      filters: { createdIn: ``, orderBy: `DESC` },
    }))
  }

  const handleInput = (field, value) => {
    setState(prevState => {
      const { filters } = prevState
      const newFilters = { ...filters, [field]: value }
      return { ...prevState, filters: newFilters }
    })
  }

  const getTaskFilter = () => {
    getOrderTaskCountFilters(filters)
  }

  const handleResetData = () => {
    getOrderTaskCount()
    handleResetFilters()
  }

  return (
    <>
      <Container width="100%">
        <Container
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Container width="20%">
            <Typography type="title">Reportería</Typography>
          </Container>
          <Container
            width="60%"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Input
              width="25%"
              type="dateRange"
              format="DD-MM-YYYY"
              label="Definir periodo"
              onChange={handleInput}
              name="createdIn"
              value={filters.createdIn === `` ? undefined : filters.createdIn}
            />
            <Input
              width="25%"
              showSearch
              marginBottom="0px"
              type="select"
              optionFilterProp="children"
              label="Ordenar por"
              placeholder="Elija una opción"
              onChange={handleInput}
              name="orderBy"
              keyValue="description"
              options={[
                { description: `Desde la A a la Z`, id: `DESC` },
                { description: `Desde la Z a la A`, id: `ASC` },
              ]}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={filters.orderBy === `` ? undefined : filters.orderBy}
            />
            <Container marginBottom="5px">
              <Button
                onClick={getTaskFilter}
                text="Buscar"
                boxShadow={true}
                backgroundColor="gray.2"
              />
              <Button
                onClick={handleResetData}
                text="Limpiar Búsqueda"
                color="primary.3"
                backgroundColor="transparent"
              />
            </Container>
          </Container>
        </Container>
        <Card
          width="100%"
          borderColor="primary.3"
          display="flex"
          marginTop="16px"
          paddingTop="0px"
          style={{ flexWrap: `wrap` }}
        >
          <Container
            width="50%"
            borderTop={visiblePerOT ? `4px solid #81BB2A` : ``}
            backgroundColor={visiblePerUser ? `#EBEBEB` : ``}
            padding="12px"
            style={tabs}
            onClick={handleShowTabs}
          >
            <Typography
              type="subtitle"
              marginBottom="0px"
              fontSize="18px"
              fontWeight="500"
              align="center"
            >
              Según tipo OT
            </Typography>
          </Container>
          <Container
            width="50%"
            padding="12px"
            borderTop={visiblePerUser ? `4px solid #81BB2A` : ``}
            backgroundColor={visiblePerOT ? `#EBEBEB` : ``}
            style={tabs}
            onClick={handleShowTabs}
          >
            <Typography
              type="subtitle"
              marginBottom="0px"
              fontSize="18px"
              fontWeight="500"
              align="center"
            >
              Según Usuario
            </Typography>
          </Container>
          {visiblePerOT && (
            <Container width="100%" marginTop="8px">
              <Table
                marginTop="16px"
                columns={columns}
                dataSource={tasksCountByType}
                loading={loading}
                locale={{
                  emptyText: `No existen orden de trabajo ingresadas`,
                }}
              />
            </Container>
          )}
          {visiblePerUser && (
            <Container width="100%" marginTop="8px">
              <Table
                marginTop="16px"
                columns={columns}
                dataSource={tasksCountByUser}
                loading={loading}
                locale={{
                  emptyText: `No existen orden de trabajo ingresadas`,
                }}
              />
            </Container>
          )}
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = ({
  auth: { user },
  orderTask: { tasksCountByType, tasksCountByUser, loading },
  general: { adminUnitId },
}) => {
  return {
    user,
    tasksCountByType,
    tasksCountByUser,
    loading,
    adminUnitId,
  }
}

const mapDispatchToProps = {
  getOrderTaskCount,
  getOrderTaskCountFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdenTaskReport)
