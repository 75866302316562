import React from 'react'
import Container from './Container'
import HeaderContainer from './HeaderContainer'
import Typography from './Typography'

const MainContainer = ({
  subtitle = null,
  children,
  title,
  action,
  ...props
}) => {
  return (
    <Container flexDirection="column" paddingBottom="16px" width="100%">
      <Container
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Container flexDirection="column">
          <Typography type="title" fontSize="9">
            {title}
          </Typography>
          {subtitle && <Typography type="subtitle" text={subtitle} />}
        </Container>
        {action}
      </Container>
      <Container
        padding="24px 16px"
        width="100%"
        withShadow={true}
        marginTop="16px"
        borderRadius={4}
        borderTop="4px solid"
        borderColor="primary.3"
        {...props}
      >
        <HeaderContainer {...props} />
        {children}
      </Container>
    </Container>
  )
}

export default MainContainer
