import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { connect } from 'react-redux'
import { faMapMarkerAlt, faCircle } from '@fortawesome/free-solid-svg-icons'
import Supercluster from 'supercluster'
import { thousandsFormat } from '../../utils'
import { getMapTrees, getMapTreeFilters } from '../../redux/actions'
import {
  Card,
  Loader,
  Icon,
  Container,
  MainContainer,
  HeaderInfo,
  Modal,
  Typography,
  Img,
} from '../../components'
import MapTreeFilter from '../../components/MapTreeFilter'
import unitsGeolocation from '../../assets/unitsGeolocation'

const parseFormatArrCoord = (arrCoord) => {
  const result = []
  for (let i = 0; i < arrCoord.length - 1; i += 2) {
    result.push({lng: arrCoord[i], lat: arrCoord[i+1]})
  }
  return result
}

const handleMapApi = (map, maps, unitId1, unitId2) => {
  if (unitId1 || unitId2) {
    let unitId = unitId1 || unitId2
    let arrCoord = unitsGeolocation[unitId.toString()]

    if (arrCoord) {
      for (let i=0; i<arrCoord.length; i++) {
        var polygon = new maps.Polygon({
          paths: parseFormatArrCoord(arrCoord[i].coordinates),
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2.5,
          fillColor: "#FF0000",
          fillOpacity: 0.0
        });
        polygon.setMap(map);
      }
    }
  }
}

// TODO mejorar manejo (desde backend?)
const treeStates = {
  1: {
    color: `green`,
    status: `Bueno`,
    path: `/assets/map-pin1.svg`,
  },
  2: {
    color: `yellow`,
    status: `Regular`,
    path: `/assets/map-pin2.svg`,
  },
  3: {
    color: `red`,
    status: `Malo`,
    path: `/assets/map-pin3.svg`,
  },
  4: {
    color: `black`,
    status: `Muerto`,
    path: `/assets/map-pin4.svg`,
  },
  5: {
    color: `black`,
    status: `Muerto`,
    path: `/assets/map-pin4.svg`,
  },
  6: {
    color: `blue`,
    status: `PV`,
    path: `/assets/map-pin6.svg`,
  },
  p: {
    color: `orange`,
    status: `Palmera`,
    path: `/assets/map-pin5.svg`,
  },
  t: {
    color: `brown`,
    status: `Tocón`,
    path: `/assets/map-pin7.svg`,
  },
}

const clusterizeTrees = ({ trees, bounds, zoom }) => {
  const boundArray = [
    bounds.nw.lng,
    bounds.sw.lat,
    bounds.se.lng,
    bounds.ne.lat,
  ]
  const clusterizer = new Supercluster({
    radius: 60,
    maxZoom: 16,
  })
  const treePoints = trees.map(tree => {
    const {
      id,
      longitude,
      latitude,
      streetName,
      streetNumber,
      treeStateId,
      Tasks,
      specie,
      emptySpace,
      treeTypeId,
    } = tree
    return {
      type: `Feature`,
      geometry: {
        type: `Point`,
        coordinates: [longitude, latitude],
      },
      // Agregar acá propiedades del árbol para el marker
      properties: {
        treeId: id,
        address: `${streetName}, ${streetNumber}`,
        treeState: treeStateId,
        tasks: Tasks,
        specie,
        emptySpace,
        treeTypeId,
      },
    }
  })
  clusterizer.load(treePoints)
  const clusterArray = clusterizer.getClusters(boundArray, zoom)
  return clusterArray
}

const Marker = ({
  isCluster,
  clusterCount,
  treeId,
  address,
  treeState,
  treeType,
  tasks,
  specie,
  emptySpace,
  isStateColor,
  showModal,
}) => {
  if (isCluster) return <Icon icon={faCircle} style={{ color: `blue` }} />
  let color = ``
  if (isStateColor == 0) {
    color = treeStates[treeState] ? treeStates[treeState].color : `black`
  } else if (isStateColor == 1) {
    color = `black`
    if (treeType == 1) {
      color = `green`
    } else if (treeType == 12) {
      color = `orange`
    } else if (treeType == 2) {
      color = `brown`
    } else if (treeType == 4) {
      color = `blue`
    }
  } else if (tasks.length > 0) {
    if (tasks.filter(e => e.TaskPriority === null).length > 0) {
      color = `black`
    } else {
      const priorityColor = tasks.reduce((prev, current) =>
        prev.TaskPriority.id < current.TaskPriority.id ? prev : current,
      )
      color = priorityColor.TaskPriority
        ? priorityColor.TaskPriority.color
        : `black`
    }
  } else {
    color = `black`
  }

  return (
    <>
      <Img
        onClick={() =>
          showModal({ treeId, address, tasks, specie, emptySpace })
        }
        src={`${process.env.PUBLIC_URL}${
          Object.values(treeStates).find(state => state.color === color).path
        }`}
        width="2em"
        height="2em"
        alt="tree icon"
      />
    </>
  )
}

class TreesMap extends Component {
  static defaultProps = {
    defaultCenter: {
      lat: -33.4330527,
      lng: -70.6345756,
    },
    defaultZoom: 15,
  }
  constructor(props) {
    super(props)
    this.state = {
      ...this.getInitialState(),
      clusters: [],
      isStateColor: true,
    }
  }

  getInitialState = () => ({
    filters: {},
    filteredStreets: [],
    clusters: [],
    showModal: false,
    selectedTree: {},
  })

  componentDidMount() {
    const { user, getMapTreeFilters, adminUnitId } = this.props
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getMapTreeFilters(unitId)
    }
    if (adminUnitId !== null) {
      getMapTreeFilters(adminUnitId)
    }
  }

  componentDidUpdate(prevProps) {
    const { trees, adminUnitId, treeFilters } = this.props
    const { bounds, zoom } = this.state
    if (prevProps.trees !== trees) {
      if (trees.length > 0) {
        const clusterArray = clusterizeTrees({ trees, bounds, zoom })
        this.setState({
          center: {
            lat: trees[0].latitude,
            lng: trees[0].longitude,
          },
          clusters: clusterArray,
        })
      }
    }
    if (prevProps.adminUnitId !== adminUnitId) {
      this.props.getMapTreeFilters(adminUnitId)
    }
    if (prevProps.treeFilters !== treeFilters) {
      const {
        treeFilters: {
          unitCoords: { unitLatitude, unitLongitude },
        },
      } = this.props
      this.setState({ center: { lat: unitLatitude, lng: unitLongitude } })
    }
  }

  createMapOptions = maps => {
    return {
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
      },
    }
  }

  onMapChange = ({ zoom, bounds }) => {
    const { trees } = this.props
    const clusterArray = clusterizeTrees({ trees, bounds, zoom })
    this.setState({ zoom, bounds, clusters: clusterArray })
  }

  showModal = ({ treeId, address, tasks, specie, emptySpace }) => {
    this.setState({
      showModal: true,
      selectedTree: { treeId, address, tasks, specie, emptySpace },
    })
  }

  render() {
    const {
      selectedTree,
      showModal,
      clusters,
      zoom,
      center,
      isStateColor,
    } = this.state
    const {
      loading,
      history,
      defaultZoom,
      defaultCenter,
      trees,
      treeFilters,
      getMapTrees,
    } = this.props
    const { totalTrees = 0, totalSpecies = 0 } = treeFilters || {}
    return (
      <>
        {showModal && (
          <Modal
            onCancel={() => this.setState({ showModal: false })}
            title="Datos árbol"
            acceptText="Ver"
            onAccept={() => history.push(`/arboles/${selectedTree.treeId}`)}
            visible={true}
          >
            <Container width="100%" justifyContent="space-between">
              <Container flexDirection="column" width="48%" marginBottom="16px">
                <Typography fontWeight="bold">Id árbol</Typography>
                <Typography
                  backgroundColor="gray.1"
                  width="100%"
                  padding="4px 8px"
                >
                  {selectedTree.treeId}
                </Typography>
              </Container>
              <Container flexDirection="column" width="48%" marginBottom="16px">
                <Typography fontWeight="bold">Dirección</Typography>
                <Typography
                  backgroundColor="gray.1"
                  width="100%"
                  padding="4px 8px"
                >
                  {selectedTree.address}
                </Typography>
              </Container>

              {selectedTree.emptySpace ? (
                <Typography
                  backgroundColor="gray.1"
                  width="100%"
                  padding="4px 8px"
                >
                  Plantera vacía
                </Typography>
              ) : (
                <>
                  <Container
                    flexDirection="column"
                    width="48%"
                    marginBottom="16px"
                  >
                    <Typography fontWeight="bold">Especie</Typography>
                    <Typography
                      backgroundColor="gray.1"
                      width="100%"
                      padding="4px 8px"
                    >
                      {selectedTree.specie.description}
                    </Typography>
                  </Container>
                  <Container
                    flexDirection="column"
                    width="48%"
                    marginBottom="16px"
                  >
                    <Typography fontWeight="bold">Nombre cientifico</Typography>
                    <Typography
                      backgroundColor="gray.1"
                      width="100%"
                      padding="4px 8px"
                    >
                      {selectedTree.specie.scientificName}
                    </Typography>
                  </Container>
                  {selectedTree.tasks &&
                    selectedTree.tasks.map(task => (
                      <>
                        <Container
                          flexDirection="column"
                          width="48%"
                          marginBottom="16px"
                        >
                          <Typography fontWeight="bold">Tipo tarea</Typography>
                          <Typography
                            backgroundColor="gray.1"
                            width="100%"
                            padding="4px 8px"
                          >
                            {task.taskType.description}
                          </Typography>
                        </Container>
                        <Container
                          flexDirection="column"
                          width="48%"
                          marginBottom="16px"
                        >
                          <Typography fontWeight="bold">
                            Estado tarea
                          </Typography>
                          <Typography
                            backgroundColor="gray.1"
                            width="100%"
                            padding="4px 8px"
                          >
                            {task.taskStatus.description}
                          </Typography>
                        </Container>
                      </>
                    ))}
                </>
              )}
            </Container>
          </Modal>
        )}
        {treeFilters && (
          <MainContainer title="Árboles" justifyContent="space-around">
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(treeFilters.totalTrees),
                subtitle: `TOTAL`,
              },
              {
                title: thousandsFormat(treeFilters.totalPV),
                subtitle: `PLANTERAS VACÍAS`
              },
            ]}
          />
          </div>
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(treeFilters.totalArboles),
                subtitle: `ARBOLES`
              },
              { 
                title: treeFilters.totalSpecies, 
                subtitle: `ESPECIES` 
              },
            ]}
          />
          </div>
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(treeFilters.totalPalmeras),
                subtitle: `PALMERAS`
              },
              { 
                title: thousandsFormat(trees.length), 
                subtitle: `TOTAL BÚSQUEDA` 
              },
            ]}
          />
          </div>
          <div>
          <HeaderInfo
            list={[
              {
                title: thousandsFormat(treeFilters.totalTocones),
                subtitle: `TOCONES`
              },
            ]}
          />
          </div>
        </MainContainer>
        )}
        {!treeFilters && this.props.user.roleId === 1 ? (
          <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
            Seleccione Unidad
          </Typography>
        ) : null}
        <MapTreeFilter
          isMap={true}
          getTrees={getMapTrees}
          treeFilters={treeFilters}
          isStateColor={isStateColor}
          changeColorType={isStateColor => this.setState({ isStateColor })}
        />
        {treeFilters && (
          <Card width="100%" borderColor="primary.3">
            {loading ? (
              <Loader />
            ) : (
              <Container marginTop="16px" height="80vh" width="100%">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: `AIzaSyDTveYGct8Kmle1JLGtmvMcxPwU4FeeevE`,
                  }}
                  options={this.createMapOptions}
                  defaultCenter={defaultCenter}
                  defaultZoom={defaultZoom}
                  center={center}
                  zoom={zoom}
                  onChange={this.onMapChange}
                  onGoogleApiLoaded={({ map, maps }) => handleMapApi(map, maps, this.props.adminUnitId, this.props.user.unitId)}
                >
                  {clusters.length > 0 &&
                    clusters.map(cluster => {
                      //TODO handle marker vs/cluster marker
                      const {
                        cluster: isCluster,
                        cluster_id: clusterId,
                        point_count_abbreviated: clusterCount,
                        treeId,
                        address,
                        treeState,
                        tasks,
                        specie,
                        emptySpace,
                        treeTypeId,
                      } = cluster.properties
                      const [lng, lat] = cluster.geometry.coordinates
                      return (
                        <Marker
                          lat={lat}
                          lng={lng}
                          isCluster={isCluster}
                          clusterCount={clusterCount}
                          treeId={treeId}
                          address={address}
                          treeState={treeState}
                          treeType={treeTypeId}
                          tasks={tasks}
                          specie={specie}
                          emptySpace={emptySpace}
                          isStateColor={isStateColor}
                          showModal={this.showModal}
                        />
                      )
                    })}
                </GoogleMapReact>
              </Container>
            )}
          </Card>
        )}
      </>
    )
  }
}

const mapStateToProps = ({
  map: { trees, loading, treeFilters },
  auth: { user },
  general: { adminUnitId },
}) => ({
  loading,
  trees,
  treeFilters,
  user,
  adminUnitId,
})

const mapDispatchToProps = {
  getMapTreeFilters,
  getMapTrees,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TreesMap)
