import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import reducers from '../reducers'

const persistConfig = {
  key: `arbotag-beta`,
  storage,
  whitelist: [`auth`],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const middlewares = compose(
  applyMiddleware(thunk, logger),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => {
        return f
      },
)
const store = createStore(persistedReducer, middlewares)
const persistor = persistStore(store)

export { store, persistor }
