import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ResponsiveContainer, XAxis, BarChart, Bar, Tooltip } from 'recharts'
import { getTaskReportFilters, getTreeByUnit } from '../../redux/actions'
import { Card, Typography, Loader, Container } from '../../components'
import TaskReportFilter from '../../components/TaskReportFilter'
import TreesByUnit from './TreesByUnit'

function TaskReports({
  tasksBySpecies,
  loadingBySpecies,
  tasksByType,
  loadingByType,
  getTaskReportFilters,
  getTreeByUnit,
  treesByUnit,
  treesByUnitLoading,
  taskReportFilters,
  user,
  adminUnitId,
  tasksByOrigin,
  loadingByOrigin,
}) {
  const [state, setState] = useState({
    treesByUnitId: [],
  })

  const { treesByUnitId } = state

  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getTaskReportFilters(unitId)
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getTaskReportFilters(adminUnitId)
    }
  }, [adminUnitId])

  useEffect(() => {
    const { unitId } = user
    getTreeByUnit(adminUnitId || unitId)
    setState(prevState => ({ ...prevState, treesByUnitId: [] }))
  }, [adminUnitId])

  useEffect(() => {
    if (treesByUnit.length) {
      const treesByUnitId = treesByUnit.reduce((acc, item) => {
        const treeState = item.treeState || {}
        const description = treeState.description || ``

        const data = acc[description] || []
        data.push(item)
        acc[description] = data
        return acc
      }, {})
      setState(prevState => ({ ...prevState, treesByUnitId }))
    }
  }, [treesByUnit])

  if (loadingBySpecies || loadingByType || loadingByOrigin) {
    return <Loader />
  }

  return (
    <Container width="100%">
      <Typography type="title">Reportes</Typography>
      {!taskReportFilters && user.roleId === 1 ? (
        <Typography
          marginTop="10"
          color="primary.3"
          fontWeight="bold"
          fontStyle="italic"
        >
          Seleccione Unidad
        </Typography>
      ) : null}
      {taskReportFilters && <TaskReportFilter adminUnitId={ adminUnitId } />}
      {tasksBySpecies.length > 0 && (
        <Card width="100%" borderColor="primary.3">
          <ResponsiveContainer width="100%" minHeight={250}>
            <BarChart data={tasksBySpecies[0]}>
              <Bar dataKey="value" name="cantidad" fill="#8884d8" />
              <XAxis dataKey="name" />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      )}
      {tasksByType.length > 0 && (
        <Card width="100%" borderColor="primary.3">
          <ResponsiveContainer width="100%" minHeight={250}>
            <BarChart data={tasksByType[0]}>
              <Bar dataKey="value" name="cantidad" fill="#8884d8" />
              <XAxis dataKey="name" />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      )}
      <TreesByUnit treesByUnitId={treesByUnitId} loading={treesByUnitLoading} />
    </Container>
  )
}

const mapStateToProps = ({
  tasks: {
    tasksBySpecies,
    tasksByOrigin,
    loadingBySpecies,
    loadingByOrigin,
    tasksByType,
    loadingByType,
    taskReportFilters,
  },
  auth: { user },
  general: { adminUnitId },
  trees: { treesByUnit, treesByUnitLoading },
}) => {
  return {
    loadingBySpecies,
    tasksBySpecies,
    loadingByOrigin,
    tasksByOrigin,
    loadingByType,
    tasksByType,
    taskReportFilters,
    user,
    adminUnitId,
    treesByUnit,
    treesByUnitLoading,
  }
}

const mapDispatchToProps = {
  getTaskReportFilters,
  getTreeByUnit,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskReports)
