import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Modal, Button } from '../../components'
import { addGenericField, editGenericField } from '../../redux/actions'

function Form({
  addGenericField,
  editGenericField,
  onCancel,
  entity,
  currentModel,
  clearModalData,
  units,
  createField,
}) {
  const [fields, setFields] = useState(entity || {})
  const modelName = currentModel.routeModel

  useEffect(() => {
    if (entity) {
      const { id, ...entityFields } = entity
      setFields(entityFields)
    }
  }, [entity])

  const onInputChange = (field, value) => {
    setFields({ ...fields, [field]: value })
  }

  const onAccept = () => {
    if (entity) {
      const { id } = entity
      editGenericField({
        modelName,
        id,
        ...fields,
      })
    } else {
      addGenericField({
        modelName,
        ...fields,
      })
    }
    clearModalData()
  }

  const additionalInputs = () => {
    const { additionalFields } = currentModel
    if (additionalFields && additionalFields.length > 0) {
      return additionalFields.map(af => {
        return (
          <Input
            label={`Ingrese ${af.label}:`}
            placeholder="Escriba aqui"
            onChange={onInputChange}
            value={fields[af.name]}
            name={af.name}
          />
        )
      })
    } else {
      return null
    }
  }

  return (
    <Modal
      onAccept={onAccept}
      onCancel={onCancel}
      visible={true}
      title={createField ? `Creación de valor` : `Edición de valor`}
    >
      <Input
        label="Ingrese Descripción:"
        placeholder="Escriba aqui"
        onChange={onInputChange}
        value={fields.description}
        name="description"
        width="30%"
      />
      {additionalInputs()}
      {!createField && (
        <>
          <Input
            name="unitsToBelong"
            label="Unidades visibles"
            type="select"
            onChange={onInputChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            keyValue="name"
            mode="multiple"
            value={fields.unitsToBelong}
            options={units}
            showArrow
          />
          <Button
            text="Seleccionar todos"
            onClick={() =>
              onInputChange(`unitsToBelong`, units.map(({ id }) => id))
            }
            color="primary.3"
            backgroundColor="transparent"
          />
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = ({ general: { units } }) => ({
  units,
})

const mapDispatchToProps = {
  addGenericField,
  editGenericField,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
