import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setCurrentUnit,
  setValues,
  getClients,
  getFields,
  getSpecies,
  getDaps,
  getTaskTypes,
  getRequestTypes,
  setupUnit,
} from '../../redux/actions'
import {
  SET_FIELD_VALUES,
  SET_SPECIE_VALUES,
  SET_DAP_VALUES,
  SET_TASK_TYPE_VALUES,
  SET_REQUEST_TYPE_VALUES,
} from '../../redux/actions/types'
import { Loader } from '../../components'
import GenericManager from './GenericManager'
import UnitSelector from './UnitSelector'

function Clients({
  setCurrentUnit,
  currentUnitId,
  getClients,
  loading,
  clients,
  setValues,
  getFields,
  fields,
  fieldValues,
  getSpecies,
  species,
  specieValues,
  getDaps,
  daps,
  dapValues,
  getTaskTypes,
  taskTypes,
  taskTypeValues,
  getRequestTypes,
  requestTypes,
  requestTypeValues,
  setupUnit,
}) {
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    getClients()
  }, [])

  const createUnit = requestTypes => {
    setupUnit(
      {
        fields: fieldValues.selectedIds,
        species: specieValues.selectedIds,
        daps: dapValues.selectedIds,
        taskTypes: taskTypeValues.selectedIds,
        requestTypes,
      },
      currentUnitId,
    )
    setCurrentStep(0)
  }

  if (loading) {
    return <Loader />
  }
  switch (currentStep) {
    case 0: {
      return (
        <UnitSelector
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          title="Seleccione el cliente a gestionar:"
          data={clients}
          setCurrentUnit={setCurrentUnit}
        />
      )
    }
    case 1: {
      return (
        <GenericManager
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          title="Seleccione los campos a mostrar:"
          setValues={setValues}
          getData={getFields}
          data={fields}
          fieldName="fieldId"
          values={fieldValues}
          type={SET_FIELD_VALUES}
          stepName="fields"
          unitId={currentUnitId}
        />
      )
    }
    case 2: {
      return (
        <GenericManager
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          title="Seleccione los rangos DAP a mostrar:"
          setValues={setValues}
          getData={getDaps}
          data={daps}
          fieldName="dapId"
          values={dapValues}
          type={SET_DAP_VALUES}
          stepName="daps"
          unitId={currentUnitId}
        />
      )
    }
    case 3: {
      return (
        <GenericManager
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          title="Seleccione los tipos de tarea a mostrar:"
          setValues={setValues}
          getData={getTaskTypes}
          data={taskTypes}
          fieldName="taskTypeId"
          values={taskTypeValues}
          type={SET_TASK_TYPE_VALUES}
          stepName="taskTypes"
          unitId={currentUnitId}
        />
      )
    }
    case 4: {
      return (
        <GenericManager
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          title="Seleccione los tipos de solicitudes a mostrar:"
          setValues={setValues}
          getData={getRequestTypes}
          data={requestTypes}
          fieldName="requestTypeId"
          values={requestTypeValues}
          type={SET_REQUEST_TYPE_VALUES}
          stepName="requestTypes"
          unitId={currentUnitId}
          createUnit={createUnit}
        />
      )
    }
    default: {
      return null
    }
  }
}

const mapStateToProps = ({
  clients: {
    currentUnitId,
    loading,
    clients,
    fields,
    fieldValues,
    species,
    specieValues,
    daps,
    dapValues,
    taskTypes,
    taskTypeValues,
    requestTypes,
    requestTypeValues,
  },
}) => ({
  currentUnitId,
  loading,
  clients,
  fields,
  fieldValues,
  species,
  specieValues,
  daps,
  dapValues,
  taskTypes,
  taskTypeValues,
  requestTypes,
  requestTypeValues,
})

const mapDispatchToProps = {
  setCurrentUnit,
  setValues,
  getClients,
  getFields,
  getSpecies,
  getDaps,
  getTaskTypes,
  getRequestTypes,
  setupUnit,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Clients)
