import React, { useState } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Sector,
  Tooltip,
  Legend,
  Cell,
} from 'recharts'
import { connect } from 'react-redux'
import API from '../../config/API'
import { getTaskReportFilters } from '../../redux/actions'
import {
  Container,
  Card,
  Typography,
  Loader,
  Input,
  Button,
} from '../../components'

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? `start` : `end`
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.color}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={payload.color}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={payload.color} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Cantidad: ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

function PerformanceReport() {
  const [filterMonth, setFilterMonth] = useState(``)
  const [createdIn, setCreatedIn] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [performanceTasks, setPerformanceTasks] = useState(null)
  const [taskByStatus, setTaskByStatus] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const getData = async () => {
    setIsLoading(true)
    try {
      setFilterMonth(
        `${createdIn[0].format(`DD`)} de ${createdIn[0].format(
          `MMMM`,
        )} ${createdIn[0].format(`YYYY`)} al ${createdIn[1].format(
          `DD`,
        )} de ${createdIn[1].format(`MMMM`)} ${createdIn[1].format(`YYYY`)}`,
      )
      const { performance, taskByStatus } = await API.getTaskPerformance([
        createdIn[0].format(),
        createdIn[1].format(),
      ])
      setPerformanceTasks(performance)
      setTaskByStatus(taskByStatus)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }
  const onPieEnter = (data, index) => {
    setActiveIndex(index)
  }
  return (
    <>
      <Container
        marginTop="10px"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography type="title">Cumplimiento</Typography>
        <Container
          width="50%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Input
            width="60%"
            type="dateRange"
            onChange={(name, value) => setCreatedIn(value)}
            name="createdIn"
            format="DD-MM-YYYY"
            value={createdIn}
          />
          <Button
            onClick={() => getData()}
            text="Buscar"
            boxShadow={true}
            backgroundColor="gray.2"
            disabled={!createdIn}
          />
        </Container>
      </Container>
      {isLoading ? (
        <Container
          marginTop="10px"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Loader />
        </Container>
      ) : (
        performanceTasks && (
          <Card width="100%" marginTop="3" borderColor="primary.3">
            {filterMonth && (
              <Typography marginTop="4" type="subtitle" color="secondary.3">
                {`*Cumplimiento total, desde el ${filterMonth}: `}
              </Typography>
            )}
            <ResponsiveContainer width="100%" minHeight={250}>
              <BarChart data={performanceTasks.data}>
                {performanceTasks.category.map(
                  ({ key, name, color, stackId }) => {
                    return (
                      <Bar
                        key={`bar-${key}`}
                        id={`${key}`}
                        name={name}
                        dataKey={key}
                        fill={color}
                        stackId={stackId}
                      />
                    )
                  },
                )}
                <Legend verticalAlign="top" height={36} />
                <XAxis dataKey="name" type="category" />
                <Tooltip />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" minHeight={400}>
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={taskByStatus}
                  innerRadius={100}
                  outerRadius={140}
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {taskByStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Card>
        )
      )}
    </>
  )
}

const mapStateToProps = ({
  tasks: {
    tasksBySpecies,
    loadingBySpecies,
    tasksByType,
    loadingByType,
    taskReportFilters,
  },
  auth: { user },
  general: { adminUnitId },
}) => {
  return {
    loadingBySpecies,
    tasksBySpecies,
    loadingByType,
    tasksByType,
    taskReportFilters,
    user,
    adminUnitId,
  }
}

const mapDispatchToProps = {
  getTaskReportFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PerformanceReport)
