import {
  GENERAL_LOADING,
  GET_GENERAL_DATA,
  GET_GENERAL_DATA_ERROR,
  SET_UNIT_ID,
} from '../actions/types'

const initialState = {
  error: null,
  loading: false,
  roles: null,
  units: null,
  user: null,
  adminUnitId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case SET_UNIT_ID: {
      return {
        ...state,
        adminUnitId: action.unitId,
      }
    }
    case GET_GENERAL_DATA_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }
    case GET_GENERAL_DATA: {
      const { roles, units } = action
      return {
        ...state,
        loading: false,
        roles,
        units,
      }
    }
    default:
      return state
  }
}
