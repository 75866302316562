import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { getUsers, removeUser } from '../../redux/actions'
import {
  MainContainer,
  Table,
  Popover,
  Container,
  Button,
  Modal,
  Typography,
  Loader,
} from '../../components'
import { useListenerEnter } from '../../hooks'
import { filterTable } from '../../utils'
import Form from './Form'

const Users = ({
  user,
  adminUnitId,
  getUsers,
  loading,
  users,
  units,
  removeUser,
}) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [search, setSearch] = useState(``)
  const [entityWork, setEntityWork] = useState(null)
  const [data, setData] = useState([])
  const [dataVisible, setDataVisible] = useState([])
  const [dataNa, setDataNa] = useState([])
  const [dataVisibleNa, setDataVisibleNa] = useState([])
  const [columns, setColumns] = useState([])

  //TODO: BUGS-21
  useEffect(() => {
    const { unitId, roleId } = user
    if (roleId !== 1) {
      getUsers(unitId, roleId)
    }
  }, [])

  useEffect(() => {
    if (adminUnitId !== null) {
      getUsers(adminUnitId, 1)
    }
  }, [adminUnitId])

  useEffect(() => {
    const { roleId } = user
    const userColumns = [
      {
        title: `Nombre`,
        key: `firstName`,
        dataIndex: `firstName`,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      },
      {
        title: `Apellido`,
        key: `lastName`,
        dataIndex: `lastName`,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      },
      {
        title: `Correo`,
        key: `email`,
        dataIndex: `email`,
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: `Rut`,
        key: `rut`,
        dataIndex: `rut`,
        sorter: (a, b) => a.rut.localeCompare(b.rut),
      },
      {
        title: `Rol`,
        key: `role`,
        dataIndex: `Role.description`,
        sorter: (a, b) => a.Role.description.localeCompare(b.Role.description),
      },
      {
        title: `Sucursal`,
        key: `unitId`,
        dataIndex: `unitId`,
        sorter: (a, b) =>
          a.unitId > b.unitId ? 1 : a.unitId < b.unitId ? -1 : 0,
        sortDirections: [`descend`, `ascend`],
        render: (text, record) => {
          return units ? units.filter(x => x.id === record.unitId)[0].name : `-`
        },
      },
    ]
    if (roleId === 1) {
      userColumns.push({
        title: `Acción`,
        key: `action`,
        dataIndex: `action`,
        render: (text, record) => (
          <Popover
            title="Acción"
            icon={faEllipsisV}
            trigger="click"
            color="gray.2"
            content={
              <Container flexDirection="column">
                <Button
                  backgroundColor="transparent"
                  color="primary.3"
                  text="Editar"
                  icon={faEdit}
                  onClick={() => {
                    setEntityWork(record)
                    setVisibleModal(true)
                  }}
                />
                {/* <Button
                  backgroundColor="transparent"
                  color="primary.3"
                  text="Eliminar"
                  icon={faTrashAlt}
                  onClick={() => {
                    setEntityWork(record)
                    setVisibleDeleteModal(true)
                  }}
                /> */}
              </Container>
            }
          />
        ),
      })
    }
    setColumns(userColumns)
  }, [units])

  useEffect(() => {
    setData(users[0])
    setDataVisible(users[0])
    setDataNa(users[1])
    setDataVisibleNa(users[1])
  }, [users])
  const handleSearch = () => {
    const nextVisibleData = filterTable({
      data,
      filterText: search,
      filters: [`rut`, `firstName`, `lastName`, `email`, `Role.description`],
    })
    setDataVisible(nextVisibleData)
  }
  useListenerEnter({ enterFn: handleSearch, dependencies: [search] })

  if (loading) {
    return <Loader />
  }
  return (
    <>
    <MainContainer
      title="Usuarios Activos"
      hasSearch={true}
      hasCreate={user.roleId === 1}
      handleCreate={() => {
        setVisibleModal(true)
      }}
      handleSearch={handleSearch}
      handleSearchInput={(name, value) => {
        setSearch(value)
      }}
      search={search}
      defaultData={() => {
        setDataVisible(data)
      }}
      handleRefresh={() => {
        getUsers()
      }}
      searchText="Buscar por rut o apellido"
    >
      {visibleModal && (
        <Form
          onCancel={() => {
            setEntityWork(null)
            setVisibleModal(false)
          }}
          entity={entityWork}
        />
      )}
      {visibleDeleteModal && (
        <Modal
          onAccept={() => {
            removeUser(entityWork.id)
            setVisibleDeleteModal(false)
          }}
          onCancel={() => {
            setVisibleDeleteModal(false)
          }}
          visible={true}
          title="Eliminar usuario"
        >
          <Typography>¿Seguro que deseas eliminar el usuario?</Typography>
        </Modal>
      )}
      {
        <Table
          columns={columns}
          dataSource={dataVisible}
          locale={{ emptyText: `No existen usuarios` }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: [`10`, `20`, `30`, `40`],
          }}
        />
      }
    </MainContainer>
    
    <MainContainer
      title="Usuarios Inactivos"
      hasSearch={true}
      hasCreate={user.roleId === 1}
      handleCreate={() => {
        setVisibleModal(true)
      }}
      handleSearch={handleSearch}
      handleSearchInput={(name, value) => {
        setSearch(value)
      }}
      search={search}
      defaultData={() => {
        setDataVisible(dataNa)
      }}
      handleRefresh={() => {
        getUsers()
      }}
      searchText="Buscar por rut o apellido"
    >
      {visibleModal && (
        <Form
          onCancel={() => {
            setEntityWork(null)
            setVisibleModal(false)
          }}
          entity={entityWork}
        />
      )}
      {visibleDeleteModal && (
        <Modal
          onAccept={() => {
            removeUser(entityWork.id)
            setVisibleDeleteModal(false)
          }}
          onCancel={() => {
            setVisibleDeleteModal(false)
          }}
          visible={true}
          title="Eliminar usuario"
        >
          <Typography>¿Seguro que deseas eliminar el usuario?</Typography>
        </Modal>
      )}
      {
        <Table
          columns={columns}
          dataSource={dataVisibleNa}
          locale={{ emptyText: `No existen usuarios` }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: [`10`, `20`, `30`, `40`],
          }}
        />
      }
    </MainContainer>
    </>
  )
}

const mapStateToProps = ({
  users: { loading, error, users },
  general: { units, adminUnitId },
  auth: { user },
}) => ({
  user,
  adminUnitId,
  loading,
  error,
  users,
  units,
})

const mapDispatchToProps = {
  getUsers,
  removeUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users)
