import { GET_WORKERS, GET_INSPECTORS } from '../actions/types'

const initialState = {
  workers: [],
  inspectors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKERS:
      return {
        ...state,
        workers: action.workers,
      }
    case GET_INSPECTORS:
      return {
        ...state,
        inspectors: action.inspectors,
      }
    default:
      return state
  }
}
