import React from 'react'
import { ButtonStyled } from './styles'
import Icon from './Icon'

const Button = ({ icon, onClick, text, ...props }) => {
  return (
    <ButtonStyled onClick={() => onClick()} {...props}>
      {icon && <Icon icon={icon} marginRight="8px" {...props} />}
      <span>{text}</span>
    </ButtonStyled>
  )
}

export default Button
