import React from 'react'
import { Popover } from 'antd'
import Icon from './Icon'

const PopoverCustom = ({ title, icon, content, trigger, ...props }) => (
  <Popover content={content} title={title} trigger={trigger}>
    <Icon icon={icon} {...props} />
  </Popover>
)

export default PopoverCustom
