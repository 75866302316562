/* eslint-disable complexity */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import confirm from 'antd/es/modal/confirm'
import { Modal, Container, Typography, Input, Button } from '../../components'

function FieldsWrapper({ title, content }) {
  return (
    <Container flexDirection="column" width="48%" marginBottom="16px">
      <Typography fontWeight="bold">{title}</Typography>
      <Typography backgroundColor="gray.1" width="100%" padding="4px 8px">
        {content}
      </Typography>
    </Container>
  )
}

function ViewRequest({
  data,
  userId,
  roleId,
  onCancel,
  workers,
  taskTypes,
  taskPriorities,
  assignRequest,
  rejectRequest,
  approveRequest,
  deleteRequest,
}) {
  const [selections, setSelections] = useState({})
  const [citizenMessage, setMessage] = useState(
    `Estimado vecino, su solicitud ha sido asignada a uno de nuestros Inspectores Municipales.`,
  )

  useEffect(() => {
    if (roleId === 5) {
      setSelections({
        ...selections,
        workerId: userId,
      })
    }
  }, [])

  const handleSelect = (field, value) => {
    setSelections({
      ...selections,
      [field]: value,
    })
  }

  const handleInput = (field, value) => {
    setMessage(value)
  }

  const manageRequest = type => {
    switch (type) {
      case 1:
        assignRequest(
          data.id,
          selections.workerId,
          data.treeId,
          data.user.tokens,
          citizenMessage,
        )
        onCancel()
        break
      case 2:
        rejectRequest(data.id, userId, data.user.tokens)
        onCancel()
        break
      case 3:
        approveRequest(
          data.id,
          data.treeId,
          selections.approveWorkerId,
          selections.taskTypeId,
          selections.taskPriorityId,
          data.user.tokens,
          selections.observation,
        )
        onCancel()
        break
      case 4:
        deleteRequest(data.id)
        onCancel()
        break
      default:
        onCancel()
        break
    }
  }

  return (
    <Modal
      onAccept={onCancel}
      onCancel={onCancel}
      visible={true}
      title="Ver Solicitud"
      showCancel={false}
    >
      <Container overflowX="hidden" width="100%" justifyContent="space-between">
        <FieldsWrapper
          title="Tipo de Tarea:"
          content={ (data.requestType && data.requestType.description) || "N/D"}
        />
        <FieldsWrapper
          title="Estado:"
          content={data.requestStatus.description}
        />
        <FieldsWrapper title="Id Árbol:" content={data.treeId} />
        <FieldsWrapper
          title="Especie:"
          content={data.tree.specie.description}
        />
        <FieldsWrapper
          title="Dirección:"
          content={`${data.tree.streetName} ${data.tree.streetNumber}`}
        />
        <FieldsWrapper title="Comentario vecino:" content={data.description} />
        <FieldsWrapper title="Creada el:" content={data.createdAt} />
        <FieldsWrapper title="Creada por:" content={data.user.name} />
        <FieldsWrapper
          title="Teléfono Vecino:"
          content={data.user.phone ? data.user.phone : `No Registra`}
        />
        <FieldsWrapper
          title="Correo Vecino:"
          content={data.user.mail ? data.user.mail : `No Registra`}
        />
        {data.requestStatus.id === 1 && (
          <>
            <Container flexDirection="column" width="48%" marginBottom="16px">
              {roleId !== 5 && (
                <Input
                  width="100%"
                  showSearch
                  type="select"
                  optionFilterProp="children"
                  label="Seleccione inspector a asignar:"
                  placeholder="Elija una opción"
                  onChange={handleSelect}
                  name="workerId"
                  keyValue="description"
                  options={workers}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    selections.workerId === `` ? undefined : selections.workerId
                  }
                />
              )}
              <Input
                width="100%"
                label="Mensaje a vecino:"
                placeholder="Escriba aqui"
                onChange={handleInput}
                value={citizenMessage}
                name="treeId"
              />
              <Container>
                <Button
                  onClick={() => manageRequest(1)}
                  text="Asignar solicitud"
                  boxShadow={true}
                  backgroundColor="gray.2"
                  marginTop="8px"
                  marginLeft="5px"
                  disabled={!selections.workerId}
                />
                <Button
                  onClick={() => manageRequest(2)}
                  text="Rechazar solicitud"
                  boxShadow={true}
                  backgroundColor="red"
                  marginTop="8px"
                  marginLeft="10px"
                />
              </Container>
            </Container>
            {roleId !== 5 && (
              <Container flexDirection="column" width="48%" marginBottom="16px">
                <Input
                  width="100%"
                  showSearch
                  type="select"
                  optionFilterProp="children"
                  label="Seleccione inspector responsable:"
                  placeholder="Elija una opción"
                  onChange={handleSelect}
                  name="approveWorkerId"
                  keyValue="description"
                  options={workers}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    selections.approveWorkerId === ``
                      ? undefined
                      : selections.approveWorkerId
                  }
                />
                <Input
                  width="100%"
                  showSearch
                  type="select"
                  optionFilterProp="children"
                  label="Seleccione tipo de tarea a crear:"
                  placeholder="Elija una opción"
                  onChange={handleSelect}
                  name="taskTypeId"
                  keyValue="description"
                  options={taskTypes}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    selections.taskTypeId === ``
                      ? undefined
                      : selections.taskTypeId
                  }
                />
                <Input
                  width="100%"
                  showSearch
                  type="select"
                  optionFilterProp="children"
                  label="Seleccione prioridad de tarea:"
                  placeholder="Elija una opción"
                  onChange={handleSelect}
                  name="taskPriorityId"
                  keyValue="description"
                  options={taskPriorities}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    selections.taskPriorityId === ``
                      ? undefined
                      : selections.taskPriorityId
                  }
                />
                <Input
                  width="100%"
                  showSearch
                  label="Observación:"
                  onChange={handleSelect}
                  name="observation"
                  keyValue="observation"
                  value={
                    selections.observation === ``
                      ? undefined
                      : selections.observation
                  }
                />
                <Container flexDirection="row">
                  <Button
                    onClick={() => manageRequest(3)}
                    text="Aprobar solicitud"
                    boxShadow={true}
                    backgroundColor="gray.2"
                    marginTop="8px"
                    marginLeft="5px"
                    disabled={
                      !selections.taskTypeId || !selections.approveWorkerId
                    }
                  />
                </Container>
              </Container>
            )}
          </>
        )}
        {[1, 3, 5].includes(roleId) &&
        data.requestStatus.id === 1 && ( //y solicitud pendiente
            <Button
              onClick={() =>
                confirm({
                  title: `Eliminar solicitud`,
                  content: `¿Seguro quiere eliminar la solicitud?. La acción no podrá ser revertida`,
                  okText: `Yes`,
                  okType: `danger`,
                  cancelText: `No`,
                  onOk() {
                    manageRequest(4)
                  },
                })
              }
              text="Eliminar solicitud"
              boxShadow={true}
              backgroundColor="red"
              marginTop="8px"
              marginLeft="10px"
            />
          )}
        {data.assignedTo && (
          <>
            <FieldsWrapper
              title="Asignada el:"
              content={moment(data.assignedAt).format(`DD/MM/YYYY`)}
            />
            <FieldsWrapper
              title="Asignada por:"
              content={`${data.assignedTo.firstName} ${data.assignedTo.lastName}`}
            />
          </>
        )}
        {data.rejectedBy && (
          <>
            <FieldsWrapper
              title="Rechazada el:"
              content={moment(data.rejectedAt).format(`DD/MM/YYYY`)}
            />
            <FieldsWrapper
              title="Rechazada por:"
              content={`${data.rejectedBy.firstName} ${data.rejectedBy.lastName}`}
            />
          </>
        )}
      </Container>
    </Modal>
  )
}

export default ViewRequest
