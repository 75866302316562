import API from '../../config/API'
import { notification } from '../../utils'
import {
  TREES_LOADING,
  GET_TREES,
  ERROR_TREES,
  GET_TREE_FILTERS,
  GET_TREE_FIELDS,
  GET_TREE_BY_ID,
  SUCCESS_EDIT,
  SUCCESS_DELETE,
  ERROR_MESSAGE,
  GET_TREES_BY_UNIT,
  LOAD_TREES_BY_UNIT,
} from './types'

export const getTrees = filters => async dispatch => {
  dispatch({ type: TREES_LOADING })
  try {
    const { rows: trees, count, countArboles, countPalmeras, countTocones, countPV, countEspecies, countVisits } = await API.getFilteredTrees(filters)
    if (trees.length > 0) {
      dispatch({
        type: GET_TREES,
        trees,
        count,
        countArboles,
        countPalmeras,
        countTocones,
        countPV,
        countEspecies,
        countVisits
      })
    } else {
      notification({ type: `error`, message: `No se han encontrado árboles` })
      dispatch({ type: ERROR_TREES })
    }
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ERROR_TREES })
  }
}

export const getTreeFilters = unitId => async dispatch => {
  try {
    const result = await API.getTreeFilters(unitId)
    dispatch({
      type: GET_TREE_FILTERS,
      treeFilters: result,
    })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const getTreeFields = (unitId, userId) => async dispatch => {
  try {
    const result = await API.getTreeFields(unitId, userId)
    dispatch({
      type: GET_TREE_FIELDS,
      result,
    })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const getTreeById = treeId => async dispatch => {
  try {
    const result = await API.getTreeById(treeId)
    dispatch({
      type: GET_TREE_BY_ID,
      result,
    })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const modifyTree = (treeId, tree) => async dispatch => {
  try {
    await API.editTree(treeId, tree)
    notification({
      type: `success`,
      message: `Se ha editado el árbol correctamente`,
    })
    dispatch({ type: SUCCESS_EDIT, tree, treeId })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const deleteTree = treeId => async dispatch => {
  try {
    await API.removeTree(treeId)
    notification({
      type: `success`,
      message: `Se ha eliminado el árbol correctamente`,
    })
    dispatch({ type: SUCCESS_DELETE })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}

export const getTreeByUnit = unitId => async dispatch => {
  dispatch({
    type: LOAD_TREES_BY_UNIT,
  })
  try {
    const payload = await API.getTreesByUnitId(unitId)
    dispatch({
      type: GET_TREES_BY_UNIT,
      payload,
    })
  } catch (error) {
    notification({
      type: `error`,
      message: ERROR_MESSAGE,
    })
  }
}
