import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, Typography, Table, Container, Button } from '../../components'

function GenericManager({
  currentStep,
  setCurrentStep,
  title,
  getData,
  setValues,
  data,
  values,
  type,
  fieldName,
  unitId,
  stepName,
  unitValues,
  createUnit,
}) {
  const [selectedIds, setSelected] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data.length && unitValues && stepName === `fields`) {
      setSelected(unitValues[stepName].ids)

      const selectedIds = unitValues[stepName].ids.reduce((acc, item) => {
        const { fieldId } = item
        const findIndex = data.findIndex(el => el.id === fieldId)
        acc.push(findIndex)
        return acc
      }, [])

      setSelectedRowKeys(selectedIds)
      setLoading(false)
    }
  }, [data, unitValues, stepName])

  useEffect(() => {
    if (unitValues && stepName !== `fields`) {
      setSelected(unitValues[stepName].ids)
      setSelectedRowKeys(unitValues[stepName].values)
      setLoading(false)
    }
  }, [unitValues])

  useEffect(() => {
    setLoading(true)
    getData()
    if (stepName !== `fields`) {
      setSelected(values.selectedIds)
      setSelectedRowKeys(values.selectedRowKeys)
      if (unitValues) {
        setSelected(unitValues[stepName].ids)
        setSelectedRowKeys(unitValues[stepName].values)
      }
      setLoading(false)
    }
  }, [currentStep])

  const columns = [
    {
      title: `Id`,
      dataIndex: `id`,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: `Campo`,
      dataIndex: `description`,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows.map(row => ({ [fieldName]: row.id, unitId })))
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const setStep = step => {
    setValues(type, selectedRowKeys, selectedIds)
    setCurrentStep(step)
  }

  const newUnit = () => {
    setValues(type, selectedRowKeys, selectedIds)
    createUnit(selectedIds)
  }

  return (
    <Card width="100%" borderColor="primary.3">
      <Typography color="primary.3" fontWeight="bold" fontStyle="italic">
        {title}
      </Typography>
      {unitValues ? (
        <>
          <Table
            loading={loading}
            marginTop="16px"
            columns={columns}
            dataSource={
              currentStep === 1
                ? data.map(field => ({
                    id: field.id,
                    description: `${field.description} ${
                      field.isRequired ? `(Campo Obligatorio)` : ``
                    }`,
                  }))
                : data
            }
            rowSelection={rowSelection}
            pagination={false}
            scroll={{ y: 540 }}
            locale={{
              emptyText: `No existen campos`,
            }}
          />
          <Container marginTop="20px" justifyContent="flex-end">
            <Button
              onClick={() => setStep(currentStep - 1)}
              text="Volver"
              color="primary.3"
              backgroundColor="transparent"
              marginRight="6px"
            />
            <Button
              onClick={() =>
                createUnit ? newUnit() : setStep(currentStep + 1)
              }
              text="Siguiente"
              boxShadow={true}
              backgroundColor="gray.2"
            />
          </Container>
        </>
      ) : null}
    </Card>
  )
}

const mapStateToProps = ({ clients: { unitValues } }) => ({
  unitValues,
})

export default connect(
  mapStateToProps,
  null,
)(GenericManager)
