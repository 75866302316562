import styled from 'styled-components'
import {
  compose,
  space,
  layout,
  color,
  typography,
  border,
  textStyle,
} from 'styled-system'

export const TitleStyle = styled.h1`
  ${compose(
    space,
    layout,
    color,
    typography,
    border,
    textStyle,
  )}
  ${({ isUnderLine }) => isUnderLine && `text-decoration: underline`}
`
TitleStyle.defaultProps = {
  fontSize: `8`,
  fontWeight: 500,
  color: `primary.3`,
  marginTop: `0px`,
  marginBottom: `0px`,
  marginLeft: `0px`,
  marginRight: `0px`,
  isUnderline: false,
}

export const SubtitleStyle = styled.h2`
  ${compose(
    space,
    layout,
    color,
    typography,
    border,
    textStyle,
  )}
  ${({ isUnderLine }) => isUnderLine && `text-decoration: underline`}
`
SubtitleStyle.defaultProps = {
  fontSize: `3`,
  fontWeight: 300,
  color: `primary.3`,
  marginBottom: `16px`,
  isUnderline: false,
}

export const LabelStyle = styled.span`
  ${compose(
    space,
    layout,
    color,
    typography,
    border,
    textStyle,
  )}
  ${({ isUnderLine }) => isUnderLine && `text-decoration: underline`}
`
LabelStyle.defaultProps = {
  fontSize: `3`,
  fontWeight: 300,
  color: `secondary.2`,
  marginTop: `0px`,
  marginBottom: `0px`,
  marginLeft: `0px`,
  marginRight: `0px`,
  isUnderline: false,
}
