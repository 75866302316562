import API from '../../config/API'
import {
  GENERIC_FIELDS_LOADING,
  GET_GENERIC_FIELDS,
  GET_GENERIC_FIELDS_ERROR,
  ADD_GENERIC_FIELD,
  ADD_GENERIC_FIELD_ERROR,
  EDIT_GENERIC_FIELD,
  EDIT_GENERIC_FIELD_ERROR,
  REMOVE_GENERIC_FIELD,
  REMOVE_GENERIC_FIELD_ERROR,
  ERROR_MESSAGE,
} from '../actions/types'
import { notification } from '../../utils'

export const getGenericFields = modelName => async dispatch => {
  dispatch({ type: GENERIC_FIELDS_LOADING })
  try {
    const unmappedGenericFields = await API.getGenericFields(modelName)
    const genericFieldsById = {}
    const genericFields = unmappedGenericFields.map(gf => {
      genericFieldsById[gf.id] = gf
      return gf.id
    })
    dispatch({ type: GET_GENERIC_FIELDS, genericFields, genericFieldsById })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: GET_GENERIC_FIELDS_ERROR, error })
  }
}

export const addGenericField = ({ modelName, ...fields }) => async dispatch => {
  dispatch({ type: GENERIC_FIELDS_LOADING })
  try {
    const field = await API.addGenericField({ modelName, ...fields })
    notification({
      type: `success`,
      message: `Se ha agregado correctamente`,
    })
    dispatch({ type: ADD_GENERIC_FIELD, field })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: ADD_GENERIC_FIELD_ERROR, error })
  }
}

export const editGenericField = ({
  modelName,
  id,
  ...fields
}) => async dispatch => {
  dispatch({ type: GENERIC_FIELDS_LOADING })
  try {
    await API.editGenericField({ modelName, id, ...fields })
    notification({
      type: `success`,
      message: `Se ha editado correctamente`,
    })
    dispatch({ type: EDIT_GENERIC_FIELD, id, ...fields })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: EDIT_GENERIC_FIELD_ERROR, error })
  }
}

export const removeGenericField = ({ modelName, id }) => async dispatch => {
  dispatch({ type: GENERIC_FIELDS_LOADING })
  try {
    await API.removeGenericField({ modelName, id })
    notification({
      type: `success`,
      message: `Se ha eliminado correctamente`,
    })
    dispatch({ type: REMOVE_GENERIC_FIELD, id })
  } catch (error) {
    notification({ type: `error`, message: ERROR_MESSAGE })
    dispatch({ type: REMOVE_GENERIC_FIELD_ERROR, error })
  }
}
