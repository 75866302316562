import _ from 'lodash'
import {
  CLIENTS_LOADING,
  CLIENTS_ERROR,
  GET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  SET_CURRENT_UNIT,
  GET_FIELDS,
  SET_FIELD_VALUES,
  GET_SPECIES,
  SET_SPECIE_VALUES,
  GET_DAPS,
  SET_DAP_VALUES,
  GET_TASK_TYPES,
  SET_TASK_TYPE_VALUES,
  GET_REQUEST_TYPES,
  SET_REQUEST_TYPE_VALUES,
  SETUP_CLIENT_LOADING,
  SETUP_CLIENT_SUCCESS,
  SETUP_CLIENT_ERROR,
} from '../actions/types'

const initialState = {
  clients: [],
  loading: false,
  setupLoading: false,
  currentUnitId: null,
  unitValues: null,
  fields: [],
  fieldValues: { selectedRowKeys: [], selectedIds: [] },
  species: [],
  specieValues: { selectedRowKeys: [], selectedIds: [] },
  daps: [],
  dapValues: { selectedRowKeys: [], selectedIds: [] },
  taskTypes: [],
  taskTypeValues: { selectedRowKeys: [], selectedIds: [] },
  requestTypes: [],
  requestTypeValues: { selectedRowKeys: [], selectedIds: [] },
}

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case CLIENTS_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case GET_CLIENTS: {
      return {
        ...state,
        loading: false,
        clients: action.result,
      }
    }
    case ADD_CLIENT: {
      const { id, unit } = action
      const nextClient = {
        ...unit,
        id,
      }
      return {
        ...state,
        loading: false,
        clients: [...state.clients, nextClient],
      }
    }
    case EDIT_CLIENT: {
      const { unitId, unit } = action
      const cloneClients = _.cloneDeep(state.clients)
      const nextClient = {
        ...unit,
        unitId,
      }
      return {
        ...state,
        loading: false,
        clients: cloneClients.map(client =>
          client.id === unitId ? nextClient : client,
        ),
      }
    }
    case GET_FIELDS: {
      return {
        ...state,
        fields: action.result,
      }
    }
    case SET_CURRENT_UNIT: {
      return {
        ...state,
        currentUnitId: action.unitId,
        unitValues: action.unitValues,
        fieldValues: { selectedRowKeys: [], selectedIds: [] },
        specieValues: { selectedRowKeys: [], selectedIds: [] },
        dapValues: { selectedRowKeys: [], selectedIds: [] },
        taskTypeValues: { selectedRowKeys: [], selectedIds: [] },
        requestTypeValues: { selectedRowKeys: [], selectedIds: [] },
      }
    }
    case SET_FIELD_VALUES: {
      return {
        ...state,
        fieldValues: {
          selectedRowKeys: action.selectedRowKeys,
          selectedIds: action.selectedIds,
        },
      }
    }
    case GET_SPECIES: {
      return {
        ...state,
        species: action.result,
      }
    }
    case SET_SPECIE_VALUES: {
      return {
        ...state,
        specieValues: {
          selectedRowKeys: action.selectedRowKeys,
          selectedIds: action.selectedIds,
        },
      }
    }
    case GET_DAPS: {
      return {
        ...state,
        daps: action.result,
      }
    }
    case SET_DAP_VALUES: {
      return {
        ...state,
        dapValues: {
          selectedRowKeys: action.selectedRowKeys,
          selectedIds: action.selectedIds,
        },
      }
    }
    case GET_TASK_TYPES: {
      return {
        ...state,
        taskTypes: action.result,
      }
    }
    case SET_TASK_TYPE_VALUES: {
      return {
        ...state,
        taskTypeValues: {
          selectedRowKeys: action.selectedRowKeys,
          selectedIds: action.selectedIds,
        },
      }
    }
    case GET_REQUEST_TYPES: {
      return {
        ...state,
        requestTypes: action.result,
      }
    }
    case SET_REQUEST_TYPE_VALUES: {
      return {
        ...state,
        requestTypeValues: {
          selectedRowKeys: action.selectedRowKeys,
          selectedIds: action.selectedIds,
        },
      }
    }
    case SETUP_CLIENT_LOADING: {
      return {
        ...state,
        setupLoading: true,
      }
    }
    case SETUP_CLIENT_SUCCESS: {
      return {
        ...state,
        setupLoading: false,
      }
    }
    case SETUP_CLIENT_ERROR: {
      return {
        ...state,
        setupLoading: false,
      }
    }
    default:
      return state
  }
}
